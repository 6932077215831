/**=====================
    1.1 Reset CSS start
==========================**/

/*======= Button-color css ends  ======= */
@each $btn-name,
$btn-color in (primary1, $primary-color-1),
(primary2, $primary-color-2) (secondary1, $secondary-color-1),
(secondary2, $secondary-color-2),
(secondary3, $secondary-color-3),
(secondary4, $secondary-color-4),
(success-dark, $success-dark-color),
(success-medium, $success-medium-color),
(success-light, $success-light-color),
(warning-dark, $warning-dark-color),
(warning-medium, $warning-medium-color),
(warning-light, $warning-light-color),
(info-dark, $info-dark-color),
(info-medium, $info-medium-color),
(info-light, $info-light-color),
(error-dark, $error-dark-color),
(error-medium, $error-medium-color),
(error-light, $error-light-color) {
  .btn-#{$btn-name} {
    border-width: 1px !important;
    border-color: $btn-color !important;
    color: $white !important;
    background-color: $btn-color !important;
  }
}

.bg-success-medium {
  background-color: $success-medium-color;
}

.min-h-content {
  height: min-content;
}

.t-padding {
  padding-top: $top-padding;
}

.b-padding {
  padding-bottom: $bottom-padding;
}

.b-padding-1 {
  padding-bottom: 20px;
}

.mt-100 {
  margin-top: 100px !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.curser-pointer {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-primary-color-1 {
  color: $primary-color-1 !important;
}

.text-dark-gray {
  color: $dark-gray !important;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

.custom-scroll {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    background-color: $white;
  }

  &::-webkit-scrollbar {
    width: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320)));
    height: 5px;
    border-radius: 4px 0 0 4px;

    @media (max-width: 575.98px) {
      width: 4px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-dark-color;
    border-radius: 4px 0 0 4px;
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    border-radius: 4px 0 0 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-dark-color;
    border-radius: 4px 0 0 4px;
  }
}

.invalid-note,
.info-note,
.success-note,
.delete-note,
.warning-note,
.complete-note,
.invoice-note {
  padding: 10px 32px 10px 12px;
  border-radius: 25px;
  background-color: $error-light-color;
  color: $error-medium-color;
  @include flex_common;
  gap: 30px;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  margin-top: 50px;
  width: auto;
  margin-inline: auto;
  z-index: 9999;

  .error-img,
  .info-img,
  .success-img,
  .delete-img,
  .warning-img,
  .complete-img,
  .invoice-img {
    background-color: $error-medium-color;
    border-radius: 100%;
    height: 24px;
    min-width: 24px;
    width: 24px;
    @include flex_common;

    img {
      width: 16px;
      min-width: 16px;
      height: 16px;
    }
  }
}

.info-note {
  background-color: $info-light-color;
  color: $info-dark-color;

  .info-img {
    background-color: $white;
  }
}

.success-note {
  background-color: $success-light-color;
  color: $secondary-color-4;
  font-size: 16px;
  font-weight: 600;
  padding: 14px 32px 14px 14px;
  gap: 20px;
  position: fixed;
  bottom: 21px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100px;

  @media (max-width: 575px) {
    position: fixed;
    top: -5px;
    right: 35px;
    bottom: unset;
    left: unset;
    transform: none;
  }

  .success-img {
    background-color: $success-medium-color;
  }
}

.invoice-note {
  background-color: $info-light-color;
  color: $info-dark-color;
  padding: 6px;
  gap: 14px;
  justify-content: unset;

  h6 {
    font-size: 14px;
    font-weight: 500;
  }

  .invoice-img {
    background-color: $white;
  }
}

.complete-note {
  background-color: $success-light-color;
  color: $secondary-color-4;
  font-size: 16px;
  font-weight: 700;
  padding: 14px 32px 14px 14px;
  gap: 20px;
  position: fixed;
  top: 75%;
  left: 50%;
  transform: translateX(-50%);

  .complete-img {
    background-color: $success-medium-color;
  }
}

.delete-note {
  background-color: $gray-light-color;
  color: $secondary-color-4;
  font-size: 16px;
  font-weight: 700;
  padding: 14px 32px 14px 14px;
  gap: 20px;
  position: fixed;
  top: 75%;
  left: 50%;
  transform: translateX(-50%);

  .delete-img {
    background-color: $dark-gray;
  }
}

.warning-note {
  background-color: $warning-light-color;
  color: $secondary-color-4;
  font-size: 16px;
  font-weight: 700;
  padding: 14px 32px 14px 14px;
  gap: 20px;
  position: fixed;
  top: 75%;
  left: 50%;
  transform: translateX(-50%);

  strong {
    color: $warning-dark-color;
  }

  .warning-img {
    background-color: $warning-medium-color;
  }
}

.not-found-page {
  height: 100vh;
  @include flex_common;
  flex-direction: column;

  img {
    width: 30%;
  }

  h4 {
    font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 10px;
  }

  a {
    white-space: nowrap;
    margin-top: 30px;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320))) calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));

    img {
      transform: translateX(0px);
      transition: all 0.2s ease;
    }

    &:hover {
      img {
        transform: translateX(-5px);
        transition: all 0.2s ease;
      }
    }
  }
}

.custom-width-card {

  .custom-w,
  .social-media {
    width: 460px;
  }
}

.custom-form-w {
  width: 450px;
  margin-inline: auto;

  .otp-code {
    .otp-field {
      height: 69px;
      width: 69px;
    }
  }
}

.container-fluid {
  padding-inline: 0;
}

.doc-upload {
  width: 15px;
  height: 18px;
}

.mb-3 {
  &.mb-0 {
    margin-bottom: 0 !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.w-65 {
  width: 65%;
}

@keyframes slideup {
  0% {
    transform: translate(-50%, 30px);
    transition: all 1s ease-in-out;
  }

  100% {
    transform: translate(-50%, 0px);
    transition: all 1s ease-in-out;
  }
}

.avatar-group {
  display: flex;
  align-items: center;

  .avatar {
    border: 1px solid $gray-dark-color;
    border-radius: 100%;
    width: 40px !important;
    height: 40px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: $white;

    +.avatar {
      margin-left: -5px;
    }
  }
}

hr {
  margin: 12px 0;
  border: 1px solid $gray-dark-color;
  width: 100%;
}

.w-max {
  width: max-content;
}

.min-h-96 {
  min-height: 96px !important;
}

.right-box-shadow {
  border-left: 1px solid transparent;
  box-shadow: -25px 0 31px rgba(74, 66, 92, 0.1);
  height: 100%;
  width: 44px;
  display: block;
  top: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.pb-120 {
  padding-bottom: 120px;
}

.mt-90 {
  margin-top: 90px;
}

.btn-close {
  box-shadow: unset;
  opacity: 1;
  background-image: url("../../images/svg/close.svg");
  background-size: 14px 14px;

  &:focus {
    box-shadow: unset;
  }

  &:hover {
    background-image: url("../../images/svg/red-close.svg");
  }

  &:focus {
    background-image: url("../../images/svg/red-close.svg") $primary-color-1;
  }
}

.bg-image {
  display: none;

  @media (max-width: 1199px) {
    position: fixed;
    bottom: 0;
    right: 0;
    display: block;
  }
}

.radius-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.custom-col-xl-4 {
  @media (min-width: 1200px) {
    width: 21%;
  }
}

.no-data-found {
  @include flex_common;
  text-align: center;

  img {
    width: 80px;
    height: 80px;
  }

  h5 {
    font-size: 14px;
    font-weight: 700;
    margin-top: 16px;
  }
}

.m-b-12 {
  margin-bottom: 12px;
}

.fw-500 {
  font-weight: 500;
}

.position-unset {
  position: unset !important;
}

.common-f-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.invalid-note {
  position: fixed;
  bottom: 20px;
  z-index: 9999;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 575px) {
    position: fixed;
    top: 15px;
    right: 15px;
    margin-top: 0;
    transform: unset;
    bottom: unset;
    left: unset;
  }
}

.custom-pe-0 {
  @media (min-width: 1440.98px) {
    padding-right: 0 !important;
  }
}

.dropdown-open {
  @media (max-width: 575.98px) {
    overflow: hidden;

    .page-body {
      &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(221, 221, 221, 0.5);
        backdrop-filter: blur(5px);
        z-index: 4;
      }
    }

    &:has(.invoice-table-main .invoice-work-responsive .invoice-work-table tbody tr td.qty-data .w-full .filter-dropdown-box .select-dropdown-menu.show) {
      .page-body {
        &::before {
          content: none;
        }
      }
    }
  }
}

.px-24 {
  @media (max-width: 767.98px) {
    padding-inline: 24px !important;
  }
}

.h-md-100 {
  @media (min-width: 767.98px) {
    height: 100% !important;
  }
}

[tabindex="-1"] {
  &:has(.offcanvas) {
    z-index: 8 !important;

    &::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      backdrop-filter: blur(2px);
    }
  }

  &:has(.offcanvas.offcanvas-bottom) {
    z-index: 8 !important;

    @media (max-width:767.98px) {
      &::before {
        backdrop-filter: unset;
      }
    }
  }

  &:has(.client-offcanvas-detail) {
    margin-top: 72px;
  }
}

.toast-wrapper-box {
  position: fixed;
  bottom: 21px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 9px;
  z-index: 9999;

  @media (max-width: 575px) {
    position: fixed;
    top: 11px;
    right: 35px;
    bottom: unset;
    left: unset;
    transform: none;
  }

  @media (max-width: 480px) {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
  }

  .invalid-note,
  .info-note,
  .success-note,
  .delete-note,
  .warning-note,
  .complete-note,
  .invoice-note {
    position: relative;
    inset: unset;
    transform: unset;
    margin: 0;
  }
}

/**=====================
    1.1 Reset CSS Ends
==========================**/