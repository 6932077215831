/**=====================
  Form CSS starts
==========================**/
body {
  @media (max-width: 576px) {
    &:not(:has(.close_icon)) {
      overflow: hidden;
    }

    &:has(.offcanvas.show) {
      .page-wrapper {
        .page-header {
          z-index: 8 !important;
        }
      }
    }
  }

  &.main-dashboard {
    .page-wrapper {
      .page-header {
        @media (max-width: 576px) {
          &:not(&.close_icon) {
            transform: unset !important;
            z-index: 9;
            background-color: #f7f5f7 !important;

            .mobile-title {
              display: none;
            }
          }
        }

        .page-title {
          .mobile-title {
            @media (max-width: 576px) {
              position: relative;
              inset: unset;
              padding: 0;
            }
          }
        }
      }

      .page-body-wrapper {
        .page-body {
          margin-top: 153px;

          @media (max-width: 767.98px) {
            margin-top: 190px;
          }

          @media (max-width: 575.98px) {
            margin-top: 176px;
            padding-inline: 8px;
          }
        }

        .sidebar-wrapper {
          .logo-wrapper {
            padding: 50px 40px 58px;

            @media (max-width: 767px) {
              padding: 20px 16px 25px;
            }
          }

          .sidebar-main {
            .sidebar-content {
              height: calc(100vh - 146px) !important;
            }
          }
        }
      }
    }
  }

  &:has(.page-wrapper .page-body-wrapper .sidebar-wrapper:not(.close_icon)) {
    .offcanvas {
      &.invoice-detail-modal {
        width: calc(100% - 270px);
        margin-left: 270px;

        .status-content {
          width: calc(100% - 774px + 135px);
        }

        .offcanvas-body {
          .invoice-content {
            @media (max-width: 1440.98px) {
              max-width: 637px;
            }
          }
        }

        @media (max-width: 1440.98px) and (min-width: 991.98px) {
          .status-content {
            width: calc(100% - (594px + 180 * (100vw - 991px) / 450) + 135px);
          }

          .offcanvas-body {
            .invoice-content {
              max-width: calc(calc(594px + 180 * (100vw - 991px) / 450) - 135px);
            }
          }
        }
      }

      &.theme-offcanvas-2,
      &.offcanvas-profile-setting {
        width: calc(100% - 270px) !important;
        margin-left: 0;

        @media (max-width: 767.98px) {
          width: 100% !important;
        }
      }
    }
  }

  &:has(.profile-setting .responsive-dropdown .select-dropdown-2.show) {
    .profile-setting .responsive-dropdown {
      &:before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(221, 221, 221, 0.065);
        backdrop-filter: blur(0.7px);
        z-index: 4;
      }
    }
  }

  &.dropdown-open {
    &:has(.theme-offcanvas-2 .filter-dropdown-box .select-dropdown-menu.show) {
      .theme-offcanvas-2 .filter-dropdown-box {
        &:before {
          content: "";
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background: rgba(221, 221, 221, 0.4);
          backdrop-filter: blur(2px);
          z-index: 4;
        }
      }
    }

    &:has(.theme-offcanvas-2 .responsive-dropdown .select-dropdown-2.show) {
      .theme-offcanvas-2 .responsive-dropdown {
        &:before {
          content: "";
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background: rgba(221, 221, 221, 0.4);
          backdrop-filter: blur(2px);
          z-index: 4;
        }
      }
    }

    &:has(.profile-setting .filter-dropdown-box .select-dropdown-menu.show) {
      .profile-setting {
        .filter-dropdown-box {
          &:before {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(221, 221, 221, 0.4);
            backdrop-filter: blur(2px);
            z-index: 1;
          }
        }
      }
    }

    &:has(.auth-body .filter-dropdown-box.show) {
      .auth-body {
        .filter-dropdown-box {
          &:before {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(221, 221, 221, 0.4);
            backdrop-filter: blur(2px);
            z-index: 1;
          }
        }
      }
    }

    &:has(.profile-setting .dropdown-box .select-dropdown-menu.show,
      .theme-offcanvas-2 .dropdown-box .select-dropdown-menu.show) {

      .theme-offcanvas-2,
      .profile-setting {
        .dropdown-box {
          &:before {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(221, 221, 221, 0.5);
            backdrop-filter: blur(4px);
            z-index: 4;
          }
        }
      }
    }
  }

  &:has(.outside-dropdown) {
    .select-dropdown-menu {
      z-index: 8;
      border: 1px solid $gray-dark-color;
      border-radius: 0 0 18px 18px;
      border-top: none;
      width: 610px;
      overflow: hidden;
      padding: 10px 8px;
      box-shadow: 0px 6px 8px 0px rgba(57, 30, 30, 0.1098039216);
      margin-top: -7px;
      min-width: unset;

      @media (max-width: 1440.98px) {
        width: calc(639px + (610 - 639) * ((100vw - 1199px) / (1441 - 1199)));
      }

      @media (max-width: 890px) {
        width: calc(569px + (676 - 569) * ((100vw - 768px) / (890 - 768)));
      }

      @media (max-width: 767px) {
        width: calc(516px + (684 - 516) * ((100vw - 576px) / (767 - 576)));
      }

      @media (max-width: 575.98px) {
        position: fixed !important;
        bottom: 0 !important;
        top: unset !important;
        width: 100%;
        left: 0 !important;
        padding: 50px 20px;
        margin: 0;
        border-radius: 24px 24px 0 0;
        transform: unset !important;
        box-shadow: 0px -8px 20px 0px rgba(103, 0, 0, 0.1215686275);
        border-top: 1px solid $gray-dark-color;
        z-index: 9;

        .list-box {
          margin: 0;
          max-height: 230px;
          overflow: auto;
        }

        &::before {
          content: "";
          position: absolute;
          top: 8px;
          left: 50%;
          width: 30px;
          height: 3px;
          background: $gray-dark-color;
          transform: translateX(-50%);
        }

        &[data-popper-placement="top-end"] {
          padding: 50px 21px 98px;
        }

        .img-box {
          display: none;
        }

        .btn-close {
          position: absolute;
          top: 20px;
          right: 20px;
          width: 24px;
          height: 24px;
          border: 1px solid $gray-medium-color;
          border-radius: 100%;
          background-size: 11px 11px;
          padding: 0;
        }

        .dropdown-item {
          border-radius: 100px;
          padding: 16px 32px;
          line-height: 19.6px;

          +.dropdown-item {
            margin-top: 2px;
          }

          &:hover,
          &.active,
          &:active {
            color: $white;
            background-color: $primary-color-1;
            font-weight: 700;

            a {
              transform: unset;
              transition: none;
              color: $white;
            }
          }
        }
      }

      &::placeholder {
        color: $gray;
      }

      &[data-popper-placement="top-start"] {
        border-radius: 18px 18px 0 0;
        box-shadow: 0px -5px 8px 0px rgba(57, 30, 30, 0.1098039216);
        margin-bottom: -3px;
        margin-top: 0;
        border-bottom: none;
        border-top: 1px solid $gray-dark-color;
      }

      .list-box {
        overflow: auto;
        max-height: 220px;

        @media (max-width: 575.98px) {
          max-height: unset;
          height: 220px;
        }
      }

      .dropdown-no-data {
        margin: 23px 0;
        text-align: center;
        color: $dark-gray;
        line-height: 18.2px !important;
        font-size: calc(12.8px + 0.0625vw) !important;
        font-weight: 500 !important;
      }

      .dropdown-item {
        color: $dark-gray;
        cursor: pointer;
        padding: 10px 12px !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        border-radius: 4px;
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;

        @media (max-width: 575.98px) {
          border-radius: 100px;
          padding: 16px 32px !important;
          line-height: 19.6px;

          +.dropdown-item {
            margin-top: 2px;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:active,
        &:hover,
        &:focus {
          background-color: $primary-color-1;
          color: $white;
          font-weight: 700;

          span {
            color: $white;
          }
        }

        &:hover {
          .form-check-input {
            &::after {
              border-color: $primary-color-1;
            }
          }
        }

        p {
          margin: 0 !important;
        }

        +.dropdown-item {
          margin-top: 5px;
        }
      }
    }
  }

  &:has(.select-dropdown-menu[data-popper-placement="top-start"]) {
    .outside-dropdown {
      .dropdown-toggle {
        border-radius: 0 0 18px 18px;
        border-top: 1px solid transparent;
        border-bottom: 1px solid #d4cfd4;

        &:hover {
          border-radius: 0 0 18px 18px;
        }
      }
    }
  }
}

.page-wrapper {
  .page-header {
    margin-left: $sidebar-width;
    width: calc(100% - $sidebar-width);
    max-width: 100vw;
    position: fixed;
    top: 0;
    z-index: 8;
    transition: all 0.3s ease;
    padding: 52px 40px 26px 0;
    height: auto;
    align-items: flex-start;
    background-color: $gray-light-color;

    @media (max-width: 576px) {
      &:not(&.close_icon) {
        transform: unset !important;
        z-index: 9;
      }
    }

    @media (max-width: 767px) {
      display: flex;
      flex-wrap: nowrap;
      gap: calc(10px + (16 - 10) * ((100vw - 320px) / (768 - 320)));
    }

    &.close_icon {
      margin-left: 116px;
      width: calc(100% - 116px);
      transition: all 0.3s ease-in-out;

      .common-header {
        @media (max-width: 767px) {
          width: calc(100% - 24px - calc(10px + (16 - 10) * ((100vw - 320px) / (768 - 320))));
        }
      }

      &.w-user-header {
        margin-left: 116px;
        width: calc(100% - 116px);
      }
    }

    &.project-header {
      .page-title {
        max-width: fit-content;

        .common-f-flex {
          gap: 10px;
          margin-block: 8px 0;
          font-weight: 600;

          img {
            position: relative;
            height: auto;
            inset: 0;
          }

          .initial-letter {
            min-width: unset;
            background-color: $white;
            width: 32px;
            height: 32px;
          }
        }

        h2 {
          span {
            color: var(--gray);
            font-weight: 500;
            font-size: 14px;
            padding-left: 16px;
            line-height: 19.6px;

            &:empty {
              display: none;
            }

            @media (max-width: 767px) {
              padding-left: 8px;
            }
          }
        }
      }
    }

    .page-title {
      width: max-content;
      padding: 0;
      max-width: calc(52% + (100 - 52) * ((100vw - 992px) / (1920 - 992)));
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;

      @media (max-width: 991px) and (min-width: 768px) {
        max-width: calc(240px + (354 - 240) * ((100vw - 767px) / (1920 - 767)));
      }

      @media (max-width: 767.98px) {
        max-width: unset;
      }

      @media (max-width: 375px) {
        width: auto !important;
      }
    }

    .header-logo-wrapper {
      display: none;
      align-items: center;
      gap: 20px;
      width: auto;

      .logo-wrapper {
        a {
          display: inline-flex;
          width: 90px;
          height: 32px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    h2 {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 32px;
      line-height: 40px;
      color: $secondary-color-4;

      @media (max-width: 1440.98px) {
        font-size: calc(26px + (32 - 26) * ((100vw - 767px) / (1441 - 767)));
      }
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-block: 10px 0;

      @media (max-width: 1440.98px) {
        margin-top: calc(5px + (10 - 5) * ((100vw - 767px) / (1441 - 767)));
        line-height: calc(19px + (24 - 19) * ((100vw - 767px) / (1441 - 767)));
      }
    }

    &.w-user-header {
      margin-left: 250px;
      width: calc(100% - 250px);
    }

    .common-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      @media (max-width: 767px) {
        width: calc(100% - 24px - calc(10px + (16 - 10) * ((100vw - 320px) / (768 - 320))));
      }

      @media (max-width: 575px) {
        width: calc(100% - 24px - calc(10px + (16 - 10) * ((100vw - 320px) / (768 - 320))));
      }
    }

    &:has(.page-title .common-f-flex) {
      padding-left: 16px;

      .header-logo-wrapper {
        display: none;
      }

      .common-header {
        width: 100%;
      }

      .mobile-title {
        padding-left: 94px;
        position: relative;

        @media (max-width: 1440px) {
          padding-left: calc(60px + (94 - 60) * ((100vw - 991px) / (1441 - 991)));
        }

        @media (max-width: 991.98px) {
          padding-left: 60px;
        }

        @media (max-width: 767.98px) {
          padding-left: 47px;
        }

        @media (max-width: 575.98px) {
          padding-bottom: 10px;
        }

        .back-btn {
          @include flex_common;
          width: 40px;
          height: 40px;
          background-color: $gray-medium-color;
          border-radius: 100%;
          padding: 6px;
          transform: scale(-1);
          position: absolute;
          top: 5px;
          left: 29px;

          @media (max-width: 1440px) {
            left: calc(5px + (29 - 5) * ((100vw - 991px) / (1441 - 991)));
          }

          @media (max-width: 991.98px) {
            left: 5px;
          }

          @media (max-width: 767.98px) {
            width: 32px;
            height: 32px;
            left: 0;
            top: 3px;
          }

          img {
            @media (max-width: 575px) {
              position: relative;
              inset: unset;
              height: 100%;
              z-index: 0;
            }
          }
        }
      }

      ~.page-body-wrapper {
        .horizontal-main-tab {
          .horizontal-tab-content {
            .tab-pane {
              &.project-invoice {
                padding-right: unset;
                height: calc(100vh - 234px);

                @media (max-width: 1800px) {
                  height: calc(100vh - 230px);
                }

                @media (max-width: 1440px) {
                  height: calc(100vh - 225px);
                }

                @media (max-width: 767px) {
                  height: calc(100vh - 168px);
                }

                .project-head {
                  padding-right: 40px;

                  @media (max-width: 575px) {
                    padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                  }
                }

                .project-detail-invoice-table {
                  .table-responsive {
                    height: calc(100vh - 166px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
                    padding-right: 40px;

                    @media (max-width: 767px) {
                      height: calc(100vh - 105px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
                    }

                    @media (max-width: 575px) {
                      padding-right: calc(17px + (25 - 17) * ((100vw - 320px) / (768 - 320)));
                    }
                  }

                  .table-footer {
                    padding-right: 40px;

                    @media (max-width: 575px) {
                      padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                    }
                  }
                }
              }

              &.document-project {
                padding-right: unset;
                height: calc(100vh - 234px);

                @media (max-width: 1800px) {
                  height: calc(100vh - 230px);
                }

                @media (max-width: 1440px) {
                  height: calc(100vh - 225px);
                }

                @media (max-width: 767px) {
                  height: calc(100vh - 168px);
                }

                .document-head {
                  padding-right: 40px;

                  @media (max-width: 575px) {
                    padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                  }
                }

                .project-detail-document-table {
                  .table-responsive {
                    height: calc(100vh - 166px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
                    padding-right: 40px;

                    @media (max-width: 767px) {
                      height: calc(100vh - 105px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
                    }

                    @media (max-width: 575px) {
                      padding: 0;
                      padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                    }
                  }

                  .table-footer {
                    padding-right: 40px;
                    margin-top: 14px;

                    @media (max-width: 575px) {
                      padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                    }
                  }
                }
              }

              &.archived-invoice {
                padding-right: unset;
                height: calc(100vh - 234px);

                @media (max-width: 1800px) {
                  height: calc(100vh - 230px);
                }

                @media (max-width: 1440px) {
                  height: calc(100vh - 225px);
                }

                @media (max-width: 767px) {
                  height: calc(100vh - 168px);
                }

                .invoice-head {
                  padding-right: 40px;

                  @media (max-width: 575px) {
                    padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                  }
                }

                .project-head {
                  padding-right: 40px;

                  @media (max-width: 575px) {
                    padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                  }
                }

                .project-detail-invoice-table {
                  .table-responsive {
                    height: calc(100vh - 166px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
                    padding: 0;
                    padding-right: 40px !important;

                    @media (max-width: 767px) {
                      height: calc(100vh - 105px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
                    }

                    @media (max-width: 575px) {
                      padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320))) !important;
                    }
                  }

                  .table-footer {
                    padding-right: 40px;

                    @media (max-width: 575px) {
                      padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                    }
                  }
                }
              }

              &.draft-invoice {
                padding-right: unset;
                height: calc(100vh - 234px);

                @media (max-width: 1800px) {
                  height: calc(100vh - 230px);
                }

                @media (max-width: 1440px) {
                  height: calc(100vh - 225px);
                }

                @media (max-width: 767px) {
                  height: calc(100vh - 168px);
                }

                .draft-head {
                  padding-right: 40px;

                  @media (max-width: 575px) {
                    padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                  }
                }

                .draft-table {
                  .table-responsive {
                    height: calc(100vh - 166px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
                    padding: 0;
                    padding-right: 40px !important;

                    @media (max-width: 767px) {
                      height: calc(100vh - 105px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
                    }

                    @media (max-width: 575px) {
                      padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320))) !important;
                    }
                  }

                  .table-footer {
                    padding-right: 40px;

                    @media (max-width: 575px) {
                      padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                    }
                  }
                }
              }
            }
          }
        }

        .sidebar-wrapper {

          .logo-wrapper,
          .logo-icon-wrapper {
            padding-bottom: 75px;
          }

          .sidebar-main {
            .sidebar-content {
              height: calc(100vh - 163px);
            }
          }
        }

        .page-body {
          margin-top: 168px;

          @media (max-width: 767.98px) {
            margin-top: 120px;
          }

          @media (max-width: 575.98px) {
            margin-top: 103px;
          }
        }
      }
    }

    &:has(.back-btn) {
      padding-left: 16px;

      .header-logo-wrapper {
        display: none;
      }

      .common-header {
        width: 100%;
      }

      .mobile-title {
        padding-left: 94px;
        position: relative;
        padding-block: 1px 7px;

        @media (max-width: 1440px) {
          padding-left: calc(60px + (94 - 60) * ((100vw - 991px) / (1441 - 991)));
          padding-bottom: 0;

          &:has(.common-f-flex) {
            padding-bottom: 6px;
          }
        }

        @media (max-width: 991.98px) {
          padding-left: 60px;
        }

        @media (max-width: 767.98px) {
          padding-left: 47px;
        }

        @media (max-width: 575.98px) {
          display: flex;
          align-items: center;
          gap: 12px;
        }

        .back-btn {
          @include flex_common;
          width: 40px;
          height: 40px;
          background-color: $gray-medium-color;
          border-radius: 100%;
          padding: 6px;
          transform: scale(-1);
          position: absolute;
          top: 0;
          left: 29px;

          @media (max-width: 1440px) {
            left: calc(5px + (29 - 5) * ((100vw - 991px) / (1441 - 991)));
          }

          @media (max-width: 991.98px) {
            left: 5px;
          }

          @media (max-width: 767.98px) {
            width: 32px;
            height: 32px;
            left: 0;
            top: 3px;
          }

          @media (max-width: 575.98px) {
            position: relative;
            inset: unset;
            padding: 8px;
          }

          img {
            @media (max-width: 575px) {
              position: relative;
              inset: unset;
              height: 100%;
              z-index: 0;
            }
          }
        }
      }
    }
  }

  .responsive-span {
    &:empty {
      display: none;
    }
  }

  .page-body-wrapper {
    .sidebar-wrapper {
      position: fixed;
      top: 0;
      z-index: 9;
      height: auto;
      line-height: inherit;
      width: $sidebar-width;
      text-align: left;
      background-color: #f8f6f8;

      .toggle-sidebar {
        position: fixed;
        top: 54px;
        left: 0;
        width: 27px;
        height: 33px;
        border-radius: 0 6px 6px 0;
        border: 1px solid $gray-medium-color;
        border-left: none;
        cursor: pointer;
        padding: 4px;
        transition: 0.3s;
        justify-content: flex-start;

        &::before {
          content: "";
          transition: all 0.3s ease;
          width: 3px;
          height: 25px;
          background-color: $primary-color-1;
          border-radius: 2px;
          position: absolute;
          left: 8px;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover {
          border-color: $white;
        }
      }

      .logo-wrapper {
        padding: 50px 40px 34px;
        @include juuli-flex(flex, space-between, center);

        a {
          img {
            width: 120.46px;
            height: 38.24px;
          }
        }
      }

      .logo-icon-wrapper {
        display: none;

        img {
          max-width: 100%;
          height: auto;
        }
      }

      .sidebar-main {
        margin-top: 0;
        overflow: auto;

        &.custom-scrollbar,
        &.custom-scroll {
          &::-webkit-scrollbar {
            width: 0;
          }

          &::-webkit-scrollbar-thumb {
            background-color: transparent;
          }
        }

        .sidebar-content {
          justify-content: space-between;
          display: flex;
          flex-direction: column;
          height: calc(100vh - 122px);
          gap: 10px;
        }

        .sidebar-links {
          padding: 0 20px 0 40px;
          overflow: auto;

          .sidebar-list {
            .sidebar-link {
              gap: 12px;
              border: 2px solid transparent;
              display: flex;
              align-items: center;
              gap: 6px;
              border-radius: 100px;

              img {
                width: 36px;
                height: 36px;
                border-radius: 100%;
                border: 2px solid transparent;
              }

              span {
                font-size: 14px;
                font-weight: 700;
                line-height: 17.64px;
                color: var(--bs-table-color);

                @media (max-width: 575px) {
                  font-size: 16px;
                  line-height: 20.16px;
                  color: $dark-gray;
                }
              }

              &.active,
              &:hover {
                background-color: $neutral-color-2;

                img {
                  background-color: $white;
                }
              }
            }

            +.sidebar-list {
              margin-top: 8px;
            }
          }

          &.custom-scrollbar,
          .custom-scrollbar {
            min-height: 184px;
            padding-right: 25px;
          }
        }

        .offer-card {
          width: 100%;
          background-color: $neutral-color-1;
          position: relative;
          padding: 20px 20px 32px 20px;
          margin-top: 30px;
          border-top: 1px solid $neutral-color-2;

          .card {
            background-color: transparent;
            border-radius: 0;
            padding: 0;
            border: none;
          }

          .card-close {
            width: 32px;
            height: 32px;
            background-color: $white;
            border-radius: 100%;
            @include flex_common;
            transition: all 0.2s ease;
            position: absolute;
            top: -18px;
            left: 50%;
            transform: translateX(-50%) rotate(180deg);
            cursor: pointer;
            padding: 0;
            margin-bottom: 0;
            border: none;

            img {
              margin-top: -1px;
            }

            &.hide {
              transform: translateX(-50%) rotate(0deg);
            }
          }

          .detail {
            display: flex;
            flex-direction: column;
            gap: 20px;
            text-align: center;
            margin-top: 20px;

            p {
              font-size: 16px;
              font-weight: 600;
              line-height: 22.4px;
              margin-inline: 50px;
              margin-bottom: 0;
            }

            .offer-card-img {
              height: 123px;
              width: auto;
            }

            .btn {
              width: 100%;
              justify-content: center;
              font-size: 14px;
              font-weight: 700;
              line-height: 17.64px;
              padding: 11px 18px 11px 24px;

              img {
                height: 14.62px;
              }
            }
          }
        }
      }

      &.close_icon {
        width: auto;
        background-color: transparent;

        @media (max-width: 767.98px) {
          background-color: #f8f6f8;
        }

        .logo-wrapper {
          display: none;

          @media (max-width: 767px) {
            display: block;
          }
        }

        .logo-icon-wrapper {
          text-align: left;
          padding: 52px 20px 44px 40px;
          display: flex;
          align-items: center;

          @media (max-width: 767px) {
            padding: 20px;
          }

          img {
            height: 40px;
            width: 40px;
          }
        }

        .sidebar-main {
          .sidebar-content {
            height: calc(100vh - 160px);

            @media (max-width: 767.98px) {
              height: 100%;
            }
          }

          .sidebar-links {
            .sidebar-link {
              width: fit-content;

              span {
                display: none;
              }

              &:hover {
                background-color: $white;
                border: 2px solid $neutral-color-1;
                padding-right: 40px;
                box-shadow: $box-shadow-2;

                span {
                  display: block;
                  color: $dark-gray;
                }

                img {
                  border: 2px solid $neutral-color-1;
                }
              }
            }
          }
        }

        .offer-card {
          display: none;
        }

        ~.page-body {
          margin-left: 116px;

          &.w-user-name {
            margin-left: 116px;
            width: calc(100% - 116px);
          }
        }

        .toggle-sidebar {
          border-color: $white;

          &::before {
            left: calc(100% - 8px);
          }
        }
      }
    }

    .page-body {
      min-height: unset;
      margin-top: 122px;
      margin-left: $sidebar-width;
      transition: all 0.3s ease;
      position: relative;
      padding-right: 40px;

      &:has(.invoice-main) {
        .invoice-main {
          border-radius: 12px 12px 0 0;

          @media (max-width: 767.98px) {
            border-radius: 0;
            background-color: $white;
          }
        }
      }

      .status-box {
        @media (max-width: 575px) {
          margin-top: 0;
        }
      }

      .status-list {
        display: flex;
        flex-direction: column;
        padding: 0;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 4px;
          border-bottom: 1px solid $gray-dark-color;
          padding: 16px 0;

          &:nth-child(2) {
            .badge {
              background: $gray-medium-color !important;
              color: $gray;

              .arrow {
                &::before {
                  width: 0;
                  height: 0;
                  border-left: 4px solid transparent;
                  border-right: 4px solid transparent;
                  border-bottom: 5px solid $gray;
                  border-top: none;
                }
              }
            }
          }

          &:last-child {
            border-bottom: none;

            .badge {
              background: $info-light-color !important;
              color: $secondary-color-2;

              .arrow {
                &::before {
                  width: 0;
                  height: 0;
                  border-left: 4px solid transparent;
                  border-right: 4px solid transparent;
                  border-bottom: 5px solid $secondary-color-2;
                  border-top: none;
                }
              }
            }
          }

          h6 {
            font-size: 12px;
            font-weight: 500;
            line-height: 16.8px;
            color: $dark-gray;
          }

          span {
            font-size: 16px;
            font-weight: 700;
            line-height: 20.16px;
            color: $secondary-color-4;
          }

          .badge {
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
            @include juuli-flex(flex, space-between, center);
            gap: 8px;
            border-radius: 25px;
            min-width: 63px;
            padding: 1.5px 6px 1.5px 8px;
            background: $success-light-color !important;
            color: $success-medium-color;

            .arrow {
              margin-bottom: 2px;

              &:before {
                content: "";
                display: inline-block;
                position: relative;
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 5px solid $success-medium-color;
              }
            }

            &.paid {
              background-color: $success-light-color !important;
              color: $success-medium-color;

              .arrow {
                &:before {
                  border-width: 8px 5px 0px 5px;
                  border-top-color: $success-medium-color;
                }
              }
            }

            &.unpaid {
              background-color: $gray-medium-color !important;
              color: $gray;

              .arrow {
                &:before {
                  border-width: 0px 5px 8px 5px;
                  border-bottom-color: $gray;
                }
              }
            }

            &.total {
              background-color: $info-light-color !important;
              color: $secondary-color-2;

              .arrow {
                &:before {
                  border-width: 0px 6px 8px 6px;
                  border-bottom-color: $secondary-color-2;
                }
              }
            }
          }

          .bg-primary,
          .bg-success,
          .bg-warning {
            width: 8px;
            min-width: 8px;
            height: 8px;
            border-radius: 100%;
          }

          .bg-primary {
            background-color: $success-medium-color !important;
          }

          .bg-success {
            background-color: $gray !important;
          }

          .bg-warning {
            background-color: $secondary-color-2 !important;
          }
        }
      }

      .responsive-status-space {
        @media (max-width: 575px) {
          margin-bottom: 13px;
        }
      }

      .finance-main,
      .customer-main {
        padding: 40px;
        background-color: $white;
        border-radius: 12px;
        height: calc(100vh - 152px);
        overflow: auto;

        &.client-page {
          padding-right: unset;
          padding-bottom: 14px;

          @media (max-width: 767px) {
            height: calc(100vh - 80px);
          }

          @media (max-width: 575px) {
            height: unset;
          }

          .client-new {
            padding-right: 40px;

            @media (max-width: 575px) {
              padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
            }
          }

          .client-table {
            .table-responsive {
              height: calc(100vh - 60px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
              padding-right: 40px;

              @media (max-width: 767px) {
                height: calc(100vh - 20px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
              }

              @media (max-width: 575px) {
                padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
                height: unset;
              }
            }

            .table-footer {
              padding-right: 40px;

              @media (max-width: 575px) {
                padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
              }
            }

            &.responsive-client-table {
              tbody {
                tr {
                  td {
                    .user-name-box {
                      display: flex;
                      align-items: center;
                      gap: 17px;

                      @media (max-width: 575.98px) {
                        gap: 12px;
                      }

                      .initial-letter {
                        width: 40px;
                        min-width: 40px;
                        height: 40px;
                      }

                      .responsive-box {
                        h6 {
                          margin-top: 0;
                        }

                        span {
                          font-size: 12px;
                          font-weight: 500;
                          line-height: 16.8px;
                          color: $gray;

                          @media (max-width: 575.98px) {
                            margin-top: 8px;
                          }
                        }
                      }
                    }

                    &:nth-child(3),
                    &:nth-child(5) {
                      @media (max-width: 575px) {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &.freelancers-page {
          padding-right: unset;
          padding-bottom: unset;

          @media (max-width: 767px) {
            height: calc(100vh - 80px);
            padding-bottom: 24px;
          }

          @media (max-width: 575.98px) {
            height: calc(100vh - 72px);
          }

          .freelancers-new {
            padding-right: 40px;

            @media (max-width: 575px) {
              padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
            }
          }

          .client-table {
            .table-responsive {
              height: calc(100vh - 60px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
              padding-right: 40px;

              @media (max-width: 767px) {
                height: calc(100vh - 20px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 35px - 35px - 46px - 30px - 35px - 14px);
              }

              @media (max-width: 575px) {
                height: calc(100vh - 20px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 46px - 14px - 105px - 14px);
                padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
              }

              @media (max-width: 480.98px) {
                height: calc(100vh - 20px - calc(56px + (64 - 56) * ((100vw - 1398px) / (1920 - 1398))) - 50px - 14px - 136px - 14px);
              }
            }

            .table-footer {
              padding-right: 40px;

              @media (max-width: 575px) {
                padding-right: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
              }
            }
          }
        }

        &::-webkit-scrollbar {
          width: 8px;
          height: 0;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px 0px 0px 4px;
          background-color: $gray-dark-color;
        }
      }

      .customer-main {
        height: calc(100vh - 120px);
        border-radius: 12px 12px 0 0;

        @media (max-width: 575.98px) {
          height: calc(100vh - 60px);
        }

        .client-table {
          .custom-scrollbar {
            height: calc(100vh - 121px - 78px - 43px - 30px - 35px - 14px);

            @media (max-width: 992.98px) {
              height: calc(100vh - 107px - 78px - 43px - 30px - 35px - 14px);
            }

            @media (max-width: 767.98px) {
              height: calc(100vh - 68px - 66px - 42px - 30px - 35px - 14px);
            }

            @media (max-width: 575.98px) {
              height: calc(100vh - 68px - 91px - 42px - 30px - 35px - 14px);
            }

            @media (max-width: 480px) {
              height: calc(100vh - 131px - 66px - 42px - 30px - 35px - 14px);
            }
          }
        }
      }

      .invoice-main {
        .invoice-tab {
          overflow: auto;
          background-color: $white;

          @media (max-width: 767.98px) {
            padding-inline: 24px;
            background-color: transparent;
          }

          @media (max-width: 420px) {
            padding: 0;
          }
        }
      }
    }
  }

  .invoice-main {
    .react-datepicker-popper {
      background-color: $white;
      border: 1px solid $gray-dark-color;
      border-radius: 12px;
      overflow: hidden;
      text-align: center;

      .react-datepicker {
        margin-top: 0;
        width: 255px;
        border: unset;
        border-radius: 0;
        color: $title;
      }
    }
  }
}

.money-main {
  .money-content {
    border-radius: 12px;
    padding: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
    display: flex;
    align-items: center;
    gap: 40px;

    .money-img {
      max-width: min-content;
      min-width: 250px;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    h2 {
      font-size: calc(24px + (32 - 24) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 700;
      line-height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
      color: $btn-hover;
      margin-bottom: 6px;
    }

    h3 {
      font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 700;
      line-height: calc(22px + (33 - 22) * ((100vw - 320px) / (1920 - 320)));
      color: $secondary-color-4;
    }

    p {
      font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 500;
      line-height: 19px;
      color: $dark-gray;
      margin-block: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
    }

    ul {
      display: flex;
      align-items: center;
      gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
      padding-left: 0;
      flex-wrap: wrap;

      li {
        display: flex;
        align-items: baseline;
        gap: 8px;
        color: $secondary-color-4;
        font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
      }
    }

    .btn {
      white-space: nowrap;
      padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320))) calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }

    &.info-light {
      background-color: $neutral-color-4;
    }

    &.primary-light {
      background-color: $neutral-color-1;
    }
  }
}

.message-main {
  height: 100%;

  .message-right {
    border-left: 1px solid $gray-medium-color;
    padding: 30px 0 0;

    .message-right-part {
      margin-inline: auto;
      width: 85%;

      ~.col-12 {
        height: calc(100% - 100px);
      }
    }
  }

  .message-left {
    padding: 30px;
    padding-bottom: 0;

    .message-left-part {
      ul {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 0;
        margin-bottom: 30px;

        li {
          display: flex;
          align-items: baseline;
          gap: 12px;

          span {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            color: $secondary-color-4;
            min-width: 120px;

            &.badge {
              justify-content: center;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
            }
          }

          p {
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
            color: $dark-gray;
            margin: 0;
          }
        }
      }
    }
  }

  .message-box {
    background-color: $gray-light-color;
    border: none;
    border-radius: 12px 0 0 12px;
    position: relative;
    overflow: hidden;
    height: 100%;

    .message-box-data {
      padding-inline: 30px;
      padding-top: 30px;
      padding-bottom: 160px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      height: 61vh;
      overflow: auto;

      .profile-image {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border: 1px solid $white;
      }

      .receiver-data,
      .sender-data {
        display: flex;
        align-items: flex-start;
        gap: 16px;

        .initial-letter {
          background-color: $white;
          border: 1px solid $gray-medium-color;
        }

        .timing {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: $gray;
        }

        .receiver-message {
          border-radius: 0 12px 12px 12px;

          padding: 16px 24px;
          color: $secondary-color-4;
          font-weight: 500;
          line-height: 24px;
        }
      }

      .receiver-data {
        justify-content: start;

        .receiver-message {
          background-color: $neutral-color-4;
        }
      }

      .sender-data {
        justify-content: end;
        margin-left: 60px;

        .receiver-message {
          background-color: $neutral-color-2;
        }
      }
    }
  }

  .chat-input-sec {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    box-shadow: 0px -3px 5px rgba($dark-gray, 0.08);
    border-radius: 12px 12px 0 0;
    background-color: $white;

    .share {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }

    ul {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 0;

      li {
        cursor: pointer;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .chat-input {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;

      input {
        width: 100%;
        padding: 4px 0;
        color: $gray;
        border: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;

        &:focus,
        &:focus-visible,
        &:hover,
        &.active,
        &:active {
          border: none;
          outline: none;
        }
      }
    }
  }

  .chat-data {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .document-sec {
    padding-top: 20px;
    margin-top: 30px;
    border-top: 1px solid $gray-dark-color;

    .document-list {
      height: 18vh;
      overflow: auto;
    }
  }

  .chat-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0;
    overflow: auto;
    height: 32vh;

    .chat-item {
      display: flex;
      align-items: center;
      gap: 12px;
      border: 1px solid $gray-dark-color;
      padding: 8px 16px;
      border-radius: 8px;
      border-left: 4px solid $primary-color-1;
    }
  }
}

.payment-main {
  background-color: $white;
  border-radius: 12px;
  min-height: calc(100vh - 125px);

  .payment-left {
    padding: 50px 50px 0;

    label {
      font-size: 18px;
      font-weight: 700;
      line-height: 22.68px;
      margin-bottom: 0;
    }

    .address-indicate-note {
      .indicate {
        margin-bottom: 0;
      }
    }

    .payment-detail {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 50px;

      li {
        display: flex;
        align-items: center;
        gap: 8px;

        span,
        p {
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
        }

        span {
          color: $gray;
          min-width: 130px;
        }

        p {
          color: $secondary-color-4;
          margin: 0;
        }
      }
    }
  }

  .payment-right {
    padding: 0 30px;
    padding-top: 141px;
    border-left: 1px solid $gray-medium-color;

    h5 {
      margin-bottom: 68px;
      font-size: 20px;
      line-height: 25.2px;
      font-weight: 700;
    }

    .invoice-action {
      margin-top: 54px;
      padding: 16px 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-block: 1px solid $gray-dark-color;

      li {
        font-size: 14px;
        font-weight: 700;
        line-height: 17.64px;
        display: flex;
        align-items: center;
        gap: 4px;

        img {
          width: 32px;
          height: 32px;
          padding: 5px;
        }
      }
    }

    .invoice-data {
      li {
        span {
          min-width: 110px;
          font-size: 14px;
          font-weight: 700;
          line-height: 17.64px;
        }

        p {
          width: 100%;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          font-size: 15px;
          font-weight: 500;
          line-height: 18.9px;
        }
      }
    }
  }
}

.custom-container {
  height: calc(100vh - 120px);
}

body {
  &.mobile-dashboard {
    .page-body {
      .container-fluid {
        min-height: calc(100vh - 152px);

        .main-row {
          min-height: calc(100vh - 152px);
        }
      }
    }
  }
}

.project-detail-container {
  .project-detail-tab {
    background-color: $white;
  }

  @media (max-width: 767.98px) {
    .project-details-box {
      padding: 18px 16px 8px;
      background-color: $white;
      border-radius: 12px 12px 0 0;
      transition: all 0.3s ease-in-out;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 36px);
        border-top: 1px solid $gray-dark-color;
      }

      .horizontal-tab {
        background-color: $white;
        border-radius: 12px 12px 0 0;
        gap: 10px;

        .nav-item {
          .nav-link {
            padding-top: 0;
            position: relative;
            font-size: 16px;
            border: none;
            padding-bottom: 12px;

            &.active {
              &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 5px;
                border-radius: 100px;
                background-color: $primary-color-1;
              }
            }
          }
        }
      }
    }

    .project-detail-tab {
      border-radius: 0 !important;

      .tab-pane {
        border-radius: 0 0 12px 12px !important;

        &.draft-invoice {
          .draft-table {
            table {
              .responsive-body {
                tr {
                  td {
                    &.invoice-number {
                      @media (max-width: 575px) {
                        padding-top: 0 !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &.project-invoice {
          .project-detail-invoice-table {
            table {
              tbody {
                tr {
                  td {
                    &.invoice-date-box {
                      padding-right: 25px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.project-container {
  .horizontal-tab-content {

    .all-invoice,
    .draft-invoice {
      border-radius: 0 !important;

      .all-project-table,
      .project-all-invoice-table,
      .draft-table {
        .table-responsive {
          @media (max-width: 1440px) and (min-width: 1400px) {
            height: calc(100vh - 124px - 56px - 78px - 70px - 14px - 30px);
          }

          @media (max-width: 1400px) {
            height: calc(100vh - 122px - 56px - 78px - 70px - 14px - 30px);
          }

          @media (max-width: 1299.98px) {
            height: calc(100vh - 121px - 54px - 78px - 70px - 14px - 30px);
          }

          @media (max-width: 1199.98px) {
            height: calc(100vh - 123px - 52px - 78px - 70px - 14px - 30px);
          }

          @media (max-width: 1024.98px) {
            height: calc(100vh - 124px - 47px - 78px - 70px - 14px - 30px);
          }

          @media (max-width: 991.98px) {
            height: calc(100vh - 124px - 47px - 78px - 70px - 14px - 30px);
          }

          @media (max-width: 767.98px) {
            height: calc(100vh - 82px - 48px - 78px - 70px - 14px - 30px);
          }

          @media (max-width: 676.98px) and (min-width: 575.98px) {
            height: calc(100vh - 80px - 47px - 78px - 70px - 14px - 30px);
          }

          @media (max-width: 575.98px) {
            height: calc(100vh - 72px - 60px - 66px - 70px - 14px - 17px);
          }

          @media (max-width: 480.98px) {
            height: calc(100vh - 72px - 60px - 66px - 109px - 14px - 17px);
          }
        }
      }
    }

    .all-invoice {
      .project-all-invoice-table {
        .table-responsive {
          @media (max-width: 1299.98px) {
            height: calc(100vh - 122px - 54px - 78px - 70px - 14px - 30px);
          }

          @media (max-width: 1024.98px) {
            height: calc(100vh - 125px - 47px - 78px - 70px - 14px - 30px);
          }

          @media (max-width: 767.98px) {
            height: calc(100vh - 82px - 48px - 78px - 70px - 14px - 30px);
          }

          @media (max-width: 676.98px) and (min-width: 575.98px) {
            height: calc(100vh - 80px - 47px - 78px - 70px - 14px - 30px);
          }

          @media (max-width: 575.98px) {
            height: calc(100vh - 75px - 60px - 66px - 70px - 14px - 17px);
          }

          @media (max-width: 480.98px) {
            height: calc(100vh - 85px - 60px - 66px - 98px - 14px - 17px);
          }
        }

        .responsive-body {
          tr {
            td {
              &:first-child {
                @media (max-width: 575.98px) {
                  display: block;
                  padding-block: 0;
                  border-bottom: none;
                  position: absolute;
                  top: calc(47px + (51 - 47) * ((100vw - 320px) / (575 - 320)));
                  left: calc(114px + (116 - 114) * ((100vw - 320px) / (575 - 320)));

                  .responsive-span,
                  .responsive-box span:first-child {
                    display: none;
                  }
                }

                .responsive-box {
                  span {
                    &:first-child {
                      font-size: 14px;
                      font-weight: 600;
                      line-height: 17.64px;
                      color: #252133;
                    }
                  }
                }
              }
            }

            td {
              max-width: 265px;

              @media (max-width: 575.98px) {
                max-width: unset;
              }
            }
          }
        }
      }
    }
  }
}

.order-dashboard {
  .custom-row {
    @media (min-width: 1400px) {
      >.col-xxl-9 {
        width: 79.76%;
      }

      >.col-xxl-3 {
        width: 20.24%;
      }
    }

    &.g-2,
    .g-2 {
      @media (max-width: 575.98px) {
        --bs-gutter-y: 12px;
      }

      @media (max-width: 480px) {
        --bs-gutter-x: 0;
      }
    }
  }

  .dashboard-custom-height {
    height: 100%;
    display: block;

    .col-height {
      height: calc(100% - 360px - var(--bs-gutter-y));

      @media (max-width: 1199px) {
        height: auto;
      }

      >div {
        height: 100%;

        @media (max-width: 1199px) {
          height: auto;
        }
      }

      &:has(.no-data-found) {
        height: calc(100% - 359px - var(--bs-gutter-y));

        @media (max-width: 1199px) {
          height: auto;
        }

        .recent-invoice-card {
          height: 100%;

          .card-body {
            height: 100%;

            .no-data-found {
              height: 100%;
            }
          }
        }
      }
    }

    &:has(.min-h-content .spinner-loader) {
      .col-height {
        height: calc(100% - 262px - var(--bs-gutter-y));

        @media (max-width: 1199px) {
          height: auto;
        }
      }
    }
  }

  .dashboard-custom-height-2 {
    .min-h-content {
      .card {
        padding: 24px 16px;

        @media (max-width: 575.98px) {
          padding: 26px 18px 32px;
        }
      }
    }

    .col-height {
      height: calc(100% - 346px - var(--bs-gutter-y));

      @media (max-width: 1440.98px) {
        height: auto;
        max-height: calc(100vh - 454px - var(--bs-gutter-y));
      }

      @media (max-width: 1199px) {
        height: auto;
      }

      &:has(.no-data-found) {
        height: calc(100vh - 146px - var(--bs-gutter-y));

        @media (max-width: 1199px) {
          height: auto;
        }

        .latest-transaction-card {
          height: 100%;

          .card-body {
            height: calc(100% - 20px);

            .no-data-found {
              height: 100%;
            }
          }
        }
      }
    }

    .card {
      padding: 24px 16px;

      @media (max-width: 575.98px) {
        padding: 30px 20px 50px;
      }
    }

    .col-height {
      @media (max-width: 1199px) {
        height: auto;
      }
    }

    &:has(.invoice.hide) {
      .col-height {
        height: calc(100% - 80px - var(--bs-gutter-y));
      }
    }

    .show {
      ~div {
        height: calc(100% - 72px - var(--bs-gutter-y));
      }
    }
  }

  .dashboard-custom-height-3 {
    .col-height {
      height: calc(100% - 372px - var(--bs-gutter-y));

      @media (max-width: 1199px) {
        height: auto;
      }
    }

    &:has(.invoice.hide) {
      .col-height {
        height: calc(100% - 80px - var(--bs-gutter-y));
      }
    }

    .show {
      ~div {
        height: calc(100% - 72px - var(--bs-gutter-y));
      }
    }
  }

  .profile-card-box {
    padding: 0;
    background-color: transparent;

    .btn-solid {
      padding: 12px 24px !important;
      margin-left: auto;
      margin-top: 16px;

      @media (max-width: 575px) {
        margin-left: 0;
        display: block;
        width: 100%;
        position: relative;

        img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 24px;
          height: 22.72px;
        }

        .loader {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 24px;
        }

        &:has(.loader) {
          img {
            display: none;
          }
        }
      }
    }
  }
}

.business {
  .profile-card {
    padding: 8px;

    .horizontal-tab-content {
      padding: 45px 24px 16px 42px;
      background-color: #faf7fa;
      max-height: 277px;
      position: unset !important;

      @media (max-width: 575px) {
        padding-inline: 40px;
        max-height: unset;
      }

      .horizontal-tab-content {
        padding: 0;
      }

      .tab-pane {
        .profile-chart {
          width: 110px;
          height: 110px;
          padding: 11px;

          @media (max-width: 575px) {
            margin-top: 0;
          }
        }

        img {
          height: 100%;
          width: 100%;
        }

        p {
          @media (max-width: 575px) {
            width: unset;
            padding: 0;
            text-align: left;
            margin-block: 24px 47px;
          }
        }
      }

      .nav-item {
        .nav-link {
          padding: 14px 42px 10px;
          border-radius: 6px 6px 0 0;

          &.active {
            background-color: #faf7fa;
          }
        }
      }
    }

    .horizontal-tab {
      padding: 0;

      .tab-pane {
        .profile-chart {
          width: 110px;
          height: 110px;
        }

        img {
          height: auto;
        }
      }

      .nav-item {
        .nav-link {
          padding: 14px 42px 10px;
          border-radius: 6px 6px 0 0;

          &.active {
            background-color: #faf7fa;
          }
        }
      }
    }
  }

  .filter-dropdown-box {
    position: absolute;
    top: 7px;
    right: 16px;
    max-width: 156px;

    @media (max-width: 575px) {
      display: none;
    }

    .select-btn {
      background-color: $white;
      border: 1px solid $gray-dark-color;
      padding: 7px 16px;
      font-size: 12px;
      height: unset;
    }
  }

  .dashboard-custom-height {
    .col-height {
      height: calc(100% - 274px - var(--bs-gutter-y));

      @media (max-width: 1199.98px) {
        height: auto;
      }
    }
  }

  .dashboard-custom-height-2 {
    .min-h-content {
      height: 448px;

      @media (max-width: 1199.98px) {
        height: auto;
      }

      >.card {
        height: 100%;

        @media (max-width: 1199.98px) {
          height: auto;
        }
      }
    }

    .col-height {
      max-height: calc(100% - 422px - var(--bs-gutter-y));
      height: 100%;

      @media (max-width: 1199.98px) {
        max-height: unset;
        height: auto;
      }
    }
  }

  .invoice-detail {
    ul {
      li {
        span {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
        }
      }
    }
  }
}

/* Project -> Events tab */
.message-div-data {
  .sender-data {
    img {
      height: 40px;
    }
  }
}

.draft-head,
.freelancer-new,
.document-head {
  @media (max-width: 991px) and (min-width: 767px) {
    .tab-right-filter {
      .search-input-form {
        .search-input {
          &.full-search {
            min-width: 350px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .tab-right-filter {
      .search-input-form {
        .search-input {
          .search-img {
            display: block !important;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .tab-right-filter {
      .search-input-form {
        width: 100%;

        .search-input {
          background-color: unset;
          border: unset;
          padding: 0;
          box-shadow: none;
          min-width: 230px;

          &::before {
            display: none;
          }

          img {
            left: 12px;

            &.search-img-hover {
              display: none !important;
            }
          }

          .close-img {
            right: 8px;
          }
        }
      }
    }
  }
}

/**=====================
  Form CSS end
==========================**/

body {
  @media (max-width: 575.98px) {
    .page-wrapper {
      .page-body-wrapper {
        .page-body {
          .project-container {
            .horizontal-main-tab {
              .all-projects {
                .all-project-table {
                  .table-responsive {
                    height: unset !important;
                  }
                }
              }

              .all-invoice {
                .table-responsive {
                  height: unset !important;
                }
              }
            }
          }

          .invoice-main {
            .invoice-tab-content {
              height: unset;
              min-height: calc(100vh - 115px);
            }

            .summary-tabs {
              .invoice-main-content {
                height: unset;
              }
            }
          }

          .project-detail-container {
            .project-detail-tab {
              overflow: unset !important;

              .custom-scroll,
              .tab-pane {
                height: unset !important;
              }
            }
          }
        }
      }
    }

    &.page-down {
      .page-wrapper {
        .page-header {
          position: unset;
          inset: unset;
          transition: all 0.3s ease-in-out;
        }

        .invoice-main {
          .invoice-nav-tab-box {
            transform: translateY(-100%);
          }
        }
      }

      .page-wrapper {
        .page-body-wrapper {
          .page-body {
            margin-top: 0 !important;
          }
        }
      }

      &.main-dashboard {
        .page-wrapper {
          .page-header {
            top: -115px !important;
            position: absolute;

            .page-title {
              .mobile-logo {
                .mobile-title {
                  top: -115px !important;
                  z-index: 2;
                  transition: all 0.3s ease-in-out;
                }
              }
            }
          }

          .page-body-wrapper {
            .page-body {
              margin-top: 28px;
            }
          }
        }
      }
    }

    &.page-up {
      &.main-dashboard {
        .page-wrapper {
          .page-header {
            .page-title {
              .mobile-logo {
                .mobile-title {
                  top: 60px;
                  transition: all 0.3s ease-in-out;
                }
              }
            }
          }
        }
      }
    }
  }
}

body {
  @media (max-width: 767px) {
    .page-header--visible {
      transform: translateY(0);
    }

    .page-header--hidden {
      transform: translateY(-100%);
    }
  }
}