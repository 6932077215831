/**=====================
  Auth CSS starts
==========================**/

.log-in-section {
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: 100vh;
  padding: 32px 40px;
  overflow: auto;
  padding-left: 0;

  /* Testing css  */
  height: calc(var(--vh, 1vh) * 100);
  box-shadow: 0 0 8px rgba($dark-color, 0.4);

  @media (max-width: 1400px) {
    padding-left: 40px;
  }

  @media (max-width: 575px) {
    padding: 64px 20px 28px 28px;

    @media (max-height: 850px) {
      gap: 70px;
    }
  }

  @media (max-width: 360px) {
    padding: 64px 10px 28px 18px;
  }


  @media (max-height: 576px) {
    height: calc(100vh - 70px);
  }

  @media (max-height: 600px) {
    padding: 10px;
  }

  @media (max-height: 890px) {
    padding-bottom: 60px;
  }

  .help-div {
    display: inline-block;
    position: absolute;
    bottom: 18px;
    right: 23px;
    background: $white;
    border-radius: 100%;
    padding: 8.25px;
    z-index: 5;
    cursor: pointer;

    img {
      @include juuli-size(27.5px, 27.5px);
    }
  }

  .login-content {
    height: 100%;

    .auth-left {
      width: 45%;

      @media (max-width: 1199px) {
        width: 60%;
      }

      @media (max-width: 991px) {
        width: 70%;
      }

      @media (max-width: 767px) {
        width: 90%;
      }

      @media (max-width: 576px) {
        width: 100%;
      }
    }

    .auth-right {
      width: 55%;
    }
  }

  .login-main {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: space-between;
    padding: 0;
    height: 100%;
    width: 60%;
    margin: 0 auto;
    padding-block: 94px 28px;

    @media (max-width: 1640px) {
      width: 69%;
    }

    @media (max-width: 1430px) {
      width: 74%;
      padding-block: 44px 20px;
    }

    @media (max-width: 1380px) {
      width: 80%;
    }

    @media (max-width: 1290px) {
      width: 90%;
    }

    @media (max-width: 991px) {
      width: 100%;
    }

    @media (max-width: 576px) {
      padding: 0;
    }

    @media (max-height: 600px) {
      gap: 30px;
    }

    @media (max-height: 460px) {
      gap: 10px;
    }

    .logo-wrapper {
      @include juuli-flex(flex, space-between, center);
      width: 100%;
      position: relative;

      img {
        height: 68px;
        width: auto;
        object-fit: contain;

        @media (max-width: 1430px) {
          height: 54px;
        }

        @media (max-width: 767px) {
          height: 50px;
        }

        @media (max-width: 360px) {
          height: 38px;
        }
      }

      .login-badge {
        background-color: $white;
        border-radius: 36px;
        padding: 10px 25px 8px 24px;
        @include juuli-flex(flex, center, center);
        gap: 11px;
        font-weight: 700;
        font-size: 14px;
        color: $black;

        @media (max-width: 1199px) {
          padding: 11px 21px 12px 14px;
        }

        @media (max-width: 480px) {
          gap: 7px;
          padding: 13px 12px;
        }

        svg {
          @include juuli-size(18px, 16px);
          stroke: $primary-color-1;
          fill: $white;
        }
      }
    }

    .auth-card {
      .auth-title {
        h2 {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 42px;
          display: block;
          line-height: 33.6px;

          @media (max-height: 1080px) and (min-height: 650px) {
            margin-bottom: calc(10px + (42 - 10) * ((100vh - 650px) / (1080 - 650)));
          }

          @media (max-height: 650px) {
            margin-bottom: 10px;
          }

          @media (max-height: 600px) {
            margin-bottom: 0;
          }
        }

        .login-account {
          font-weight: 500;
          color: $title;
          display: flex;
          align-items: center;
          gap: 4px;
          line-height: 19.6px;
          margin-bottom: 22px;
          font-weight: 500;
          font-size: 14px;

          @media (max-width: 575.98px) {
            margin-bottom: 40px;
          }

          @media (max-height: 600px) {
            margin-bottom: 20px;
          }

          a {
            color: $link-color;
            font-size: 14px;
            line-height: 17.64px;
            font-weight: 600;
          }
        }
      }

      .login-detail {
        .mb-3 {
          margin: 0 !important;
        }

        form {
          .mb-20 {
            @media (max-width: 575px) {
              margin-bottom: 12px;
            }
          }

          .check-required {
            align-items: unset;

            .form-check-input {
              margin-top: 3px;
            }

            label {
              flex-wrap: wrap;
            }
          }
        }
      }

      .btn {
        padding: $btn-padding;
        width: 100%;
        justify-content: center;
        font-size: 16px;
        font-weight: 700;
        box-shadow: unset;
        line-height: 20px;
        color: $white;
        margin: 30px 0 27px 0;

        @media (max-height: 600px) {
          margin: 20px 0 27px 0;
        }

        &:hover {
          background-color: $btn-hover;
        }

        &.active,
        &:focus {
          background-color: $btn-active;
        }
      }

      .forgot-pass {
        display: flex;
        align-items: center;
        gap: 6px;
        justify-content: flex-end;

        @media (max-width: 575.98px) {
          margin-top: 26px;
          justify-content: center;
        }

        a {
          font-size: 14px;
          font-weight: 700;
          line-height: 17.64px;
          color: $primary-color-1;

          &.text-dark {
            color: $dark-gray;
          }
        }

        &.signup {
          margin-top: 50px;
          margin-bottom: -30px;

          @media (max-width: 1199px) {
            margin-bottom: 0;
            margin-top: calc(34px + (50 - 34) * ((100vw - 320px) / (1200 - 320)));
          }
        }
      }

      .login-detail {
        .form-control {
          border: 1px solid $gray-dark-color;
        }
      }
    }

    .social-media {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      padding: 0;
      flex-wrap: nowrap;

      @media (max-width: 1286px) and (min-width: 1200px) {
        li {
          .social-icon {
            justify-content: center;
          }
        }
      }

      @media (max-width: 575px) {
        justify-content: center;
      }

      @media (max-width: 1199px) {
        margin-top: 46px;
      }

      @media (max-width: 575px) {
        margin: 0;
        flex-wrap: unset;
      }

      li {
        background-color: $white;
        border: 1px solid $gray-medium-color;
        border-radius: 30px;
        width: 100%;

        .social-icon {
          display: flex;
          align-items: center;
          gap: 10px;
          color: $secondary-color-4;
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
          padding: 8px 12px 8px 8px;

          img {
            width: 30px;
            height: 30px;
          }

          span {
            white-space: nowrap;
          }
        }
      }
    }

    .form-group {
      &:has(.form-control.required-field) {
        .required-note {
          display: flex;
          justify-content: end;
          align-items: center;
          margin-top: 12px;

          small {
            position: relative;
            padding: 6px 18px;
            border-radius: 24px;
            width: max-content;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: $error-medium-color;
            background-color: $error-light-color;

            &::before {
              content: "";
              display: inline-block;
              left: 14px;
              position: absolute;
              top: -8px;
              border-style: solid;
              border-width: 0px 8px 8px 8px;
              height: 0;
              width: 0;
              border-color: transparent;
              border-bottom-color: $error-light-color;
            }
          }
        }
      }
    }

    &:has(.verification-form) {
      justify-content: unset;
      gap: 0;

      .auth-card {
        display: flex;
        flex-direction: column;
        height: calc(100% - 68px);
        justify-content: center;
      }
    }
  }

  /* Sign-up */
  &.freelancer-signup {
    .login-main {
      .login-detail {
        .form-group {
          margin-bottom: 20px !important;

          .form-check {
            label {
              display: flex;
              gap: 5px;
            }
          }
        }
      }
    }
  }
}

.password-indicate {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: nowrap;
  margin-top: 8px;
  padding: 0;
  margin-inline: calc(12px + (29 - 12) * ((100vw - 320px) / (1920 - 320)));

  li {
    height: 4px;
    width: 100%;
    background-color: $gray-color;
  }

  &.weak {
    li {
      &:first-child {
        background-color: $error-medium-color;
      }
    }
  }

  &.medium {
    li {

      &:nth-child(1),
      &:nth-child(2) {
        background-color: $warning-medium-color;
      }
    }
  }

  &.strong {
    li {
      background-color: $success-medium-color;
    }
  }
}

.password-indicate-note,
.address-indicate-note,
.payment-indicate-note {
  @include flex_common;
  width: 100%;
  position: relative;
  margin-bottom: 50px;

  .indicate {
    display: flex;
    align-items: center;
    gap: 14px;
    position: absolute;
    right: 4%;
    top: 10px;
    z-index: 2;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;

    .more-info {
      line-height: 1;
      position: relative;

      .tip {
        width: 16px;
        height: 16px;
      }

      .info {
        position: absolute;
        top: -6px;
        left: -6px;
        display: none;
        transform: translateY(-5px);
        transition: all 0.25s ease;
      }

      &:hover {
        .info {
          display: block;
          transform: translateY(0px);
          transition: all 0.25s ease;
          width: 223px;
          background-color: $info-light-color;
          border-radius: 8px;
          padding: 4px;

          .info-icon {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            background-color: $info-medium-color;
            @include flex_common;

            img {
              width: 14px;
              height: 14px;
            }
          }

          p {
            font-size: 12px;
            font-weight: 500;
            line-height: 20.4px;
            padding: 20.4px;
            margin: 0;
            color: $info-dark-color;
          }
        }
      }
    }
  }
}

.address-indicate-note,
.payment-indicate-note {
  display: block;
  width: auto;
  margin-bottom: 0;

  .indicate {
    position: static;
    margin-bottom: 0.7rem;

    .more-info {
      &:hover {
        .info {
          display: flex;
          align-items: center;
          border-radius: 25px;
          width: max-content;

          p {
            padding-block: 0;
          }
        }
      }
    }
  }
}

.password-indicate-note {
  .indicate {
    .more-info {
      .info-icon {
        img {
          width: 16px;
          height: 16px;
        }
      }

      p {
        padding: 0 11px !important;
        width: calc(100% - 20px);
      }

      &:hover {
        .info {
          display: flex !important;
        }
      }
    }
  }
}

.business-auth-card {
  .login-detail {
    form {
      @media (max-width: 575.98px) {
        display: flex;
        flex-direction: column;
      }
    }

    .resend-code {
      margin: 32px 0 0;
      width: auto;

      @media (max-width: 575.98px) {
        margin-top: 20px;
        gap: 101px;
        order: 0;
      }

      button {
        width: unset !important;
        padding: 0 !important;
        font-size: 14px !important;
        margin: 0 !important;
        font-weight: 700 !important;
        line-height: 17.64px !important;
        color: $secondary-color-4 !important;
        background: none !important;
      }
    }

    .action-btn {
      @media (max-width: 575.98px) {
        order: 1;
      }
    }
  }
}

.image-main {
  padding: 0;
  padding-left: 0;
  width: 100%;
  height: calc(100vh - 64px);
  position: relative;
  background-image: url(../../images/svg/banner/freelancer-bg.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;

  .image-contain {
    width: 100%;
    height: 100%;

    &.business-banner {
      border-radius: 20px;
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(../../images/svg/banner/business-banner.svg);
    }

    .login-badge {
      display: flex;
      align-items: center;
      gap: 8px;
      position: absolute;
      top: calc(70px + (80 - 70) * ((100vw - 992px) / (1920 - 992)));
      right: calc(70px + (80 - 70) * ((100vw - 992px) / (1920 - 992)));
      background-color: $white;
      border-radius: 30px;
      padding: 8px 20px;
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;

      img {
        width: 18px;
        height: 18px;
      }
    }

    .image-title {
      font-size: 50px;
      font-weight: 800;
      line-height: 1.1;
      color: $primary-color-1;
      position: absolute;
      top: 94px;
      left: 55px;
      width: calc(100% - calc(227px + (448 - 227) * ((100vw - 1440px) / (1920 - 1440))));
      text-align: left;

      @media (max-height: 1024px) and (min-height: 650px) {
        top: calc(43px + (94 - 43) * ((100vh - 650px) / (1024 - 650)));
      }

      @media (max-height: 650px) {
        top: 43px;
      }

      @media (max-width: 1400px) {
        font-size: calc(39px + (50 - 39) * ((100vw - 1199px) / (1440 - 1199)));
      }
    }

    .freelance-profile {
      position: absolute;
      top: calc(50% + 18px);
      transform: translateY(-50%);
      margin: 0;
      right: calc(135px + (205 - 135) * ((100vw - 1440px) / (1920 - 1440)));
      height: 51.75vh;
    }

    .auth-content {
      width: 330px;
      height: auto;
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
      padding: 8px;
      position: absolute;
      bottom: 30px;
      left: 62px;

      p {
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        font-family: $roboto;
        margin: 0;
      }
    }

    .quote {
      width: 34px;
      height: 30px;
    }

    .review {
      display: flex;
      align-items: center;
      gap: 20px;

      .rate {
        display: flex;
        align-items: center;
        gap: 2px;
        padding: 0;

        li {
          @include flex_common;
          background-color: $success-color;
          padding: 5px;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      .review-time {
        font-size: 12px;
        line-height: 26px;
        font-weight: 300;
        font-family: $roboto;
      }
    }

    .divider {
      width: 138px;
      height: 2px;
      background-color: #d4d4d8;
      margin-top: -4px;
    }

    .trusted {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 16px;
      font-weight: 700;
      line-height: 5.26px;
      font-family: $roboto;

      img {
        width: 16px;
        height: 16px;
      }

      &:hover {
        color: unset;
      }
    }
  }
}

.auth-main {
  position: relative;

  .flower {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 34%;
    z-index: -1;
  }

  .leaf {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 26%;
    z-index: -1;
  }

  .auth-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    position: absolute;
    width: 100%;
    left: 0;
    padding: 0 $body-padding;
    top: $body-padding;

    .logo-wrapper {
      height: 60px;
      width: 176px;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .auth-body {
    .auth-body-title {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding-inline: 0;

      @media (max-height: 840px) and (min-height: 600px) {
        padding-bottom: 32px;
      }

      @media (max-height: 600px) {
        padding-bottom: 32px;
      }

      @media (max-width: 991.98px) {
        padding-bottom: 16px;

        &.t-padding {
          padding-top: 0;
        }
      }

      .auth-sub-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
          line-height: calc(20px + (55 - 20) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 700;
          color: $secondary-color-4;
        }

        .page-count {

          span,
          small,
          p {
            font-weight: 700;
            font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
            line-height: 25px;
            margin-bottom: 0;
          }
        }
      }

      p {
        font-size: 16px;
        font-weight: 500;
        color: $dark-gray;
      }

      &.welcome {
        height: 100%;
        align-items: center;
        justify-content: center;

        .hifive {
          width: 200px;
          height: 200px;
        }
      }
    }
  }
}

.verification-form {
  text-align: center;

  .title {
    margin-bottom: calc(38px + (50 - 38) * ((100vw - 320px) / (1920 - 320)));

    @media (max-height: 840px) and (min-height: 600px) {
      margin-top: calc(30px + (50 - 30) * ((100vh - 600px) / (840 - 600)));
    }

    @media (max-height: 600px) {
      margin-top: 30px;
    }

    h3 {
      font-size: calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.4;

      span {
        color: $primary-color-1;
      }
    }
  }

  .otp-code {
    @include flex_common;
    gap: 4px;
    padding: 0;
    margin-top: 50px;

    @media (max-height: 840px) and (min-height: 600px) {
      margin-top: calc(30px + (50 - 30) * ((100vh - 600px) / (840 - 600)));
    }

    @media (max-height: 600px) {
      margin-top: 30px;
    }

    .otp-field {
      border: 1px solid $gray-dark-color;
      border-radius: 12px;
      background-color: $gray-medium-color;
      @include flex_common;
      height: 69px;
      width: 69px;
      font-size: calc(24px + (32 - 24) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 600;
      line-height: 40px;
      text-align: center;

      &:active,
      &.focus,
      &:hover,
      &:focus-visible {
        border: 1px solid $gray-dark-color;
        outline: none;
      }

      &.invalid-field {
        border: 1px solid $error-medium-color;
        background-color: $error-light-color;
        color: $error-dark-color;
      }
    }
  }

  .resend-code {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
    gap: 12px;

    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      margin: 0;
      text-align: left;

      .sec {
        font-weight: 700;
      }
    }

    .resend {
      border: none;
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      white-space: nowrap;
      cursor: pointer;

      &:disabled,
      &.disabled {
        cursor: not-allowed;
        pointer-events: auto;
      }
    }
  }

  .action-btn {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: calc(16px + (20 - 16) * ((100vw - 992px) / (1920 - 992)));

    @media (max-height: 840px) and (min-height: 600px) {
      margin-top: calc(30px + (50 - 30) * ((100vh - 600px) / (840 - 600)));
    }

    @media (max-height: 600px) {
      margin-top: 30px;
    }

    .btn {
      width: 100%;
      border-radius: 25px;
      font-weight: 700;
      padding: 12px 32px;
      font-size: 16px;
      line-height: 20px;
      justify-content: center;
      position: relative;

      .action-btn-image {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &.login-detail {
    text-align: start;

    .otp-code {
      justify-content: flex-start;
      margin-top: 20px;
      gap: 8px;

      .otp-field {

        &:active,
        &.focus,
        &:hover,
        &:focus-visible {
          border: 1px solid $gray-dark-color;
          outline: none;
        }

        &.invalid-field {
          border: 1px solid $error-medium-color;
          background-color: $error-light-color;
          color: $error-dark-color;
        }
      }
    }

    .resend-code {
      margin-inline: 0;
    }

    .action-btn {
      margin-top: 30px;

      .btn {
        margin: 0;
      }
    }
  }
}

.help-div {
  position: absolute;
  bottom: 50px;
  right: 63px;
  background: $white;
  border-radius: 100%;
  padding: 9px;
  z-index: 5;
  cursor: pointer;
  box-shadow: 0px 8px 20px 0px #3916161F;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1440.98px) {
    bottom: calc(32px + (50 - 32) * ((100vw - 575px) / (1441 - 575)));
    right: calc(38px + (63 - 38) * ((100vw - 575px) / (1441 - 575)));
  }

  @media (max-width: 575.98px) {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.profile-setup {
  padding-bottom: 90px;
  padding-top: 50px;

  .profile-setup-field {
    +.profile-setup-field {
      margin-top: 30px;
    }
  }

  .verification-box {
    width: 100%;
    height: 100%;
    @include flex_common;
    text-align: center;

    img {
      width: calc(98px + (120 - 98) * ((100vw - 320px) / (1920 - 320)));
    }

    p {
      margin: calc(14px + (40 - 14) * ((100vw - 320px) / (1920 - 320))) 0 0;
      font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 600;
      line-height: 25.2px;
      color: $secondary-color-4;
    }
  }

  .verified {
    height: auto;
    width: 100%;
    @include flex_common;
    font-size: 16px;
    font-weight: 500;
    color: $gray;
    position: relative;
    padding: 16px 0;
    overflow: hidden;

    @media (max-width: 991.98px) {
      // height: calc(100vh - 383px);
      padding: 0;
    }

    .box {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: auto;

      &::-webkit-scrollbar {
        background-color: #f5f5f5;
        width: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320)));

        @media (max-width: 575.98px) {
          width: 4px;
        }
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px 0px 0px 4px;
        background-color: $gray-dark-color;
      }

      .verification-box {
        width: 100%;
        height: 100%;
        @include flex_common;
        text-align: center;

        img {
          height: calc(96px + (120 - 96) * ((100vw - 320px) / (1920 - 320)));
          width: calc(96px + (120 - 96) * ((100vw - 320px) / (1920 - 320)));
        }

        p {
          height: auto;
          margin: calc(16px + (32 - 16) * ((100vw - 320px) / (1920 - 320))) 0 0;
          font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 600;
          color: $secondary-color-4;
          overflow: visible;
          line-height: 1.5;
          padding-inline: 16px;
        }
      }
    }

    // .verified-box {
    //   @media (max-width: 991.98px) {
    //     position: absolute;
    //     top: 0;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     margin: 20px 0;
    //     overflow: auto;
    //     width: 100%;
    //   }
    // }

    p {
      height: 100%;
      overflow: auto;
      margin: 15px;
      margin-right: 0;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px transparent;
      }

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        border-radius: 4px 0 0 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-dark-color;
        border-radius: 4px 0 0 4px;
      }
    }

    .invalid-note {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .profile-setup-field {
    .text-danger {
      font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
    }

    .form-check {
      padding: 12px;
      gap: 8px;
      align-items: center;

      label {
        font-weight: 500;
        color: $dark-gray;
      }
    }
  }

  .transaction-detail {
    .transaction-list {
      overflow: unset;
    }
  }
}

.auth-footer-main {
  position: relative !important;
  width: 100%;
  margin-inline: auto;
  padding: 0 0 30px;
  margin-top: 20px;

  @media (max-width: 991.98px) {
    position: fixed !important;
    bottom: 0;
    max-width: 720px;
  }

  @media (min-width: 575px) {
    margin-top: 0;
    max-width: 540px;
  }

  @media (max-width: 575.98px) {
    margin-top: 0;
    max-width: unset;
    width: 100%;
    padding-inline: calc(var(--bs-gutter-x)* 0.5);
    left: 0;
  }
}

.agreements-bottom-box {
  position: fixed;
  width: 61%;
  bottom: 0;
  padding-bottom: 30px;

  .auth-footer {
    justify-content: unset;
  }
}

.agreements-body {
  .agreements-setup {
    padding: 0;

    .verified {
      height: calc(100vh - 120px - 260px);

      @media (max-width: 575px) {
        height: calc(100vh - 170px - 260px);
      }
    }
  }

  .agreements-footer {
    width: calc(100% - calc(1 * var(--bs-gutter-x)));
  }
}

.auth-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;

  .btn {
    min-width: 260px;
    gap: 50px;
    justify-content: space-between;

    @media (max-width: 767px) {
      white-space: nowrap;
      min-width: unset;
      width: 100%;
    }
  }
}

.transaction-detail,
.document-detail,
.activity-detail {

  .transaction-list,
  .document-list,
  .activity-list {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 8px !important;
    padding-left: 4px;

    .transaction-item,
    .document-item,
    .activity-item {
      border: 1px solid $gray-dark-color;
      border-radius: 8px;
      gap: 8px;
      padding: calc(14px + (20 - 14) * ((100vw - 992px) / (1920 - 992)));
      display: flex;
      align-items: center !important;
      justify-content: space-between;
      background-color: $white;
      box-shadow: -4px 0px 0px $box-shadow;
      border-left: 0;

      @media (max-width: 575.98px) {
        padding: 21px 24px 25px;
      }

      .transaction-data,
      .document-data,
      .activity-data {
        display: flex;
        align-items: center;
        gap: 24px;

        img {
          width: calc(20px + (24 - 20) * ((100vw - 992px) / (1920 - 992)));
          height: calc(30px + (40 - 30) * ((100vw - 992px) / (1920 - 992)));
        }

        .country-name,
        span {
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
          color: $secondary-color-4;
        }

        span {
          margin-bottom: 4px;
        }

        .amount {
          font-size: 12px;
          font-weight: 500;
          line-height: 16.8px;
          color: $secondary-color-4;
        }

        p {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: $gray;
        }

        .data {
          display: flex;
        }
      }

      h4 {
        font-size: 24px;
        font-weight: 500;
        color: $primary-color-1;
      }

      .transaction-action {
        .more {
          width: 35px;
          height: 35px;
          @include flex_common;
          cursor: pointer;
          border: 1px solid $gray-medium-color;
          border-radius: 100%;

          img {
            width: 14px;
            height: 14px;
          }
        }
      }

      .activity-date {
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        color: $dark-gray;
      }

      &.add-item,
      &.update-item {
        width: 100%;

        .transaction-data {
          width: 100%;
          align-items: flex-start;

          .add-item-data {
            width: 100%;
            margin-top: 50px;
            margin-right: 44px;
          }
        }
      }

      .edit-item-data {
        display: none;
      }

      &.open {
        align-items: flex-start !important;

        .transaction-data {
          align-items: flex-start;

          .data {
            display: none !important;
          }
        }

        .edit-item-data {
          display: flex;
          margin-top: 50px;

          .btn {
            min-width: 200px;
          }
        }
      }
    }

    .document-item {
      .document-data {
        gap: 16px;
        width: 100%;
        justify-content: space-between;

        .document-action {
          display: flex;
          align-items: center;
          gap: 48px;

          h5 {
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
            color: $dark-gray;

            @media (max-width: 575.98px) {
              font-weight: 500;
              font-size: 12px;
              line-height: 16.8px;
            }
          }

          .document-btn {
            background-color: transparent;
            width: 24px;
            height: 24px;
            padding: 5px;
            border: none;
            @include flex_common;

            img {
              width: 100%;
              height: 100%;
            }

            @media (max-width: 767px) {
              position: absolute;
              top: 0;
              right: 0;

              img {
                position: relative;
                inset: unset;
              }
            }
          }

          .signed-badge {
            font-size: 12px;
            font-weight: 500;
            line-height: 16.8px;
            padding: 2px 25px;
          }
        }

        img {
          width: 20px;
          height: 20px;
        }
      }

      &:hover {
        box-shadow: -4px 0px 0px $box-shadow-active-hover,
          0px 1px 10px rgba($dark-gray, 0.2);
      }
    }
  }

  .activity-list {
    margin-bottom: 0 !important;
    height: 420px;

    .activity-item {
      margin-left: 8px;
      padding: 12px 24px 12px 12px;

      .activity-data {
        gap: 16px;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.transaction-detail {
  .transaction-list {
    .transaction-item {
      padding: 19px 20px 14px 27px;

      @media (max-width: 575px) {
        padding: 18px 17px 16px 20px;

        .select-dropdown-2 {
          position: relative;
          inset: unset;
        }
      }

      h4 {
        position: relative;
        inset: unset;
        font-size: 32px;
        font-weight: 600;
        line-height: 40.32px;
        color: $primary-color-1;

        img {
          height: 36px !important;
          width: 36px !important;
        }

      }

      &.open {
        padding: 19px 40px 32px 27px;
        display: block;

        &:has(.transaction-data h4:empty) {
          padding: 19px 40px 32px;

          @media (max-width: 575px) {
            padding: 24px 20px;

            .edit-item-data {
              form {
                .edit-input-box {
                  padding: 0;
                }
              }
            }
          }
        }

        @media (max-width: 575px) {
          padding: 24px 20px;
        }

        .onhover-dropdown {
          position: absolute;
          top: 28px;
          right: 20px;
          width: 30px;
          height: 30px;

          .action-img {
            width: 30px !important;
            height: 30px !important;
          }
        }

        .edit-item-data {
          margin-top: 0;
        }

        .bank-apply-btn {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: flex-end;
          flex-direction: row;
          margin-top: 60px;
          gap: 24px;

          @media (max-width: 1440px) and (min-width: 575px) {
            gap: calc(9px + (24 - 9) * ((100vw - 575px) / (1440 - 575)));
            flex-wrap: nowrap;
          }

          @media (max-width: 575.98px) {
            flex-direction: row-reverse;
            gap: 9px;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 40px;
            align-items: unset;

            button {
              width: 100%;
            }
          }

          button {
            min-width: unset !important;
            padding: 13px calc(18px + (52 - 18) * ((100vw - 320px) / (1920 - 320))) !important;
            font-size: 14px;
            font-weight: 700;
            line-height: 17.64px;
            max-width: 100%;
            flex-shrink: 0;
            justify-content: center;

            @media (max-width: 575.98px) {
              width: calc(50% - 5px);
              flex: 0 0 auto;
            }

            &.delete-btn {
              margin-right: 23px;
              padding: 0 !important;
              border: none;

              &:active {
                border: none;
              }

              @media (max-width: 1440px) and (min-width: 575px) {
                margin-right: calc(0px + (23 - 0) * ((100vw - 575px) / (1440 - 575)));
              }

              @media (max-width: 575.98px) {
                margin: 0;
                text-align: center;
                display: block;
                width: 100%;
                padding: 13px 52px !important;
                margin-bottom: 11px;
              }
            }
          }
        }

        .transaction-data {
          .edit-input-box {
            @media (max-width: 575.98px) {
              padding-left: 42px;
            }
          }

          img {
            position: absolute;
            top: 19px;
            left: 27px;
            width: 21px;
            height: 40px;
            font-size: 32px;
            font-weight: 500;
            color: $primary-color-1;
          }

          h4 {
            position: relative;
            inset: unset;
            font-size: 32px;
            font-weight: 600;
            line-height: 40.32px;
            color: $primary-color-1;

            @media (max-width: 575px) {
              position: absolute;
              top: 24px;
              left: 20px;
            }

            &:empty {
              display: none;
            }
          }

          .search-img,
          .search-img-hover {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 14px;
            width: 21px;
            height: 40px;
          }

          .edit-icon {
            left: unset;
            right: 8px;
            top: 10px;
            width: 24px;
            height: 24px;
            padding: 3px;

            @media (max-width: 575.98px) {
              top: -2px;
            }
          }

          .bank-info-box {
            .address-indicate-note {
              z-index: 1;

              @media (max-width: 991.98px) {
                margin-left: auto;
                margin-right: 14px;
              }

              .indicate {
                z-index: 0;

                .more-info {
                  &:hover {
                    .info {
                      @media (max-width: 991.98px) {
                        display: block;
                      }
                    }
                  }

                  .tip {
                    width: 18px;
                    height: 18px;
                  }

                  .info {
                    max-width: 190px;
                    border-radius: 8px;
                    align-items: unset;

                    @media (max-width: 991.98px) {
                      left: unset;
                      right: 0;
                      max-width: calc(176px + (215 - 176) * ((100vw - 320px) / (992 - 320)));
                    }

                    .info-icon {
                      width: 18px;
                      margin-top: 3px;
                      height: 18px;

                      @media (max-width: 991.98px) {
                        margin-bottom: 6px;
                        margin-left: auto;
                      }
                    }

                    p {
                      padding-inline: 10px;
                      width: calc(100% - 18px);

                      @media (max-width: 991.98px) {
                        width: auto;
                      }
                    }
                  }
                }
              }

              .indicate {
                position: relative;
                inset: unset;
                margin: 0;

                .more-info {
                  .tip {
                    width: 16px;
                    height: 16px;
                    inset: unset;
                    position: relative;
                  }
                }

                .info-icon {
                  img {
                    position: static;
                    inset: unset;
                  }
                }
              }
            }

            .react-datepicker-popper {
              z-index: 2;
              inset: 35px 0 0 0 !important;
              line-height: 0;
            }
          }
        }

        .select-dropdown-2 {
          display: none;
        }

        .data-box {
          display: none !important;
        }
      }

      .transaction-data {
        .edit-item-data {
          .edit-input-box {
            .form-control {
              padding: 10px 32px 12px 0;
              border: none !important;
              border-bottom: 1px solid $gray !important;
              border-radius: 0;
              background-color: transparent;
              font-size: 14px;
              font-weight: 400;
              line-height: 16.94px;
              color: $black;
              height: unset !important;

              @media (max-width: 575px) {
                padding-top: 0;
              }
            }
          }
        }

        .country-name,
        span {
          margin-bottom: 0;

          &:empty {
            display: none;
          }
        }
      }
    }
  }
}

.agreement {
  overflow: hidden;
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: $gray;

  .agreement-data {
    overflow: hidden;
    width: 100%;
    text-align: center;

    .offcanvas-loader {
      z-index: 0;
    }

    p {
      overflow: auto;
      padding: calc(13px + (20 - 13) * ((100vw - 320px) / (1920 - 320)));
      width: 100%;
      text-align: center;
      height: 100%;

      &::-webkit-scrollbar {
        width: 8px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px 0px 0px 4px;
        background-color: $gray-dark-color;
      }
    }

    .offcanvas-loader {
      width: auto;
      height: auto;
      inset: 0;
      margin: auto;

      p {
        width: auto;
        height: auto;
      }
    }
  }

  .agreement-footer {
    @include flex_common;
    gap: 12px;
    position: absolute;
    width: 100%;
    left: 0;
    padding: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320))) calc(12px + (40 - 12) * ((100vw - 320px) / (1920 - 320)));
    bottom: 0;

    .btn {
      gap: 20px;
      padding: 12px 40px;

      &.btn-gray {
        color: $dark-gray !important;
        position: relative;
        padding-left: 48px;
      }

      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 9px;
        width: 24px;
        height: 24px;
        padding: 4px;
      }
    }
  }
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-left: auto;
  width: fit-content;
  padding: 0;

  @media (max-width: 370px) {
    gap: 18px;
  }

  li {
    position: relative;
  }

  .nav-icon {
    width: 24px;
    height: 24px;
  }

  .client-box {
    h5 {
      line-height: 23.38px;
    }
  }

  .notification-box {
    cursor: pointer;

    .notify-badge {
      position: absolute;
      top: 5px;
      right: 2px;
      background-color: $primary-color-1;
      width: 7.18px;
      height: 7.18px;
      border-radius: 100%;
      border: 1px solid $gray-light-color;
    }

    h6 {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: $btn-hover;
      margin: 12px 0 12px 20px;

      @media (max-width: 575.98px) {
        margin-top: 0;
      }

      span {
        color: $primary-color-1;
        font-size: 18px;
        font-weight: 700;
      }
    }

    .notification-box-menu {
      min-width: 335px;
      top: 46px;

      ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-height: 400px;
        min-height: 282px;
        overflow: auto;
        padding-left: 0;

        &:empty {
          display: none;
        }

        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px 0px 0px 4px;
          background-color: $gray-dark-color;
        }

        li {
          background-color: $white;
          border-radius: 6px;
          padding: calc(11px + (20 - 11) * ((100vw - 320px) / (1920 - 320)));
          color: $dark-gray;
          border: 1px solid $gray-medium-color;

          @media (max-width: 575.98px) {
            padding: 20px;
          }

          p {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            margin: 0;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;

            strong {
              font-weight: 700;
              margin-right: 2px;
            }
          }

          .view-more {
            display: flex;
            align-items: baseline;
            justify-content: end;
            gap: 9px;
            color: $primary-color-1;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

            svg {
              height: 9px;
              width: 12px;
              transform: translateX(0px);
              transition: all 0.2s ease;
              fill: $primary-color-1;
            }

            &:hover {
              svg {
                transform: translateX(5px);
                transition: all 0.2s ease;
              }
            }
          }
        }
      }

      .onhover-dropdown-menu {
        li {

          &:hover,
          &.active {
            color: $dark-gray;
          }
        }
      }
    }
  }

  .profile-box {
    cursor: pointer;

    @media (max-width: 575.98px) {
      position: relative;

      &:has(.profile-box-menu) {
        &::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          background-color: $white;
          bottom: -25px;
          left: 50%;
          border: 1px solid $gray-dark-color;
          transform: translateX(-50%) rotate(45deg);
          border-right: none;
          border-bottom: none;
          z-index: 1;
        }
      }

      &::before {
        content: none;
      }

      .profile-box-menu {
        top: 50px;
        padding: 30px 18px 23px;
        min-width: 260px;
        border: 1px solid $gray-dark-color;

        &::before {
          content: none;
        }
      }
    }

    @media (max-width: 420px) {
      .profile-box-menu {
        min-width: calc(280px + (380 - 280) * ((100vw - 320px) / (420 - 320)));
      }
    }

    .profile-image {
      padding: 4px;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      outline: 4px solid $white;
      box-shadow: $box-shadow-2;
      background-color: #f7f5f7;
    }

    &:hover {
      .profile-image {
        border-color: $primary-color-1;
      }
    }

    .profile-main-box {
      display: flex;
      align-items: center;
      gap: 16px;
      background-color: $white;
      border-radius: 100px;

      .profile-user-name {
        font-size: 13px;
        font-weight: 600;
        line-height: 18.2px;
        white-space: nowrap;
        padding-right: 13px;
        max-width: 103px;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;

        @media (max-width: 575.98px) {
          display: none;
        }
      }

      .nav-profile-box,
      .initial-letter {
        width: 44px;
        height: 44px;
        border-radius: 100%;
        object-fit: cover;
        border: 4px solid $white;
        box-shadow: 0px 2px 8px 0px #391e1e1f;
        outline: none;
      }
    }
  }

  .datepicker-box {
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .notification-box {
    @media (max-width: 575.98px) {
      position: relative;

      &:has(.notification-box-menu) {
        &::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          background-color: $white;
          bottom: -25px;
          left: 50%;
          border: 1px solid $gray-dark-color;
          transform: translateX(-50%) rotate(45deg);
          border-right: none;
          border-bottom: none;
          z-index: 1;
        }
      }

      &::before {
        content: none;
      }

      .notification-box-menu {
        top: 42px;
        padding: 41px 12px 14px;
        min-width: 330px;
        border: 1px solid $gray-dark-color;

        &::before {
          content: none;
        }
      }
    }

    @media (max-width: 420px) {
      .notification-box-menu {
        min-width: calc(287px + (380 - 287) * ((100vw - 320px) / (420 - 320)));
        right: calc(-54px + (-60 - -54) * ((100vw - 320px) / (420 - 320))) !important;
      }
    }
  }
}

.juuli-account {
  a {
    color: $dark-gray;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    display: flex;
    align-items: center;
    gap: 30px;
  }
}

.upload-profile-box,
.profile-image-box {
  &.business-header {

    .nav-profile-box,
    .initial-letter {
      border-color: $secondary-color-2 !important;
    }
  }
}

.datepicker-box,
.recurring-invoice,
.project-box,
.final-recurring-invoice {
  display: flex;
  align-items: center;
  gap: 24px;

  &.form-control {
    padding: 9px 14.1px 7px 6px;
    opacity: 1;
    background-color: $gray-light-color;
    border: 1px solid $gray-dark-color;

    .left-title {
      gap: 14px;

      span {
        font-size: 14px;
        font-weight: 500;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
      }
    }

    .right-title {
      gap: 13px;
      border-left: 1px solid $white;
      padding-left: 16px;
      margin-left: 15px;
      font-weight: 700;
      font-size: 14px;

      @media (max-width: 1399px) {
        width: 112px;
      }

      svg {
        width: 11.23px;
        height: 5.67px;
        fill: $info-dark-color;
        transform: rotate(270deg);
      }
    }
  }

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    background-color: $gray-light-color;
    border: 1px solid $gray-dark-color;
  }
}

.recurring-invoice {
  &.form-control {
    padding: 6px;
    padding-right: 12px;

    @media (max-width: 1399px) {
      padding-right: 9px;
    }

    &:focus {
      border: none !important;
    }
  }
}

.project-box {
  &.form-control {
    padding: 10px 27px 10px 11.44px;
    background-color: $gray-medium-color;
    border: unset;

    .d-flex {
      gap: 13px;
      border: none;

      .edit-icon {
        height: 12.86px;
      }

      h6 {
        font-size: 14px;
        font-weight: 700;
      }
    }

    &:hover {
      background-color: $gray-medium-color;
      border: unset;
    }
  }
}

.recurring-invoice,
.final-recurring-invoice {
  justify-content: space-between;
  gap: 8px;
  background-color: $info-light-color !important;
  border: 1px solid transparent !important;
  color: $info-dark-color !important;

  .img-box {
    border-radius: 100%;
    background-color: $white;
    width: 24px;
    min-width: 24px;
    height: 24px;
    @include flex_common;
  }

  img {
    width: 15px !important;
    min-width: 15px !important;
    height: 15px !important;
  }

  a {
    border-left: 1px solid $white;
    padding-left: 16px;
    font-weight: 700;
    color: $info-dark-color;
    font-size: 14px;
  }
}

.final-recurring-invoice {
  background-color: $primary-color-1 !important;
  color: $white !important;

  a {
    color: $white;
  }
}

.custom-width-card {
  .login-main {
    align-items: center;
  }
}

.reset-form-field {
  position: relative;

  .btn {
    position: absolute;
    right: 7px;
    top: 7px;
    padding-block: 6px;
  }
}

.verify-invoice {
  .auth-sub-title {
    .back-btn {
      border-radius: 100%;
      width: 40px;
      height: 40px;
      @include flex_common;
      background-color: $gray-medium-color;
      border: 1px solid transparent;
      cursor: pointer;

      &:hover {
        border-color: $gray-dark-color;
        background-color: transparent;
      }

      &:focus {
        border-color: transparent;
        background-color: $neutral-color-2;
      }

      img {
        transform: rotate(180deg);
      }
    }
  }

  .verify-invoice-content {
    position: relative;

    .verify-invoice-form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: calc(10px + (50 - 10) * ((100vw - 992px) / (1920 - 992)));
      margin-inline: 30px;

      .invoice-data {
        padding: 0 30px 30px;
        border-bottom: 1px solid $gray-dark-color;
        margin-bottom: 30px;
      }

      .form-check {
        padding-inline: 30px;

        label {
          font-weight: 500;
          line-height: 19px;
        }
      }

      .select-reason {
        padding-inline: 30px;
      }

      .invoice-approved {
        width: 490px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin: 0 auto;

        img {
          width: 60px;
          height: 60px;
          margin-inline: auto;
        }

        h2 {
          text-align: center;
        }

        p {
          margin: 0;
          font-size: 14px;
          font-weight: 500;
          line-height: 19px;
        }
      }
    }

    .rejection-form {
      display: flex;
      flex-direction: column;

      .auth-footer {
        margin-top: 60px;
      }
    }
  }

  .invoice-approved-footer {
    width: 490px;
    margin: 0 auto;
    margin-top: 50px;

    .btn {
      justify-content: space-between !important;
    }
  }

  .auth-footer {
    gap: calc(12px + (20 - 12) * ((100vw - 992px) / (1920 - 992)));

    .btn {
      width: 100%;
      white-space: nowrap;
      min-width: unset;
      justify-content: center;
    }
  }
}

.verify-invoice {
  .verify-invoice-content {
    .row {
      >div {
        .verify-footer {
          padding-bottom: 0 !important;
        }
      }
    }
  }
}

.auth-business {
  padding: 180px 0 0 155px;

  @media (max-width: 1440.98px) {
    padding: calc(138px + (180 - 138) * ((100vw - 757px) / (1441 - 757))) 0 0 calc(48px + (155 - 48) * ((100vw - 757px) / (1441 - 757)));
  }

  @media (max-width: 991.98px) {
    padding: 20px;
    overflow: auto;
    height: 100vh;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px transparent;
      background-color: $white;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 5px;
      border-radius: 4px 0 0 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-dark-color;
      border-radius: 4px 0 0 4px;
    }
  }

  @media (max-width: 575.98px) and (max-height: 850px) {
    padding-bottom: 40px;
  }

  .auth-business-form {
    height: calc(100vh - 152px);
    overflow: hidden auto;

    @media (max-width: 991.98px) {
      height: auto;
      padding-right: 0;
      overflow: unset;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  h1 {
    font-size: 44px;
    font-weight: 700;
    line-height: 55.44px;
    margin-bottom: 46px;
    padding-right: 155px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1440.98px) {
      font-size: calc(26px + (44 - 26) * ((100vw - 757px) / (1441 - 757)));
      padding-right: calc(48px + (155 - 48) * ((100vw - 757px) / (1441 - 757)));
    }

    @media (max-width: 991.98px) {
      padding-right: 0;
      margin-top: 62px;
      font-size: 24px;
      line-height: 33.6px;
      align-items: flex-start;
      margin-bottom: 36px;
    }

    span {
      font-size: 20px;
      font-weight: 700;
      line-height: 25.2px;
      color: #000;

      @media (max-width: 1440.98px) {
        font-size: calc(18px + (20 - 18) * ((100vw - 757px) / (1441 - 757)));
      }


      @media (max-width: 575.98px) {
        font-size: 18px;
        line-height: 22.68px;
      }
    }
  }

  .auth-image {
    position: fixed;
    top: 50px;
    left: 40px;
    width: 124px;
    z-index: 1;

    @media (max-width: 991.98px) {
      position: static;
      inset: unset;
      width: 90px;
    }
  }

  .back-img {
    position: fixed;
    bottom: 0;
    right: 0;
    opacity: 0.2;
    z-index: -1;
    width: 27.8%;

    @media (max-width: 991.98px) {
      width: 225.18px;
      height: 300px;
    }
  }

  .main-form {
    .julli-height {
      padding-right: 385px;

      @media (max-width: 1440.98px) {
        padding-right: calc(48px + (385 - 48) * ((100vw - 757px) / (1441 - 757)));
      }

      @media (max-width: 991.98px) {
        height: auto;
        padding-right: 0;
      }

      &::-webkit-scrollbar-track {
        background-color: $gray-light-color;
      }
    }

    .form-check {

      .form-check-input,
      .form-radio-input {
        padding: 0;
        height: 14px !important;
        border: none !important;
      }
    }
  }

  .footer-buttons {
    justify-content: space-between;
    padding-block: 50px;
    padding-right: 155px;

    @media (max-width: 1440.98px) {
      padding-right: calc(48px + (155 - 48) * ((100vw - 757px) / (1441 - 757)));
    }

    @media (max-width: 991.98px) {
      padding-right: 0;
      padding-block: 40px;
    }

    @media (max-width: 575.98px) {
      flex-direction: column-reverse;

      .btn {
        img {
          padding: 6px;
          right: 5px;
        }
      }
    }

    .btn {
      width: auto;
      min-width: 260px;

      @media (max-width: 767.98px) {
        width: auto;
        min-width: 260px;
      }

      @media (max-width: 575.98px) {
        width: 100%;
        min-width: unset;
      }
    }
  }
}

.agreement-payment-box {
  border: 1px solid $gray-dark-color;
  height: calc(100vh - 42px - 118px);
  border-radius: 20px;
  overflow: hidden;
  padding: 7px;

  @media (max-width: 1440.98px) {
    border-radius: calc(6px + (20 - 6) * ((100vw - 575px) / (1441 - 575)));
  }

  @media (max-width: 575.98px) {
    margin-inline: 20px;
    height: calc(100vh - 35px - 72px);
    border-radius: 6px;
  }

  .custom-scroll {
    height: 100%;
    overflow: auto;
  }
}

/**=====================
  Auth CSS end
==========================**/