/**=====================
     62. Responsive CSS Start
==========================**/

@media (min-width: 2388px) {
  .order-dashboard {
    .main-row {
      >.col-xl-4 {
        >.row {
          .min-h-content {
            ~.col-12 {
              height: calc(100% - 371px) !important;
            }
          }
        }
      }

      >.col-xl-8 {
        >.row {
          .min-h-content {
            ~.col-12 {
              height: calc(100% - 305px) !important;
            }
          }
        }
      }
    }
  }

  .project-container {
    .horizontal-tab-content {
      .tab-pane {
        &.active {
          min-height: calc(100vh - 190px);
          height: 100%;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 576px) {
  .h-sm-100 {
    height: 100% !important;
  }

  .all-project-table,
  .project-all-invoice-table,
  .project-detail-document-table,
  .project-detail-invoice-table,
  .project-detail-transaction-table {
    .table {
      tbody {
        tr {
          td {
            .responsive-box {
              .badge {
                width: fit-content;

                ~span {
                  padding-left: 24px;
                  font-size: 14px;
                  margin-top: 6px;
                  font-weight: 500;
                  color: $title;
                }
              }

              >h6 {
                margin-top: 0;
              }

              span,
              h6 {
                width: 100%;
                overflow: hidden;
                @include text-line;
                word-break: break-all;

                &.badge {
                  width: fit-content;
                }
              }

              .status {
                padding: 3px 15px;
                font-weight: 500;
                font-size: 12px;
                line-height: 16.8px;
                margin: 0;
              }

              .status-badge {
                padding: 1.5px 16px;
                font-size: 12px;
                font-weight: 500;
                line-height: 16.8px;
                width: max-content;
                border-radius: 100px;

                &.waiting-badge {
                  color: $warning-dark-color;
                  background-color: $warning-light-color;
                }

                &.accepted-badge {
                  color: $success-dark-color;
                  background-color: $success-light-color;
                }

                &.invited-badge {
                  color: $warning-dark-color;
                  background-color: $warning-light-color;
                }
              }
            }
          }
        }
      }
    }
  }

  .invoice-work-table {
    tbody {
      tr {
        &.editing {
          td {
            border-top-color: $success-medium-color;
            border-bottom-color: $success-medium-color;

            &:first-child {
              border-left-color: $success-medium-color;
            }

            &:last-child {
              border-right-color: $success-medium-color;
            }
          }
        }
      }
    }
  }

  .header-logo-wrapper {
    .toggle-sidebar {
      .close-icon {
        display: none;
      }
    }
  }

  .responsive-span {
    display: none;
  }

  .project-detail-invoice-table {
    .table {
      tbody {
        tr {
          td {
            .responsive-box {
              .badge {
                width: auto !important;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1440.98px) {
  .offcanvas {

    &.profile-setting,
    &.add-client,
    &.finance-modal {
      padding: calc(36px + (60 - 36) * ((100vw - 320px) / (1441 - 320))) 0 calc(46px + (100 - 46) * ((100vw - 320px) / (1441 - 320))) calc(36px + (115 - 36) * ((100vw - 320px) / (1441 - 320)));
    }

    &.setting-canvas {
      padding: 62px 114px;

      @media (max-width: 767px) {
        padding: 24px calc(16px + 20 * (100vw - 320px) / 448) !important;
      }

      .setting-main {
        .setting-footer-main {
          left: 115px;

          @media (max-width: 767px) {
            width: 100%;
            left: 0;
          }

          @media (max-width: 575px) {
            bottom: 0;
          }
        }
      }
    }

    &.add-client {
      padding-left: 63px;
      padding-right: 104px;
    }

    &.profile-setting {

      .offcanvas-header,
      .profile-box {
        margin-right: calc(36px + (155 - 36) * ((100vw - 320px) / (1441 - 320)));

        .btn-close {
          width: calc(32px + (44 - 32) * ((100vw - 320px) / (1441 - 320)));
          height: calc(32px + (44 - 32) * ((100vw - 320px) / (1441 - 320)));
        }

        .offcanvas-title {
          font-size: calc(27px + (32 - 27) * ((100vw - 320px) / (1441 - 320)));
        }
      }

      .offcanvas-body {
        padding-right: calc(36px + (155 - 36) * ((100vw - 320px) / (1441 - 320)));
        padding-bottom: 0;
      }
    }
  }
}

@media (max-width: 1439px) {
  .verification-form {
    .otp-code {
      .otp-field {
        width: 66px;
        height: 66px;
      }
    }
  }

  .custom-width-card {

    .custom-w,
    .social-media {
      width: 440px;
    }
  }

  .custom-form-w {
    .otp-code {
      .otp-field {
        height: 69px;
        width: 69px;
      }
    }
  }
}

@media (max-width: 1399px) {
  .payment-setting-footer {
    .btn {
      min-width: 135px;
    }
  }

  .invoice-right-part {
    width: 520px;
  }

  .page-wrapper {
    .page-body-wrapper {
      .page-body {

        .finance-main,
        .customer-main {
          padding: 25px;
          padding-bottom: 40px;
        }
      }
    }
  }

  .mx-6 {
    margin-inline: 70px;
  }

  .profile-card {
    .horizontal-tab-content {
      padding-inline: 30px;
    }
  }

  .horizontal-main-tab {
    .horizontal-tab-content {
      .tab-pane {
        padding: 25px;
      }
    }
  }

  .tab-right-filter {
    gap: 12px;

    .search-input {
      min-width: 310px;
    }

    .btn {
      width: 42px;
      min-width: 42px;
      height: 42px;
      @include flex_common;
      padding: 0;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .filter-dropdown-box {
      .select-btn {
        width: 100%;
        min-width: unset;
        padding: 10px 20px;
        justify-content: unset;
      }
    }
  }

  .select-dropdown-box {
    .select-btn {
      width: 100%;
      min-width: unset;
      height: unset;
      justify-content: unset;
      padding: 10px 20px;
    }
  }
}

@media (max-width: 1199px) {
  .radius-bottom-0 {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }

  .summary-footer {
    .footer-buttons {
      .btn {
        width: 100%;
        min-width: unset;
      }
    }
  }

  .payment-main {
    .payment-right {
      padding: 30px;
      padding-bottom: 0;

      h5 {
        margin-bottom: 33px;
      }

      .invoice-action {
        margin-top: 35px;
      }

      .invoice-data {
        border: 1px solid $gray-dark-color;
        border-radius: 12px;
        padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }

  .horizontal-main-tab-filled {
    .horizontal-tab-filled-content {
      .tab-pane {
        &.active {
          min-height: calc(100vh - 468px);
        }
      }
    }
  }

  .invoice-right-part,
  .invoice-left-part {
    width: 100%;
  }

  .invoice-main-content {
    .select-menu {
      &.form-control {
        &.with-link {
          .input-btn {
            width: auto;
            padding: 10px;
          }

          .select-btn {
            background-position: center right 50px;
          }
        }
      }
    }
  }

  .select-menu {
    &.form-control {
      &.with-link {
        .input-btn {
          width: auto;
          padding: 10px;
        }

        .select-btn {
          background-position: center right 50px;
        }
      }
    }
  }

  .message-main {
    .message-left {
      height: auto !important;
    }

    .message-right {
      border-left: unset;
      border-top: 1px solid $gray-medium-color;
      padding: 30px 0 0;
      height: auto !important;

      .message-right-part {
        ~.col-12 {
          height: auto;
        }
      }
    }

    .message-box {
      border-radius: 0;
      height: auto !important;
    }
  }

  .invoice-main {
    .summary-note {
      padding: 30px;

      .edit-invoice {
        width: 30px;
        height: 30px;

        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  .tab-right-filter {
    gap: 8px;

    .search-input {
      min-width: 250px;
    }
  }

  .log-in-section {
    .auth-left {
      margin-inline: auto;
    }

    .login-main {
      .social-media {
        li {
          position: relative;

          a {
            padding: 7px 13px 7px 7px;
            justify-content: flex-start;
            width: 100%;
            position: relative;
          }

          &.social-box {
            width: 100%;
            grid-column: 1 / 3;
          }
        }
      }

      .social-box {
        background-color: $white;
        border: 1px solid $gray-medium-color;
        border-radius: 30px;
        width: 100%;
        position: relative;

        .social-icon {
          padding: 13px;
          width: 100%;
          position: relative;
          @include flex_common;
          gap: 16px;
          color: $secondary-color-4;
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;

          img {
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  .juuli-account {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }

  .password-indicate-note {
    .indicate {
      .more-info {
        .info {
          left: unset;
          right: -6px;

          .info-icon {
            justify-content: end;
            margin-left: auto;
          }
        }
      }
    }
  }

  .recent-invoice-card {
    .no-data-found {
      position: relative;
      inset: unset;
      padding-block: calc(80px + (120 - 80) * ((100vw - 320px) / (1200 - 320))) calc(100px + (148 - 100) * ((100vw - 320px) / (1200 - 320)));
    }
  }

  .transaction-detail {
    .transaction-list {
      .transaction-item {
        .transaction-data {
          gap: 12px;

          .transaction-data {}
        }
      }
    }
  }

  .select-menu {
    &.form-control {
      &.with-link {
        .select-btn {
          background-position: center right 70px !important;

          .select-data {
            &:hover {
              margin-right: 42px;
              word-break: break-all;
            }
          }

          &.selected {
            .select-data {
              margin-right: 42px;
              word-break: break-all;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .verify-invoice {
    .verify-invoice-content {
      position: static;

      .row {
        >div {
          position: static;

          .verify-footer {
            position: absolute;
            bottom: 30px;
            left: 50%;
            margin-inline: auto;
            width: 100%;
            max-width: 720px;
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  .right-box-shadow {
    display: none;
  }

  .custom-width-card {
    width: 488px;
  }

  .verification-form {
    .otp-code {
      gap: 4px;

      .otp-field {
        border-radius: 6px;
      }
    }
  }

  .search-input-form {
    .search-input {

      .search-img,
      .search-img-hover {
        display: none !important;
      }

      &.open {
        display: block !important;
        position: absolute;
        top: 50px;
        left: -155px;
        z-index: 5;

        .form-control {
          padding-left: 24px;
        }

        .search-img {
          display: none;
        }
      }

      .close-img {
        height: 25px;
        top: 50%;
        transform: translateY(-50%);
        width: 25px;
      }
    }
  }

  .onhover-dropdown-menu {
    display: none;

    &.open {
      display: block;
      opacity: 1;
      visibility: visible;
      transition: all 0.2s ease;
      transform: translateY(0);
    }
  }

  .page-wrapper {
    .page-body-wrapper {
      .sidebar-wrapper {
        &.close_icon {
          width: auto;
        }
      }
    }

    .page-header {
      &.close_icon {
        margin-left: 116px;
        width: calc(100% - 116px);

        &.w-user-header {
          margin-left: 116px;
          width: calc(100% - 116px);
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .project-container {
    .horizontal-tab-content {
      border-radius: 0 12px 0 0;
    }
  }

  .page-wrapper {
    .page-body-wrapper {
      .header-logo-wrapper {
        .toggle-sidebar {
          cursor: pointer;
          border-radius: 100%;
          @include flex_common;

          img {
            height: 16px;
            width: 16px;
          }

          .open-icon {
            height: 32px;
            width: 32px;
            padding: 3px 3px 3px 0;
          }

          .close-icon {
            display: flex;
            height: 32px;
            width: 32px;
            background-color: $white;
            padding: 8px;
            border-radius: 100%;
          }
        }
      }

      .page-body {

        .invoice-main,
        .finance-main,
        .customer-main {
          height: calc(100vh - 68px);
          padding: calc(20px + (25 - 20) * ((100vw - 320px) / (768 - 320)));
          padding-bottom: 40px;
        }

        .invoice-main {
          padding: 0;
          min-height: calc(100vh - 68px - 102px);
          height: auto;
          border-radius: 12px 12px 0 0;
          background-color: transparent;
        }
      }
    }
  }

  .invoice-main {
    .footer-buttons-main {
      padding: 0 !important;
      margin-bottom: 16px;
      padding-inline: 20px !important;
      width: calc(100% - 24px);
    }

    .invoice-tab-content {
      .tab-pane {
        .invoice-main-content {
          padding-inline: 24px;

          .footer-buttons-main {
            margin: 0;
            width: auto;
          }
        }
      }
    }
  }

  .bg-img {
    background-image: url("./../../images/svg/body-bg.svg");
  }

  .footer-buttons-main {
    padding-top: 27px !important;
    padding-right: 24px;
  }

  .summary-review {
    ~div {
      .footer-buttons-main {
        padding-top: 24px !important;
      }
    }
  }

  .verify-invoice {
    .verify-invoice-content {
      .row {
        >div {
          .verify-footer {
            max-width: 540px;
          }
        }
      }
    }
  }

  .horizontal-main-tab-filled {
    .horizontal-tab-filled-content {
      .tab-pane {
        &.active {
          min-height: calc(100vh - 408px);
        }
      }
    }
  }

  .verify-invoice {
    .t-padding {
      padding-top: 30px;
    }
  }

  .money-main {
    .money-content {
      flex-direction: column;
    }
  }

  .juuli-account {
    flex-direction: row;
    gap: 4px;
    align-items: center;
  }

  .delete-confirm-modal,
  .invited-modal {
    width: 100% !important;
  }

  .invited-modal {
    .mailbox {
      width: 60px;
      height: 60px;
    }
  }

  .footer-buttons,
  .profile-setting-footer,
  .setting-footer {
    .btn {
      justify-content: center !important;
      width: 100%;
      min-width: unset;
      padding: 12px;
      font-size: 14px;

      img {
        width: 32px;
        height: 32px;
        padding: 6px 8px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
      }
    }
  }

  .not-found-page {
    img {
      width: 50%;
    }
  }

  .agreement {
    .agreement-footer {
      flex-wrap: wrap;

      .btn {
        width: 100%;
        padding: 12px;
        justify-content: center;
      }
    }
  }

  .page-wrapper {
    .page-body-wrapper {
      .page-body {
        padding-inline: 20px;
        margin-top: 68px;
      }

      .w-user-name {
        margin-top: 97px;
      }

      .sidebar-wrapper {
        height: 100vh;
        background-color: $gray-light-color;
        z-index: 8;
        transition: none;

        &.close_icon {
          transform: translateX(-285px);
          width: 250px;
          height: 100vh;

          .logo-wrapper {
            display: flex;
          }

          .logo-icon-wrapper {
            display: none;
          }

          .sidebar-main {
            .sidebar-links {
              &.custom-scrollbar {
                min-height: auto;
              }

              .sidebar-link {
                width: 100%;

                span {
                  display: block;
                }
              }
            }
          }

          ~.page-body {
            &.w-user-name {
              width: 100%;
            }
          }
        }

        .logo-wrapper {
          padding: 20px;
          flex-direction: row-reverse;
          justify-content: flex-end;
          gap: 11px;

          a {
            img {
              width: 94px;
            }
          }
        }

        .sidebar-main {
          height: calc(100vh - 78px);

          .sidebar-links {
            padding: 0 20px;
          }

          .sidebar-content {
            height: 100% !important;
            transition: none;

            &:has(.offer-card .collapse:not(.show)) {
              height: calc(100vh - 83px);

              >.sidebar-links {
                height: calc(100% - 173px - 50px);
              }
            }

            >div {
              .sidebar-links {
                position: fixed;
                bottom: 0;
                width: 100%;
              }
            }
          }
        }

        .toggle-sidebar {
          position: relative;
          inset: unset;
          width: 32px;
          height: 32px;
          border-radius: 100%;
          background-color: $white;
          border: none;
          padding: 8px;

          &::before {
            content: unset;
          }

          img {
            max-width: 100%;
            height: auto;
          }
        }
      }

      .page-body {
        margin-left: 0 !important;
        margin-top: 80px;

        &:has(.invoice-main) {
          margin-top: 65px;
          padding-inline: 0 !important;
        }
      }
    }

    .page-header {
      height: auto;
      padding: 20px;
      margin-left: 0 !important;
      width: 100% !important;
      align-items: center;

      .page-title {
        padding-inline: 0;
      }

      h2 {
        font-size: 24px;
        line-height: 33px;
        margin: 0;
      }

      p {
        font-size: 14px;
        line-height: 19px;
        margin-top: 6px;
      }

      .header-logo-wrapper {
        display: flex;
        padding: 0;
      }

      .nav-right {
        padding: 0;
      }

      &:has(~ .page-body-wrapper .invoice-main) {
        background-color: $white;

        .page-title {
          .mobile-title {
            background-color: $white;
          }
        }
      }
    }

    #page-headers {
      &:has(~ .page-body-wrapper .invoice-main) {
        background-color: $white;

        .page-title {
          .mobile-title {
            background-color: $white;
          }
        }
      }
    }
  }

  .document-detail {
    .document-list {
      .document-item {
        .document-data {
          flex-direction: column;
          align-items: flex-start;
          position: relative;

          .download {
            position: absolute;
            top: 0;
            right: 0;
          }

          .document-action {
            gap: 4px;
            width: 100%;
            justify-content: space-between;
          }
        }
      }
    }
  }

  .pagination,
  .pagination-info {
    position: static;
  }

  .offcanvas {
    &.add-client {
      padding-inline: 20px;

      .offcanvas-header {
        padding: 0;

        .btn-close {
          position: absolute;
          top: 29px;
          background-color: $gray-medium-color;
          border: none;
        }
      }

      form {
        height: auto;
      }

      .offcanvas-body {
        h2 {
          line-height: 25px;
          padding-left: 44px;
        }

        .footer {
          bottom: 20px;
          width: calc(100% - 40px);
        }
      }
    }

    &.profile-setting {
      padding: calc(16px + (36 - 16) * ((100vw - 320px) / (768 - 320))) 0 calc(16px + (36 - 16) * ((100vw - 320px) / (768 - 320))) calc(16px + (36 - 16) * ((100vw - 320px) / (768 - 320)));
      padding-bottom: 78px;

      .profile-box {
        display: none;
      }

      .offcanvas-header {
        justify-content: flex-end;
        align-items: center;
        gap: 12px;
        margin-right: calc(16px + (36 - 16) * ((100vw - 320px) / (768 - 320)));
        flex-direction: row-reverse;

        .offcanvas-title {
          width: auto;
          font-size: 20px;
          line-height: 25.2px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .btn-close {
          position: relative;
          inset: unset;
          width: 32px;
          height: 32px;
          background-color: $gray-medium-color;
          border-color: transparent;
          background-image: url(./../../images/svg/red-right-arrow.svg);
          transform: scale(-1);
        }
      }

      .profile-tab {
        padding-right: calc(16px + (36 - 16) * ((100vw - 320px) / (768 - 320)));
        margin-top: 32px;

        &:before {
          bottom: 2px;
        }

        .nav-item {
          &:first-child {
            .nav-item {
              padding-left: 0;
            }
          }

          &:last-child {
            .nav-item {
              padding-right: 0;
            }
          }

          .nav-link {
            padding: 0 9px;
          }
        }
      }

      .offcanvas-body {
        padding-right: calc(16px + (36 - 16) * ((100vw - 320px) / (768 - 320)));
        height: calc(100vh - calc(32px + (72 - 32) * ((100vw - 320px) / (768 - 320))) - 34px - 56px);
        max-height: 100%;

        .setting-main {
          padding-top: 20px;
        }
      }
    }

    &.client-offcanvas-detail {
      &.offcanvas-bottom {
        margin-top: 72px;
        box-shadow: 0px -8px 20px 0px #6700001F;
        border-top: 1px solid $gray-dark-color;
        border-radius: 24px 24px 0 0;

        .client-offcanvas-box {
          padding: 24px 15px 26px 15px;

          .client-edit-icon {
            top: 10px;
            right: 10px;
          }

          ~h5 {
            margin-bottom: 7px;
            margin-left: 17px;
            font-size: 13px;
            font-weight: 600;
            line-height: 16.9px;
          }

          .invoice-finance {
            margin-top: 33px;
          }

          .common-f-flex {
            gap: 12px;

            .initial-letter {
              width: 40px;
              height: 40px;
              display: flex !important;

              span {
                font-size: 16px !important;
                line-height: 20.16px !important;
              }
            }

            >div {
              span {
                font-weight: 600;
                font-size: 15px;
                line-height: 18.9px;
              }

              p {
                font-weight: 500;
                font-size: 12px;
                line-height: 16.8px;
                color: $gray;
              }
            }
          }
        }

        .offcanvas-header {
          .offcanvas-title {
            margin: 0;
          }

          .btn-close {
            display: none;
          }
        }

        .business-table {
          tbody {
            tr {
              padding: 14px 19px;

              td {

                .responsive-span,
                .responsive-box {
                  &:empty {
                    display: none;
                  }
                }

                .responsive-box {
                  h6 {
                    line-height: 17.64px;
                    margin-bottom: 8px;
                  }

                  span {
                    line-height: 16.8px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.theme-offcanvas-2 {
      &.offcanvas-bottom {
        height: calc(100vh - 80px);

        .theme-offcanvas-box {
          &::before {
            position: absolute;
            content: "";
            width: 34px;
            height: 4px;
            border-radius: 100px;
            top: 8px;
            left: 50%;
            transform: translateX(-50%);
            background-color: $gray-dark-color;
          }
        }

        .offcanvas-header {
          .btn-close {
            display: none;
          }
        }
      }
    }

    &.invoice-detail-modal {
      width: 100% !important;
      padding: 0;
      max-height: unset;
      height: calc(100vh - 72px);
      min-height: unset;
      box-shadow: 0px -8px 20px 0px rgba(103, 0, 0, 0.122);

      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 100px;
        background-color: $gray-dark-color;
        width: 34px;
        height: 4px;
      }

      .btn-close {
        display: none;
      }

      ~.offcanvas-backdrop {
        opacity: 0 !important;
      }
    }
  }

  .main-dashboard {
    .page-wrapper {
      .page-header {
        .page-title {
          .mobile-logo {
            img {
              position: relative;
              height: 32px;
              z-index: 1;
              inset: unset;
            }

            .mobile-title {
              position: fixed;
              top: 60px;
              width: 100%;
              left: 0px;
              background-color: $gray-light-color;
              padding: 41px 30px 22px;
            }
          }
        }
      }

      .page-body-wrapper {
        .page-body {
          margin-top: 179px;
        }
      }
    }
  }

  .search-input-form {
    .search-input {
      max-width: unset !important;
      padding: 40px 24px;
      background-color: $white;
      border: 1px solid $gray-dark-color;
      border-bottom: 0;
      box-shadow: 0px -8px 20px 0px rgba(103, 0, 0, 0.122);
      border-radius: 24px 24px 0 0;

      .search-img,
      .search-img-hover {
        display: block !important;
        left: 42px;
      }

      &.open {
        position: fixed;
        bottom: 0;
        left: 0;
        top: unset;
      }

      &::before {
        content: "";
        position: absolute;
        width: 34px;
        height: 4px;
        background-color: $gray-dark-color;
        border-radius: 100px;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
      }

      .form-control {
        padding: 12px 24px 12px 48px !important;
      }
    }
  }

  .datepicker-div {
    .react-datepicker__portal {
      background-color: rgba(250, 247, 250, 0.7);
      backdrop-filter: blur(1.1px);

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker__day-name {
        color: $white;
      }
    }

    .react-datepicker__navigation-icon {
      width: 100%;
      height: 100%;
      inset: unset;
      font-size: 0;
      @include flex_common;
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
      border: none;
      position: relative;
      inset: unset;
      color: transparent;
      transform: unset;
      background-image: url(../../images/svg/white-left-arrow.svg);
      width: -webkit-fill-available;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }

    .react-datepicker__navigation {
      top: 15px;
      left: 15px;
      width: 22px;
      height: 22px;
    }

    .react-datepicker__navigation--next {
      right: 15px;
      left: unset;
    }

    .react-datepicker__navigation-icon--next::before {
      background-image: url(../../images/svg/white-right-arrow.svg);
    }

    .react-datepicker {
      font-family: Plus Jakarta Sans, sans-serif;
      background-color: $gray-light-color;
      position: relative;
      color: $secondary-color-4;
      font-size: 14px;
      border: 1px solid #f0ecf0;
      box-shadow: 0px 8px 20px 0px #3916161f;
    }

    .react-datepicker__header {
      border: none;
      background-color: $primary-color-1;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background-color: $primary-color-1;
      color: $white;
      font-weight: 600;
    }

    .react-datepicker__day--selected:not([aria-disabled="true"]):hover,
    .react-datepicker__day--in-selecting-range:not([aria-disabled="true"]):hover,
    .react-datepicker__day--in-range:not([aria-disabled="true"]):hover,
    .react-datepicker__month-text--selected:not([aria-disabled="true"]):hover,
    .react-datepicker__month-text--in-selecting-range:not([aria-disabled="true"]):hover,
    .react-datepicker__month-text--in-range:not([aria-disabled="true"]):hover,
    .react-datepicker__quarter-text--selected:not([aria-disabled="true"]):hover,
    .react-datepicker__quarter-text--in-selecting-range:not([aria-disabled="true"]):hover,
    .react-datepicker__quarter-text--in-range:not([aria-disabled="true"]):hover,
    .react-datepicker__year-text--selected:not([aria-disabled="true"]):hover,
    .react-datepicker__year-text--in-selecting-range:not([aria-disabled="true"]):hover,
    .react-datepicker__year-text--in-range:not([aria-disabled="true"]):hover {
      background-color: $primary-color-1;
    }

    .react-datepicker__day:not([aria-disabled="true"]):hover,
    .react-datepicker__month-text:not([aria-disabled="true"]):hover,
    .react-datepicker__quarter-text:not([aria-disabled="true"]):hover,
    .react-datepicker__year-text:not([aria-disabled="true"]):hover {
      background-color: #f0ecf0;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: rgba(255, 74, 41, 0.188);
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #bg-overlay {
    &.active {
      height: 100vh;
      width: 100vw;
      background-color: rgba($black, 0.2);
      position: fixed;
      z-index: 8;
      top: 0;
    }
  }
}

@media (max-width: 575.98px) {
  .card {
    .card-header {
      h6 {
        font-size: 18px;
      }
    }
  }

  .horizontal-main-tab-filled {
    .horizontal-tab-filled-content {
      .tab-pane {
        &.active {
          min-height: unset;
        }
      }
    }
  }

  .select-dropdown {
    .select-dropdown-menu {
      inset: unset !important;
      transform: unset !important;
      position: fixed !important;
      bottom: -100% !important;
      left: 0 !important;
      display: block;
      width: 100%;
      height: auto;
      transition: all 2s ease-in-out;
      opacity: 0;
      visibility: hidden;
      height: 0;
      padding-block: calc(28px + (50 - 28) * ((100vw - 320px) / (576 - 320)));
      border-radius: 12px 12px 0 0;

      .btn-close {
        background-size: 12px 12px;
        border: 1px solid $gray-dark-color;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 14px;
        top: 14px;
        z-index: 5;
        cursor: pointer;
        background-color: $white;
      }

      &.show {
        height: auto;
        bottom: 0 !important;
        opacity: 1;
        visibility: visible;
        transition: none;
      }

      .dropdown-item {
        border-radius: 100px;
        padding: 16px 32px;
      }
    }
  }

  .datepicker-box {
    &.d-block {
      .form-control {
        padding: 9px 12px !important;
      }
    }
  }

  .switch-field {
    padding: 3px !important;

    label {
      padding: 7px;
      line-height: 19.6px;
      font-size: 14px;
    }
  }

  .all-project-table,
  .project-all-invoice-table,
  .project-detail-invoice-table {
    .table {
      tbody {
        tr {
          td {
            .badge {
              padding: 0;
            }
          }
        }
      }
    }
  }

  .project-all-invoice-table {
    .table {
      tbody {
        tr {
          td {
            &.name-box {
              order: 1;

              .responsive-box {
                span {
                  width: auto;
                  display: block;

                  &.text {
                    max-width: calc(227px + (283 - 227) * ((100vw - 320px) / (1920 - 320)));
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  }
                }
              }
            }

            &.invoice-number {
              order: 0;

              .responsive-box {
                span {
                  width: auto;
                  display: block;
                }
              }
            }

            &.status-table {
              order: 2;
            }

            &.amount-header {
              .responsive-box {
                position: absolute;
                bottom: -130px;
                right: 0;
                width: 50%;

                .amount {
                  font-size: 13px;
                  font-weight: 700;
                  line-height: 16.38px;
                }
              }
            }

            &.status-table {
              border-bottom: unset;

              .responsive-box {
                .badge {
                  width: 50%;
                  text-align: left;
                }

                .badge-text {
                  position: absolute;
                  top: -32px;
                  right: 0;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 16.8px;
                  color: #433b55;
                }
              }
            }

            .amount {
              top: -33px;
            }
          }
        }
      }
    }

    &.draft-table {
      .invoice-number {
        border: none;
      }
    }
  }

  .client-table {
    .table {
      tbody {
        tr {
          padding: 24px 20px 20px 16px;

          td {
            max-width: unset;
            display: none;

            &.country-box,
            &.client-name-box,
            &.responsive-dropdown {
              display: block;
            }

            &.country-box {
              border: none;
              border-top: 1px solid $gray-dark-color;
              padding-bottom: 0;

              .responsive-span {
                font-size: 12px;
                font-weight: 500;
                line-height: 16.8px;
              }

              .responsive-box {
                span {
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 16.8px;
                }
              }
            }

            &.client-name-box {
              padding-block: 0 17px;

              .initial-letter {
                span {
                  font-size: 16px !important;
                  font-weight: 700 !important;
                  line-height: 20.16px !important;
                }
              }

              .responsive-span {
                display: none;
              }

              .responsive-box {
                h6 {
                  max-width: calc(165px + (370 - 165) * ((100vw - 320px) / (576 - 320)));
                  margin-top: 0;
                  font-size: 15px;
                  font-weight: 600;
                  line-height: 18.9px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  display: block;
                }
              }
            }
          }
        }
      }

      &.business-table {
        tbody {
          tr {
            td {
              @media (max-width: 575.98px) {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .project-detail-invoice-table {
    .table {
      tbody {
        tr {
          td {
            &.order-2 {
              border-bottom: none;
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }

  .freelancer-project {
    &.project-detail-invoice-table {
      .table {
        tbody {
          tr {
            padding: 30px 20px 16px;

            td {
              &.invoice-number-box {
                border-bottom: 1px solid $gray-dark-color;
                margin-bottom: 0;
                padding-top: 0;
                padding-bottom: 16px;

                .responsive-span {
                  margin-bottom: 6px;
                  font-size: 12px;
                  line-height: 16.8px;
                }

                .responsive-box {
                  font-size: 13px;
                  font-weight: 600;
                  line-height: 16.38px;
                }
              }

              &.invoice-date-box {
                position: absolute;
                top: 29px;
                text-align: right;
                padding-right: 25px;
                right: 20px;
                padding-top: 0;
                width: calc(50% - 20px);
                padding-bottom: 16px;
                padding-right: 25px !important;

                .responsive-box {
                  span {
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 16.38px;
                  }
                }
              }

              &.last-update-box {
                padding-bottom: 0;
                border-bottom: 0;
                padding-top: 16px;

                .responsive-span {
                  width: 50%;
                }

                .responsive-box {
                  .badge {
                    width: calc(50% - 9px) !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    display: block;
                    text-align: left;

                    .media {
                      margin: 0;
                      margin-right: 6px;
                      margin-top: -3px;
                    }
                  }

                  .date {
                    width: 50%;
                    text-align: right;
                    margin: 0;
                    margin-top: 14px;
                    padding-right: 25px !important;
                  }
                }
              }

              &.amount-header {
                .responsive-span {
                  display: none;
                }

                .responsive-box {
                  margin-top: -18px;
                  width: 50%;
                  margin-left: auto;
                  text-align: right;

                  .amount {
                    text-align: right;
                    padding-right: 25px;
                    padding-right: 25px;
                  }
                }
              }
            }
          }
        }

        &.business-table {
          tbody {
            tr {
              td {
                &:first-child {
                  order: 1;
                  padding-block: 16px;

                  .responsive-box {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    span {
                      width: auto;
                    }
                  }
                }

                &.last-update-box {
                  order: 3;
                  padding-top: 0;

                  .responsive-span {
                    position: absolute;
                    opacity: 0;
                  }

                  .responsive-box {
                    padding-top: 29px;

                    .date {
                      text-align: right;
                      padding-right: 25px !important;
                      margin: 0;
                    }
                  }
                }

                &.amount-header {
                  .responsive-box {
                    position: absolute;
                    bottom: -124px !important;
                    right: 0;
                    margin: 0;
                    text-align: right;
                    padding-right: 25px !important;

                    .amount {
                      text-align: right;
                      padding-right: 0;
                    }
                  }
                }

                &.freelancer-box {
                  order: unset;
                  border-bottom: 1px solid $gray-dark-color;
                  padding-top: 0;

                  &:has(~.flex-invoice-box .freelancer-flex:empty) {
                    border-bottom: 0;
                    padding-bottom: 0;
                  }

                  .responsive-span {
                    display: none;
                  }

                  .responsive-box {
                    display: grid;
                  }

                  ~td {
                    display: none;
                  }
                }

                &.flex-invoice-box {
                  display: block !important;
                }

                &.last-invoice-box {
                  width: 50%;
                  padding-bottom: 0;
                  display: block !important;

                  .responsive-box {
                    >span {
                      font-size: 13px;
                      font-weight: 500;
                      line-height: 18.2px;
                      color: #433B55;
                    }
                  }

                }

                &.ongoing-invoice-box {
                  width: 43%;
                  position: relative;
                  display: block !important;
                  padding-right: 15px;

                  .responsive-box {
                    >span {
                      font-size: 13px;
                      font-weight: 500;
                      line-height: 18.2px;
                      color: #433B55;
                    }
                  }
                }

                &.last-invoice-box,
                &.ongoing-invoice-box {
                  padding-block: 0;
                  margin-top: 10px;

                  &:has(.responsive-box > span:empty) {
                    display: none !important;
                  }

                  &:has(.responsive-box > span:not(:empty)) {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .page-wrapper {
    .page-header {
      .page-title {
        position: static;

        .mobile-logo {
          img {
            height: 32px;
            position: absolute;
            top: -26px;
            left: 45px;
            z-index: 1;
          }
        }

        .mobile-title {
          width: auto;
          padding: 0;
        }
      }

      &.close_icon {
        .header-logo-wrapper {
          .open-icon {
            display: block;
          }

          .close-icon {
            display: none;
          }
        }

        .logo-wrapper {
          display: none;
        }

        .offer-card {
          bottom: 0;
          position: absolute;
        }

        &.sidebar-wrapper {
          .logo-wrapper {
            display: none;
          }
        }
      }

      .header-logo-wrapper {
        .open-icon {
          display: none;
        }

        .close-icon {
          display: block;
          width: 24px;
          height: 24px;
          background-color: $white;
          padding: 4px;
          border-radius: 100%;
        }
      }

      h2 {
        font-size: 20px;
        line-height: 25.2px;
      }

      &:has(~ .page-body-wrapper .invoice-main) {
        .header-logo-wrapper {
          .toggle-sidebar {
            .close-icon {
              background-color: $gray-light-color;
            }
          }
        }
      }
    }

    .page-body-wrapper {
      .sidebar-wrapper {
        top: 60px;
        width: 100%;
        height: calc(100vh - 60px);

        .logo-wrapper {
          display: none !important;
        }

        .sidebar-main {
          height: calc(100vh - 60px - calc(30px + (66 - 30) * ((100vw - 320px) / (575 - 320))));
          margin-top: calc(30px + (66 - 30) * ((100vw - 320px) / (575 - 320)));

          .sidebar-links {
            padding: 0 20px 41px !important;
          }

          .offer-card {
            display: none;
          }
        }
      }

      .page-body {
        margin-top: 84px;
      }
    }

    .page-body {

      .invoice-main,
      .finance-main,
      .customer-main {
        padding: 15px;
      }
    }
  }

  #bg-overlay {
    &.active {
      height: 100vh;
      width: 100vw;
      background-color: rgba($white, 0.2);
      position: fixed;
      z-index: 2;
      top: 0;
    }
  }

  .pagination-info {
    width: 100%;

    span {
      line-height: 1;
    }

    .select-menu {
      width: max-content;
    }
  }

  .verification-form {
    .resend-code {
      flex-direction: column;
      align-items: center;
      gap: calc(9px + (25 - 9) * ((100vw - 320px) / (576 - 320)));
    }
  }

  .verify-invoice {
    .t-padding {
      padding-top: 30px;
    }

    .verify-invoice-content {
      .verify-invoice-form {
        margin: 0;

        .invoice-data {
          border: 1px solid $gray-dark-color;
          border-radius: 12px;
          padding: 15px;
        }

        .form-check {
          padding-inline: 0;
        }

        .invoice-approved {
          width: 100%;
        }

        .select-reason {
          padding-inline: 0;
        }

        .invoice-approved-footer {
          width: 100%;
        }
      }
    }

    .auth-footer {
      flex-direction: row;
    }

    .invoice-data {
      li {
        span {
          min-width: 90px;
        }

        p {
          small {
            word-break: break-word;
          }
        }
      }
    }
  }

  .invoice-work-table {
    tbody {
      tr {
        &.editing {
          border-color: $success-medium-color;
          border-left-color: $success-medium-color !important;
        }

        td {
          &.qty-data {
            .w-full {
              gap: 9px;

              .filter-dropdown-box {
                width: 98px;

                .select-btn {
                  padding: 8px;
                  border-radius: 4px;
                  background-color: $gray-medium-color;
                  width: 100%;
                  font-size: 14px;
                  background-position: center right 12px;
                  line-height: 19.6px;
                  background-size: 14px 14px;
                }
              }

              .d-flex {
                padding: 0px;
                border-radius: 4px;
                background-color: $gray-medium-color;
                width: calc(100% - 98px - 8px);
                font-size: 14px;
                background-position: center right 20px;
                line-height: 19.6px;
              }
            }

            .footer-buttons-main {
              position: absolute !important;
              left: 0;
              bottom: 0px;

              .footer-buttons {
                display: flex;
                align-items: center;
                justify-content: end;
                gap: 12px;
                width: 100%;
                padding-inline: calc(20px + 5 * (100vw - 320px) / 448);
              }
            }
          }
        }
      }
    }
  }

  .select-menu {
    &.form-control {
      &.with-link {
        .select-btn {
          background-position: center right 52px !important;

          &.selected {
            .select-data {
              background-position: center right 16px !important;
              background-size: 14px 14px !important;
              padding-block: 2px;
              margin-right: 32px !important;
            }
          }

          .select-data {
            background-position: center right 16px !important;
            background-size: 14px 14px !important;
            padding-block: 2px;
            margin-right: 32px !important;
            padding-left: 12px;
          }
        }
      }
    }
  }

  .payment-main {
    .payment-right {
      padding: 15px 15px 0;

      h5 {
        font-size: 16px;
        font-weight: 700;
        line-height: 20.16px;
        margin-bottom: 16px;
      }

      .invoice-data {
        padding: 32px 18px;

        li {
          gap: 19px;
        }
      }

      .invoice-action {
        padding: 12px 0;
        margin-top: 25px;

        li {
          img {
            width: 24px;
            height: 24px;
            padding: 2px;
          }
        }
      }
    }

    .payment-left {
      padding: 32px 15px 0;

      label {
        font-size: 16px;
        font-weight: 700;
        line-height: 20.16px;
      }

      .payment-detail {
        li {
          flex-wrap: wrap;
        }
      }

      .horizontal-main-tab-filled {
        margin-top: 16px;
      }
    }
  }

  .transaction-detail {
    .transaction-list {
      min-height: unset;
      margin-bottom: 28px;
      max-height: unset;
    }
  }

  .profile-setting {
    .setting-main {
      .transaction-detail {
        .transaction-list {
          .transaction-item {

            &.open,
            &.add-item {
              .transaction-data {
                flex-direction: column;
                align-items: flex-start;

                .edit-item-data,
                .add-item-data {
                  margin-top: 10px;
                }
              }

              .transaction-action {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .phone-detail {
    .input-group {
      .select-menu {
        min-width: 100px;
      }
    }
  }

  .custom-file-input {
    .form-control {
      height: 38px;
    }
  }

  .reset-form-field {
    .btn {
      top: 5px;
      padding-block: 4px;
    }
  }

  .offcanvas {
    &.theme-offcanvas-2 {
      &.offcanvas-bottom {
        height: calc(100vh - 72px);

        .theme-offcanvas-box {
          padding: 52px 0 81px 20px;

          .offcanvas-header {
            margin-left: 10px;
            margin-bottom: 24px;

            .offcanvas-title {
              font-size: 20px;
              font-weight: 700;
              line-height: 25.2px;
            }
          }

          .offcanvas-body {
            max-height: calc(100vh - 167px - 88px);
            padding-right: 20px;
          }

          .offcanvas-footer {
            padding: 18px 24px;
          }
        }

        ~.offcanvas-backdrop {
          opacity: 0 !important;
        }
      }

      .client-offcanvas-box {
        .common-f-flex {
          gap: 12px;

          .initial-letter {
            width: 40px;
            height: 40px;
          }

          >div {
            span {
              font-size: 15px;
              font-weight: 600;
              line-height: 18.9px;
            }
          }
        }
      }

      .btn-secondary-3 {
        padding: 8px 9px;
      }
    }

    &.finance-modal {
      .offcanvas-header {
        .offcanvas-title {
          line-height: 20px;
        }
      }

      .invoice-finance {
        padding: 20px;

        .invoice-data {
          li {
            span {
              font-size: 13px;
              min-width: 90px;
            }

            p {
              font-size: 13px;
            }
          }
        }
      }
    }

    &.invoice-detail-modal {
      .invoice-action {
        justify-content: space-evenly;
        gap: 5px;
        padding: 15px 0;

        li {
          font-size: 14px;
          gap: 4px;
          line-height: 17.64px;

          img {
            display: none;
          }
        }
      }

      .footer-buttons {
        .btn {
          padding: 13px;
          font-size: 14px;
          font-weight: 700;
          line-height: 17.64px;
        }
      }

      .offcanvas-body {
        overflow: hidden;

        .invoice-content {
          height: unset !important;
          padding-top: 52px;
          padding-inline: 0;
          display: block;

          >div {
            display: block;
          }
        }
      }
    }

    &.invoice-send-modal {
      .offcanvas-body {
        .invoice-content {
          padding: 50px 0 0;
          height: auto !important;
        }
      }

      .invoice-right-part {
        gap: 20px;

        h2 {
          flex-direction: column;
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          line-height: 25.2px;
          margin-bottom: 0 !important;
          gap: 16px;
          padding-inline: 34px;

          img {
            width: 100px;
            height: 100px;
            padding: 12px 12px 0;
          }
        }

        .invoice-data {
          p {
            width: 100%;
            overflow: hidden;
            @include text-line;
          }
        }

        .btn-close {
          top: 20px !important;
          right: unset !important;
          left: 16px !important;
          width: 32px !important;
          height: 32px !important;
          padding: 0;
          background-color: $gray-medium-color;
          border-color: transparent !important;
          background-image: url("../../images/svg/red-right-arrow.svg");
          transform: scale(-1);
          background-size: 17px 17px;
        }

        .modal-note {
          margin-block: 16px 24px;
          text-align: center;
          padding-inline: 34px;

          p {
            font-size: 13px;
            font-weight: 500;
            line-height: 18.2px;
          }
        }
      }

      .invoice-data {
        height: calc(100vh - 467px);
      }
    }

    &.freelancer-detail-offcanvas {
      .offcanvas-body {
        .business-table {
          tbody {
            tr {
              padding: 12px 12px 14px 19px;
              flex-direction: row-reverse;
              justify-content: space-between;

              td {
                &:has(.responsive-box:empty) {
                  display: none;
                }

                .responsive-span:empty,
                .responsive-box:empty {
                  display: none;
                }

                .responsive-box {
                  h6 {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 17.64px;
                  }

                  span {
                    font-size: 12px;
                    line-height: 16.8px;
                    margin-top: 8px;
                  }
                }

                &.responsive-dropdown {
                  position: relative;
                  inset: unset;
                }
              }
            }
          }
        }
      }
    }
  }

  .business-profile {
    .profile-setup {
      .btn-secondary-3 {
        padding: 8px 9px;
      }
    }
  }

  .payment-setting-footer-main {
    width: 100%;

    .btn {
      font-size: 12px;
      padding-inline: 10px;
    }
  }

  .payment-setting-footer {
    .btn {
      width: 100%;
    }
  }

  .currency-change-modal {
    width: auto !important;

    .modal-body {
      .currency-content {
        padding: 20px;

        h2 {
          font-size: 20px !important;
          line-height: 24px !important;
        }

        .currency-data {
          margin-top: 30px;
          margin-bottom: 80px;
        }
      }
    }

    .btn-close {
      min-width: 20px;
      width: 20px;
      height: 20px;
      font-size: 9px;
    }
  }

  .footer-buttons-main {
    &.summary-footer {
      .footer-buttons {
        flex-wrap: wrap;

        .btn {
          min-width: unset;
          width: 100%;
        }
      }
    }
  }

  .footer-buttons {
    .btn {
      padding: 13px 24px;
    }
  }

  .profile-setting {
    .profile-box {
      flex-direction: column;
      align-items: flex-start !important;
      margin-bottom: 10px !important;
      gap: 10px !important;
    }
  }

  .p-6 {
    padding: calc(6px + (12 - 6) * ((100vw - 320px) / (1920 - 320)));
  }

  .invoice-main-content {
    .select-menu {
      &.form-control {
        &.with-link {
          background-position: center right 40px;

          .input-btn {
            padding: 7px;
          }
        }
      }
    }
  }

  .invoice-main {
    padding: 0 !important;
    background-color: transparent;

    .invoice-tab-content {
      height: calc(100vh - 114px);
      min-height: unset;
    }
  }

  .invoice-work-table {
    tbody {
      tr {
        &:hover {
          td {
            &:first-child {
              border-left: none;
            }
          }
        }

        td {
          max-width: none;

          &:first-child {
            max-width: none;
          }
        }
      }
    }
  }

  .select-menu {
    &.form-control {
      &.with-link {
        background-position: center right 40px;

        .input-btn {
          padding: 7px;
        }
      }
    }
  }

  .invoice-main {
    .summary-note {
      padding: 15px;

      ul {
        gap: 16px;

        li {
          flex-direction: column;
          gap: 4px;
        }
      }
    }
  }

  .total-data {
    margin-left: auto;

    span {
      padding-right: 6px;
    }
  }

  .custom-dropdown-main {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: $white;
    padding: 14px 27px;
    width: 100%;
    border-radius: 12px 12px 0 0;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 28px);
      height: 1px;
      border-top: 1px solid $gray-dark-color;
    }
  }

  .table {
    margin-block: 0;

    thead {
      display: none;
    }

    tbody {
      tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;
        border: 1px solid $gray-dark-color;
        border-left: 6px solid $box-shadow;
        border-radius: 8px;
        padding: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));

        &:last-child {
          margin-bottom: 0;
        }

        td {
          border: none;
          padding: 10px 0;
          max-width: unset;

          &:has(.responsive-box:empty, span:empty) {
            padding: 0;
            border: none;
          }

          &:first-child {
            border-radius: 8px 8px 0 0;
            border-left: none;
          }

          &:last-child {
            border-bottom: none;
            border-radius: 0 0 8px 8px;
          }

          .responsive-span {
            font-size: 13px;
            color: $gray;
            line-height: 18px;
            margin-bottom: 6px;
          }

          .responsive-box {
            width: 100%;
            overflow: hidden;
            @include text-line($line: 2);

            &.avatar-group {
              display: flex;

              .avatar {
                width: 35px;
                height: 35px;

                h6 {
                  font-size: 12px;
                }
              }
            }
          }

          .responsive-box {
            width: auto;
            overflow: hidden;
            @include text-line;

            h6 {
              margin: 0;
              width: max-content;
              overflow: unset;
              display: inline-block;
              -webkit-line-clamp: unset;
              -webkit-box-orient: unset;
              text-overflow: unset;
              word-break: unset;
              white-space: nowrap;
            }

            span {
              width: 100%;
              overflow: hidden;
              @include text-line;
              word-break: break-all;
            }

            &.avatar-group {
              display: flex;

              .avatar {
                width: 35px;
                height: 35px;

                h6 {
                  font-size: 12px;
                }
              }
            }
          }

          .date {
            padding-left: 0;
            position: absolute;
            top: calc(0px + (3 - 0) * ((100vw - 320px) / (575 - 320)));
            right: 3px;
            text-align: end;
            z-index: 0;
          }
        }

        &:hover,
        &:active,
        &:focus {
          border-left-color: $box-shadow-active-hover;
        }

        &.archived-badge {
          border-left: 6px solid $warning-light-color;

          &:hover,
          &:active,
          &:focus {
            border-left-color: $warning-medium-color;
          }
        }
      }

      &.responsive-body {
        tr {
          position: relative;

          td {
            &.responsive-dropdown {
              position: static;
              order: -1;

              &:last-child {
                border: none;
                padding: 0 !important;
                position: absolute;
                top: 10px;
                right: 10px;
                display: block !important;

                .onhover-dropdown {

                  .action-img,
                  .action-img-active {
                    width: 24px;
                    height: 24px;
                  }
                }

                .select-dropdown {
                  position: static;
                }
              }

              .select-dropdown-2 {
                position: relative;
                inset: unset;
              }
            }

            &:nth-last-child(3) {
              border-radius: 0;
            }

            &:nth-last-child(2) {
              border: none;
              padding: 0;
            }
          }
        }
      }
    }
  }

  .project-detail-document-table {
    .table {
      thead {
        display: none;
      }

      tbody {
        tr {
          padding-block: 20px 17px;
          display: flex;

          td {
            position: static;
            padding: 12px 0;
            display: block;


            &:first-child {
              padding-top: 0;
            }

            &:nth-last-child(2) {
              padding-top: 8px;
              padding-bottom: 0;
            }

            &.responsive-dropdown {
              top: 20px;

              &:last-child {
                top: 20px;
              }
            }

            .doc-span {
              display: none;
            }

            .date {
              position: relative;
              inset: unset;
              text-align: left;
            }
          }
        }
      }

      &.business-table {
        tbody {
          tr {
            padding: 20px 20px 16px 14px;

            td {
              &.document-box {
                .responsive-box {
                  padding-bottom: 25px;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 17.64px;

                  @media (max-width:575px) {
                    padding-bottom: 10px;
                  }
                }
              }

              &.share-box {
                margin-top: 20px;
                padding-top: 12px;
                border-top: 1px solid $gray-dark-color;

                .responsive-span {
                  display: block;
                  margin-bottom: 12px;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 16.8px;
                }

                .responsive-box {
                  display: block;
                }
              }

              &.date-box {
                position: absolute;
                padding: 0;
                left: 15px;
                bottom: 105px;
              }
            }
          }
        }
      }
    }
  }

  .select-dropdown {
    .select-dropdown-btn {
      width: 24px;
      height: 24px;
    }
  }

  .invoice-main {
    .custom-dropdown-main {
      top: 24px;
      left: 36px;
    }

    .invoice-work-table {
      &.table {
        tbody {
          tr {
            border-left: 1px solid $gray-dark-color;
            padding: 24px 16px;

            td {

              input,
              textarea {
                padding: 8px;
                border-radius: 4px;
                background-color: $gray-medium-color;
                width: 100%;
                height: 36px !important;
                font-size: 14px;
                line-height: 19.6px;
              }

              .select-menu {
                .filter-dropdown-box {
                  min-width: unset;
                  max-width: unset;
                }

                .select-btn {
                  padding: 9px 15px;
                  border-radius: 4px;
                  background-color: $gray-medium-color;
                  background-position: center right 12px;
                  width: 100%;
                  text-align: right;
                }
              }

              &:last-child {
                border: none;
              }

              .responsive-span {
                min-width: 30px;
                margin-bottom: 12px;
                font-weight: 600;
                font-size: 13px;
                line-height: 18.2px;
                color: $gray;
              }

              span {
                font-size: 14px;
                line-height: 17.64px;
              }
            }
          }
        }

        .list-action {
          width: 32px;
          height: 32px;
          cursor: pointer;
        }
      }
    }
  }

  .w-full {
    width: 100%;
  }

  .select-dropdown-box {
    max-width: unset;
    min-width: unset;
  }

  .tab-right-filter {
    justify-content: space-between;
    gap: 20px;

    @media (max-width: 375px) {
      gap: 12px;
    }

    .filter-dropdown-box,
    .select-dropdown-box,
    .select-menu {
      max-width: unset;
      min-width: unset;
      width: 100%;
    }

    .btn {
      width: 40px;
      min-width: 40px;
      height: 40px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .select-dropdown-box {
      .select-btn {
        background-position: center right 13px;
        width: 100%;
        min-width: unset;
        height: unset;
        justify-content: unset;
        padding: 10px 20px;
        font-size: 14px;
      }
    }

    .filter-dropdown-box {
      max-width: unset;
      min-width: unset;

      .select-btn {
        width: 100%;
        min-width: unset;
        height: 40px;
        justify-content: unset;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        background-position: center right 13px;
      }
    }
  }

  .filter-dropdown-box {
    .select-dropdown-menu {
      z-index: 4;
      position: fixed !important;
      bottom: 0 !important;
      top: unset !important;
      width: 100%;
      left: 0 !important;
      padding: 50px 20px;
      margin: 0;
      border-radius: 24px 24px 0 0;
      transform: unset !important;
      box-shadow: 0px -8px 20px 0px rgba(103, 0, 0, 0.122);
      border-top: 1px solid $gray-dark-color;
    }
  }

  .select-menu {
    .select-btn {
      padding: 9px 12px;
      background-position: center right 12px;
      background-size: 14px 14px;
      padding-right: 40px;
    }

    .select-options {
      top: 36px;
    }

    .input-btn {
      width: max-content;
      padding: 7px;
    }

    &.form-control {
      &.with-link {
        .select-btn {
          background-position: center right 40px;
          padding-right: 70px;
        }
      }
    }

    &.form-check {
      padding: 9px 12px !important;
    }
  }

  .promocode-box {
    .promocode {
      .btn {
        padding: 5px 16px;
      }

      .promocode-input {
        &:disabled {
          ~.promo-service-box {
            padding: 6px;
          }
        }
      }
    }
  }

  .horizontal-main-tab {
    .horizontal-tab-content {
      overflow: hidden;
      border-radius: 0;

      .tab-pane {
        padding: 14px calc(17px + (20 - 17) * ((100vw - 320px) / (575 - 320)));
        border-radius: 0 0 12px 12px;
      }
    }
  }

  .project-all-transaction-table,
  .project-detail-transaction-table,
  .finance-table,
  .finance-table-1 {
    table {
      thead {
        tr {
          th {
            &:last-child {
              text-align: start;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &:last-child {
              text-align: start;
              border-right: none;
            }

            &:nth-last-child(2) {
              border-bottom: 1px solid $gray-dark-color !important;
            }
          }
        }
      }
    }
  }

  .finance-table,
  .finance-table-1 {
    tbody {
      tr {
        td {
          .btn {
            width: max-content;
          }
        }
      }
    }
  }

  .recent-invoice-card {
    .table {
      padding-top: 16px;

      thead {
        display: none;
      }

      tbody {
        tr {
          display: flex;
          flex-direction: column;
          border: 1px solid $gray-dark-color;
          border-left: 6px solid $box-shadow;
          border-radius: 8px;
          padding: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));

          +tr {
            margin-top: 12px;
          }

          td {
            border: none;
            border-bottom: 1px solid $gray-dark-color;
            padding: 10px 0;
            max-width: unset;

            &:first-child {
              border-radius: 8px 8px 0 0;
            }

            &:last-child {
              border-bottom: none;
              border-radius: 0 0 8px 8px;
            }

            &:nth-child(4) {
              .responsive-span {
                display: none;
              }
            }

            .responsive-span {
              font-size: 12px;
              color: $gray;
              line-height: 18px;
              margin-bottom: 8px;
            }

            .responsive-box {
              width: 100%;
              overflow: hidden;
              @include text-line;
              display: flex;
              gap: 4px;
              align-items: center;

              .in-transfer-badge {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;
                max-width: 130px;
                width: unset !important;
                padding: 0;

                img {
                  margin-right: 4px;
                }
              }

              h6 {
                margin: 0;
                width: max-content;
                overflow: unset;
                display: inline-block;
                -webkit-line-clamp: unset;
                -webkit-box-orient: unset;
                text-overflow: unset;
                word-break: unset;
                white-space: nowrap;
              }

              .top {
                margin: 0;
                width: max-content;
                overflow: unset;
                display: inline-block;
                -webkit-line-clamp: unset;
                -webkit-box-orient: unset;
                text-overflow: unset;
                word-break: unset;
                white-space: nowrap;
              }

              span {
                width: 100%;
                overflow: hidden;
                @include text-line;
                word-break: break-all;
                margin-top: 0;
              }
            }

            .date {
              padding-left: 0;
              position: absolute;
              top: 12px;
              right: 3px;
              text-align: end;
            }

            .amount {
              font-size: 13px;
              font-weight: 700;
              text-align: right;
            }

            .common-add-btn {
              width: 100%;
              padding-block: 10px;
            }
          }

          &:hover,
          &:active,
          &:focus {
            border-left-color: $box-shadow-active-hover;
          }
        }
      }
    }

    .card-header {
      .btn {
        padding: 0;
        width: 40px;
        height: 40px;
        justify-content: center;

        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  .business-recent-invoice {
    .table {
      tbody {
        tr {
          td {
            &:nth-child(3) {
              border-bottom: unset;

              .responsive-box {
                margin-top: 3px;
              }
            }

            &:nth-last-child(3) {
              padding-block: 7px;
              border-bottom: unset;
            }

            &:last-child {
              padding-block: 4px;
            }

            .responsive-box {
              .amount {
                bottom: 7px;
              }
            }
          }
        }
      }
    }
  }

  .project-all-transaction-table,
  .project-all-invoice-table,
  .all-project-table {
    .table {
      thead {
        display: none;
      }

      tbody {
        tr {
          display: flex;
          flex-direction: column;
          margin-bottom: 12px;
          border: 1px solid $gray-dark-color;
          border-left: 6px solid $box-shadow;
          border-radius: 8px;
          padding: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));

          td {
            border: none;
            border-bottom: 1px solid $gray-dark-color;
            padding: 10px 0;
            max-width: unset;

            &:has(.responsive-box .badge) {
              justify-content: space-between;

              span {
                width: auto;
                margin-bottom: 16px;
                line-height: 1.4;
              }
            }

            &:first-child {
              border-radius: 8px 8px 0 0;
            }

            &:last-child {
              border-bottom: none;
              border-radius: 0 0 8px 8px;
            }

            &:nth-child(4) {
              .responsive-span {
                display: none;
              }
            }

            .responsive-span {
              font-size: 12px;
              color: $gray;
              line-height: 18px;
              margin-bottom: 6px;
            }

            .responsive-box {
              width: 100%;
              overflow: hidden;
              @include text-line;
              display: flex;
              gap: 4px;
              align-items: center;

              &:has(.badge) {
                justify-content: space-between;
              }

              h6 {
                margin: 0;
                width: max-content;
                overflow: unset;
                display: inline-block;
                -webkit-line-clamp: unset;
                -webkit-box-orient: unset;
                text-overflow: unset;
                word-break: unset;
                white-space: nowrap;
              }

              .top {
                margin: 0;
                width: max-content;
                max-width: calc(75px + (283 - 75) * ((100vw - 320px) / (576 - 320)));
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;
              }

              span {
                width: 100%;
                overflow: hidden;
                @include text-line;
                word-break: break-all;
                margin-top: 0;

                &:last-child {
                  text-align: right;
                }
              }
            }

            .date {
              padding-left: 0;
              position: absolute;
              top: 3px;
              right: 3px;
              text-align: end;
            }

            .amount {
              text-align: right;
            }

            .common-add-btn {
              width: 100%;
            }

            &:has(.responsive-box span:empty) {
              padding: 0;
            }
          }

          &:hover,
          &:active,
          &:focus {
            border-left-color: $box-shadow-active-hover;
          }
        }
      }
    }

    .card-header {
      .btn {
        padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));

        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  .all-project-table {
    .table {
      tbody {
        tr {
          td {
            .common-add-btn {
              margin-inline: auto;
            }

            &:nth-last-child(2) {
              padding: 10px 0;
            }

            &:nth-child(2) {
              &:has(&:nth-child(3) .responsive-box span:empty) {
                border-bottom: 0;
                padding-bottom: 0;
              }
            }

            &.client-box {
              display: block;

              &:has(~.last-invoice-box .responsive-box span:empty) {
                border-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .nav-right {
    .profile-box {
      .profile-image {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        outline: 4px solid $white;
        box-shadow: $box-shadow-2;
        border: none;
      }
    }
  }

  .project-all-invoice-table,
  .project-all-transaction-table {
    .table {
      tbody {
        tr {
          td {
            &:empty {
              display: none;
            }

            &:first-child,
            &:nth-child(4) {
              display: none;
            }

            &:nth-last-child(2) {
              .responsive-span {
                display: none;
              }
            }

            &.order-3 {
              border: none;
              padding: 0;
            }

            &:nth-last-child(2) {
              border-bottom: none !important;
            }

            .responsive-box {
              position: relative;
              overflow: unset;

              .responsive-text {
                position: absolute;
                top: -21px;
                right: 0;
                left: unset;
                display: block;
                width: auto;
                color: $dark-gray;
              }

              .text {
                width: auto;
                font-weight: 600;
              }
            }

            .down,
            .up {
              width: calc(101px + (150 - 101) * ((100vw - 320px) / (575 - 320)));
              font-size: 12px;
              gap: 6px;
            }
          }
        }
      }
    }
  }

  .log-in-section {
    .login-main {
      .social-media {
        li {
          padding: 0;
          width: 100%;
          border-radius: 100px;

          &.social-box {
            grid-column: 1;
            width: auto;

            a {
              text-align: center;

              img {
                width: 30px;
                height: 30px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 7px;
              }
            }
          }

          a {
            img {
              width: 25px;
              height: 25px;
              position: relative;
              inset: unset;
              transform: unset;
            }
          }
        }
      }
    }
  }

  .custom-width-card {
    width: 458px;
  }

  .verification-form {
    .otp-code {
      gap: 4px !important;

      .otp-field {
        width: 66px;
        height: 66px;
        border-radius: 6px;
      }
    }

    .action-btn {
      .btn {
        padding-block: 8px;
      }
    }
  }

  .custom-width-card {

    .custom-w,
    .social-media {
      width: 420px;
    }
  }

  .auth-main {
    .auth-header {
      padding-inline: 20px;
      top: 20px;

      .logo-wrapper {
        height: auto;
        width: 90px;
      }
    }
  }

  .t-padding {
    padding-top: 120px;
  }

  .not-found-page {
    img {
      width: 70%;
    }
  }

  .profile-setup {
    padding-bottom: calc(60px + (170 - 60) * ((100vw - 320px) / (575 - 320)));
    padding-top: 42px;

    .profile-setup-field {
      >div {
        &:last-child {
          >div {
            margin-bottom: 0 !important;
          }
        }
      }

      .opacity-0 {
        display: none;
      }
    }
  }

  .auth-footer {
    flex-direction: column;

    .btn {
      width: 100%;
    }
  }

  .profile-card {
    .horizontal-tab-content {
      padding: calc(20px + (32 - 20) * ((100vw - 320px) / (575 - 320))) calc(10px + (20 - 10) * ((100vw - 320px) / (575 - 320)));
      height: auto;
      max-height: none;
    }

    .cart-tab {
      padding: 64px 20px 32px;

      .btn {
        width: 100%;
        justify-content: space-between;
        margin-top: 79px;
      }

      p {
        width: auto !important;
        padding-inline: 0 !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: 19.6px !important;
      }
    }
  }

  .date-picker-img {
    width: 24px;
    height: 24px;
  }

  body {
    &.mobile-dashboard {
      .page-wrapper {
        .page-body-wrapper {
          .page-body {
            margin-top: 170px;
            padding-inline: 8px;
          }

          .w-user-name {
            margin-top: 170px;
          }
        }
      }
    }

    &:has(.outside-dropdown.show) {
      &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(221, 221, 221, 0.5);
        backdrop-filter: blur(5px);
        z-index: 9;
      }

      .select-btn {
        box-shadow: none;
        border-radius: 22px;
        border-bottom: 1px solid #d4cfd4;
      }

      .select-dropdown-menu {
        position: fixed !important;
        bottom: 0 !important;
        top: unset !important;
        width: 100%;
        left: 0 !important;
        padding: 50px 20px;
        margin: 0;
        border-radius: 24px 24px 0 0;
        transform: unset !important;
        box-shadow: 0px -8px 20px 0px rgba(103, 0, 0, 0.1215686275);
        border-top: 1px solid $gray-dark-color;
        z-index: 9;

        .list-box {
          margin: 0;
          max-height: 230px;
          overflow: auto;
        }

        &::before {
          content: "";
          position: absolute;
          top: 8px;
          left: 50%;
          width: 30px;
          height: 3px;
          background: $gray-dark-color;
          transform: translateX(-50%);
        }

        &[data-popper-placement="top-end"] {
          padding: 50px 21px 98px;
        }

        .img-box {
          display: none;
        }

        .btn-close {
          position: absolute;
          top: 20px;
          right: 20px;
          width: 24px;
          height: 24px;
          border: 1px solid $gray-medium-color;
          border-radius: 100%;
          background-size: 11px 11px;
          padding: 0;
        }

        .dropdown-item {
          border-radius: 100px;
          padding: 16px 32px;
          line-height: 19.6px;

          +.dropdown-item {
            margin-top: 2px;
          }

          &:hover,
          &.active,
          &:active {
            color: $white;
            background-color: $primary-color-1;
            font-weight: 700;

            a {
              transform: unset;
              transition: none;
              color: $white;
            }
          }
        }
      }
    }
  }

  .select-dropdown-2 {
    position: absolute;
    top: 10px;
    right: 10px;

    .select-dropdown-btn {
      width: 24px;
      height: 24px;
    }
  }

  .select-dropdown-menu {
    position: fixed !important;
    bottom: 0 !important;
    top: unset !important;
    width: 100%;
    left: 0 !important;
    padding: 50px 20px;
    margin: 0;
    border-radius: 24px 24px 0 0;
    transform: unset !important;
    box-shadow: 0px -8px 20px 0px rgba(103, 0, 0, 0.1215686275);

    .list-box {
      margin: 0;
      max-height: 230px;
      overflow: auto;
    }

    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 50%;
      width: 30px;
      height: 3px;
      background: $gray-dark-color;
      transform: translateX(-50%);
    }

    &[data-popper-placement="top-end"] {
      padding: 50px 21px 98px;
    }

    .img-box {
      display: none;
    }

    .btn-close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 24px;
      height: 24px;
      border: 1px solid $gray-medium-color;
      border-radius: 100%;
      background-size: 11px 11px;
      padding: 0;
    }

    .dropdown-item {
      border-radius: 100px;
      padding: 16px 32px;
      line-height: 19.6px;

      +.dropdown-item {
        margin-top: 2px;
      }

      &:hover,
      &.active,
      &:active {
        color: $white;
        background-color: $primary-color-1;
        font-weight: 700;

        a {
          transform: unset;
          transition: none;
          color: $white;
        }
      }
    }
  }

  .mobile-search-input {
    position: relative;
    margin-bottom: 1rem;

    .create-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
    }
  }

  .freelancer-client-table {
    .table {
      tbody {
        tr {
          padding: 24px 20px 20px 16px;

          td {
            &:first-child {
              padding-block: 0 16px;

              .responsive-span {
                display: none;
              }
            }

            &:has(.responsive-box > span:empty) {
              .responsive-span {
                margin: 0;
              }
            }

            +td {
              padding-top: 16px !important;
              border-top: 1px solid $gray-dark-color !important;
            }

            &:last-child {
              border-top: unset !important;
            }

            .responsive-span {
              font-size: 12px;
              font-weight: 500;
              line-height: 16.8px;
              margin-bottom: 8px;
            }

            .responsive-box {
              margin-top: 0;

              >span {
                margin: 0;
                font-weight: 500;
                font-size: 12px;
                line-height: 16.8px;
                color: #433b55;
              }

              .initial-letter {
                height: 40px;
                width: 40px;

                span {
                  font-size: 16px !important;
                  font-weight: 700 !important;
                  line-height: 20.16px !important;
                }

                ~.responsive-box {
                  margin-top: 0;

                  h6 {
                    margin: 0;
                  }

                  span {
                    margin-top: 8px;
                  }
                }
              }
            }
          }
        }
      }

      &.business-table {
        tbody {
          tr {
            transition: all 0.2s ease-in-out;

            &:hover {
              box-shadow: 0px 4px 40px 0px #0000001A;
            }

            .hover-link {
              &:has(~.title .responsive-box span:empty) {
                padding-bottom: 0;
              }
            }

            td {
              .user-name-box {
                display: flex;
                align-items: center;
                gap: 13px;
              }
            }
          }
        }
      }
    }
  }

  .all-invoice {
    &.project-draft-tab {
      .draft-table {
        &.project-all-invoice-table {
          .table {
            tbody {
              tr {
                td {
                  display: none;

                  &.invoice-number,
                  &.invoice-date,
                  &.amount-header,
                  &.responsive-dropdown {
                    display: block;
                  }

                  &:nth-child(4),
                  &.invoice-date {
                    .responsive-span {
                      display: block;
                    }
                  }

                  &.amount-header {
                    padding-bottom: 0;
                  }

                  &.invoice-date {
                    top: 32px;
                  }

                  &.invoice-number {
                    padding-bottom: 11px;

                    .responsive-box {
                      .text {
                        font-size: 13px;
                        font-weight: 600;
                        line-height: 16.38px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .table {
    tbody {
      tr {
        td {
          &:has(.responsive-box span:empty) {
            display: none;
          }
        }
      }
    }
  }

  .offcanvas.full-offcanvas-theme .offcanvas-body .pdf-box:has(~ .invoice-action) {
    height: calc(100vh - 220px);

    @media (max-height: 820px) and (min-height: 680px) {
      height: calc(100vh - 205px);
    }
  }

  .offcanvas.full-offcanvas-theme .offcanvas-body .pdf-box:has(~ .confirm-invoice-approval) {
    height: calc(100vh - 280px);

    @media (max-height: 820px) and (min-height: 680px) {
      height: calc(100vh - 265px);
    }
  }

  .offcanvas.full-offcanvas-theme .offcanvas-body .pdf-box:has(~ .confirm-invoice-approval textarea) {
    height: calc(100vh - 390px);

    @media (max-height: 820px) and (min-height: 680px) {
      height: calc(100vh - 375px);
    }
  }

  .offcanvas.full-offcanvas-theme .offcanvas-body .invoice-action {
    position: relative;
    inset: unset;
  }

  .offcanvas.full-offcanvas-theme .footer-buttons {
    position: relative;
    inset: unset;
  }
}

@media (max-width: 480px) {
  .money-main {
    .money-content {
      .money-img {
        min-width: 100%;
      }
    }
  }

  .juuli-account {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }

  .log-in-section {
    .login-main {
      .social-media {
        margin-top: 0;
        padding-bottom: 0;
        gap: 10px;
      }
    }
  }

  .verification-form,
  .custom-form-w {
    .otp-code {
      gap: 4px !important;

      .otp-field {
        width: 64px;
        height: 64px;

        border-radius: 6px;
      }
    }
  }

  .custom-width-card {

    .custom-w,
    .social-media {
      width: 100%;
    }
  }

  .custom-form-w {
    width: 100%;
  }

  .mx-6 {
    margin-inline: 30px;
  }

  .page-wrapper {
    .page-body-wrapper {
      .page-body {
        padding-inline: 0;
      }
    }
  }

  .invalid-note,
  .info-note,
  .success-note {
    width: auto;
  }

  .pagination-box {
    justify-content: center;
    gap: 10px;
    flex-direction: column-reverse;

    .pagination-info {
      justify-content: center;
    }
  }
}

@media (max-width: 459px) {

  .verification-form,
  .custom-form-w {
    .otp-code {
      .otp-field {
        width: 60px;
        height: 60px;
      }
    }
  }
}

@media (max-width: 429px) {
  .offcanvas {
    &.invoice-detail-modal {
      .footer-buttons {
        .btn {
          img {
            display: none;
          }
        }
      }
    }
  }

  .verification-form,
  .custom-form-w {
    .otp-code {
      .otp-field {
        width: 53px;
        height: 53px;
      }
    }
  }
}

@media (max-width: 379px) {

  .verification-form,
  .custom-form-w {
    .otp-code {
      .otp-field {
        width: 50px;
        height: 50px;
      }
    }
  }
}

@media (max-width: 375px) {
  .custom-dropdown {
    .dropdown-toggle {
      font-size: 16px;
    }
  }

  .page-header {
    .page-title {
      overflow: hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      width: 155px !important;
    }
  }
}

@media (max-width: 359px) {
  .log-in-section {
    .login-main {
      .social-media {
        li {
          width: 100%;

          a {
            position: relative;
            justify-content: center;
            padding: 10px;

            img {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 7px;
            }
          }
        }
      }
    }
  }

  .verification-form,
  .custom-form-w {
    .otp-code {
      .otp-field {
        width: 43px;
        height: 43px;
      }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 3) and (max-width: 430px) and (min-device-width: 375px) and (orientation: portrait) {
  .offcanvas.theme-offcanvas-2 .offcanvas-body {
    max-height: calc(100vh - 167px - 87px);

    &:has(.invoice-action) {
      max-height: unset;
    }

    &:has(.confirm-invoice-approval) {
      max-height: unset;
    }
  }

  .log-in-section .login-main {
    justify-content: unset;
    gap: 75px;
  }

  .offcanvas {
    &.full-offcanvas-theme {
      .offcanvas-body {
        .pdf-box {
          &:has(~ .invoice-action) {
            height: calc(100vh - 250px);
          }
        }
      }
    }
  }
}

@media (-webkit-transition) {
  @media (max-width: 575.98px) and (max-height: 850px) {
    .auth-business {
      padding-bottom: 60px;
    }

    .business-profile {
      height: calc(100vh - 98px - 178px - 50px - 48px) !important;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 3) and (max-width: 500px) and (min-device-width: 390px) and (orientation: portrait) {
  .offcanvas {
    &.full-offcanvas-theme {
      .offcanvas-body {
        .pdf-box {
          &:has(~.confirm-invoice-approval) {
            height: calc(100vh - 350px) !important;
          }
        }

        .pdf-box:has(~.confirm-invoice-approval) {
          height: calc(100vh - 350px) !important;
        }
      }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 3) and (max-width: 480px) and (min-device-width: 400px) and (orientation: portrait) {
  .offcanvas {
    &.full-offcanvas-theme {
      .offcanvas-body {
        .pdf-box {
          &:has(~.confirm-invoice-approval) {
            height: calc(100vh - 600px) !important;
          }
        }
      }
    }
  }
}

@media screen and (min-device-width: 575.98px) and (min-device-height: 840px) and (orientation: portrait) {
  .page-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links {
    padding: 0 20px 70px !important;
  }
}

/**=====================
     62. Responsive CSS Ends
==========================**/