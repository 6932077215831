/**=====================
  Offcanvas CSS starts
==========================**/
.offcanvas {
  --bs-offcanvas-border-color: unset;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  box-shadow: 0 0 8px rgba($dark-color, 0.4);

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  .offcanvas-body {
    overflow-x: hidden;
  }

  &.offcanvas-bottom {
    min-height: 52vh;
    height: auto;
    border-radius: 24px 24px 0 0;
    box-shadow: -2px 0 25px rgba($dark-color, 0.2);

    .invoice-content {
      box-shadow: none;
      height: unset !important;
      padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    }

    .invoice-action {
      padding-inline: 0;

      li {
        font-size: 12px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    h2 {
      font-size: 20px;
      line-height: 28px;
    }

    .footer-buttons {
      .btn {
        font-size: 13px;
        padding: 7px;

        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    .invoice-data {
      li {
        span {
          font-size: 13px;
          min-width: 90px;
        }

        p {
          @media (max-width:575px) {
            font-size: 13px;
          }

          small {
            word-break: break-all;

            @media (max-width:575px) {
              font-size: 12px;
              font-weight: 500;
              line-height: 16.8px;
            }
          }
        }
      }
    }
  }

  &.delete-confirm-modal,
  &.invited-modal {
    &.offcanvas-bottom {
      top: unset !important;
      left: unset !important;
      transform: unset !important;
      bottom: 0;

      h2 {
        margin-bottom: 40px;
      }
    }
  }

  &.invited-modal {
    &.offcanvas-bottom {
      h2 {
        margin-top: 10px;
      }

      .btn-close {
        top: 30px;
        right: 30px;
      }

      .footer-buttons {
        .btn {
          font-size: 14px;
          padding: 12px 7px;
        }
      }
    }
  }

  &.profile-setting,
  &.add-client,
  &.finance-modal {
    box-shadow: -4px 0px 40px 0px #430B0B33;
    padding: 62px 114px;
    width: 550px !important;

    .offcanvas-header {
      padding: 0;
      padding-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));

      .offcanvas-title {
        font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 700;
        line-height: 30px;
      }

      .btn-close {
        border: 1px solid $gray-dark-color;
        border-radius: 100%;
        width: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        height: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));

        &:hover,
        &:focus,
        &:active {
          box-shadow: none;
          outline: none;
        }
      }
    }

    .offcanvas-body {
      max-height: calc(100vh - 150px - 30px - 88px - 20px);
    }

    .offcanvas-box {
      display: flex;
      justify-content: space-between;
      height: 100%;
      gap: 30px;

      h4 {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 700;
        line-height: 30px;
        margin-bottom: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));

        span {
          width: calc(28px + (30 - 28) * ((100vw - 320px) / (1920 - 320)));
          height: calc(28px + (30 - 28) * ((100vw - 320px) / (1920 - 320)));
          @include flex_common;
          background-color: $gray-medium-color;
          border-radius: 100%;

          img {
            width: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            min-width: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            height: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            transform: rotate(180deg);
          }
        }
      }

      .btn-close {
        border: 1px solid $gray-dark-color;
        border-radius: 100%;
        width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
        height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
        @include flex_common;
        padding: 0;
        background-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))) calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        margin-left: auto;

        &:hover,
        &:focus,
        &:active {
          box-shadow: none;
          outline: none;
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      .btn {
        width: 100%;
        justify-content: center;
        white-space: nowrap;

        &.btn-success {
          background-color: $success-medium-color;
          border-color: $success-medium-color;
        }
      }
    }
  }

  &.profile-setting {
    max-width: 1170px !important;
    width: calc(100% - 270px) !important;

    @media (max-width:1399px) {
      width: calc(100% - 116px) !important;
    }

    @media (max-width:767px) {
      width: 100% !important;
    }

    .offcanvas-header {
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid $gray-dark-color;
      margin-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
      padding: 0 !important;

      .offcanvas-title {
        width: 100%;
      }

      .btn-close {
        position: absolute;
        right: 30px;
        top: 30px;
      }

      .profile-tab {
        border: none;
        gap: 30px;
        justify-content: space-between;
      }
    }

    .offcanvas-body {
      .profile-main {
        .profile-body {
          .setting-main {
            padding-top: 10px;
            margin-bottom: 30px;

            .signed-badge {
              color: $success-dark-color;
              background-color: $success-light-color !important;
            }

            h6 {
              margin-bottom: 16px;
              margin-top: 24px;
              font-size: 16px;
              font-weight: 700;
              line-height: 1.3;
            }

            .transaction-detail {
              .transaction-list {
                min-height: 8vh;
                height: auto;
                overflow: inherit;
                max-height: unset;
                margin: 0;

                .transaction-item {
                  .btn {
                    white-space: nowrap;
                  }

                  .select-dropdown-2 {
                    @media (max-width: 575px) {
                      position: relative;
                      inset: unset;
                    }
                  }
                }
              }
            }

            .form-group {
              .toggle-password {
                top: 36px;
              }
            }
          }
        }
      }
    }

    .profile-tab {
      .nav-item {
        .nav-link {
          padding: 11px 0 19px;
          padding-inline: 0;
        }
      }
    }

    .profile-box {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-block: 20px;

      .profile-image-box {
        position: relative;

        .profile-image {
          border-radius: 100%;
          border: 1px solid $white;
          height: calc(60px + (100 - 60) * ((100vw - 320px) / (1920 - 320)));
          width: calc(60px + (100 - 60) * ((100vw - 320px) / (1920 - 320)));
          position: relative;
          box-shadow: 0 0 8px rgba($dark-gray, 0.1);

          img {
            height: 100%;
            width: 100%;
          }
        }

        .user-profile-image {
          width: 100px;
          height: 100px;
          object-fit: cover;
          border-radius: 100%;
          border: 4px solid #fff;
          box-shadow: 0px 2px 8px 0px #391E1E1F;
        }

        .upload-image {
          position: absolute;
          bottom: 0;
          right: 0;
          border-radius: 100%;
          height: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
          width: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
          @include flex_common;
          background-color: $white;
          border: 1px solid $gray-dark-color;
          cursor: pointer;

          img {
            height: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            width: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          }
        }
      }

      h3 {
        font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 700;
        line-height: 1.3;
        color: $secondary-color-4;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        color: $gray;
        margin: 0;
      }
    }
  }

  &.invoice-detail-modal {
    .offcanvas-header {
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid $gray-dark-color;
      margin-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
      padding: 0 !important;

      .offcanvas-title {
        width: 100%;
      }

      .btn-close {
        position: absolute;
        right: 30px;
        top: 30px;
      }

      .profile-tab {
        border: none;
        gap: 30px;
        justify-content: space-between;
      }
    }

    .right-box-shadow {
      @media (max-width:1199px) {
        display: none;
      }
    }

    .invoice-data {
      display: flex;
      flex-direction: column;
      gap: calc(15px + (17 - 15) * ((100vw - 320px) / (1440 - 320)));
      padding: 0;
      margin-top: 69px;
      height: unset;
      overflow: auto;

      @media (max-width:767.98px) {
        margin-top: 60px;
        padding-inline: 30px;
        height: calc(100vh - 165px - 75px - 96px - 69px);

        @media (max-height: 750px) {
          height: calc(100vh - 87px - 40px - 99px - 96px - 69px - 95px);
        }
      }

      @media (max-width:575px) {
        padding-inline: 30px !important;
        height: calc(100vh - 90px - 40px - 85px - 96px - 69px - 49px);
      }
    }

    .offcanvas-body {
      .invoice-content {
        width: 100%;
        padding: 80px 0 84px 85px;
        max-width: 774px;
        position: relative;
        display: block;
        place-items: unset;

        @media (max-width:1440.98px) and (min-width: 991.98px) {
          padding: 80px 0 84px calc(60px + (85 - 60) * ((100vw - 991px) / (1441 - 991)));
          max-width: calc(594px + (774 - 594) * ((100vw - 991px) / (1441 - 991)));
        }

        @media (max-width:991.98px) {
          max-width: 100%;
        }

        @media (max-width:767.98px) {
          padding: 0;
          height: 100% !important;
        }

        @media (max-width:575.98px) {
          padding-top: 0 !important;
        }

        >div {
          height: 100%;
          overflow: auto;
          display: grid;
          place-items: center;

          @media (max-width:767.98px) {
            display: block;
            place-items: unset;
            padding-block: 52px 85px;
          }
        }

        .invoice-right-part {
          width: 100%;
          display: block;
          padding-right: 117px;
          height: auto;
          margin-bottom: 15px;

          @media (max-width:1440.98px) and (min-width: 991.98px) {
            padding-right: calc(57px + (117 - 57) * ((100vw - 991px) / (1441 - 991)));
          }

          @media (max-width:767.98px) {
            padding: 0;
          }
        }

        .footer-buttons {
          height: auto;
          display: flex;
          place-items: unset;
          overflow: unset;
          inset: unset;
          padding: 0 98px 40px 64px;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          margin: 0;

          @media (max-width: 1440.98px) and (min-width: 991.98px) {
            padding-right: calc(57px + (98 - 57) * ((100vw - 991px) / (1441 - 991)));
          }

          &:has(.pay-btn) {
            padding: 0 72px 40px 36px;

            @media (max-width:1440.98px) and (min-width: 991.98px) {
              padding: 0 calc(36px + (72 - 36) * ((100vw - 991px) / (1441 - 991))) 40px 36px;
            }

            @media (max-width: 767.98px) {
              padding: 20px 13px;
              margin: 0;
            }
          }

          @media (max-width:767.98px) {
            margin-block: 20px;
            padding-bottom: 0;
            padding-inline: 24px;
            gap: 8px;

            .btn {
              font-size: 14px;
              padding: 13px;
              font-weight: 700;
              line-height: 17.64px;
            }
          }

          @media (max-width: 575.98px) {
            padding-inline: 13px;
            padding-bottom: 14px;
          }
        }
      }
    }

    .invoice-detail {
      padding: 0;
      margin-top: 74px;

      ul {
        gap: 37px;

        li {
          &.completed:not(:last-child) {
            &:before {
              height: calc(100% + 37px);
            }
          }

          &::before {
            top: unset;
          }

          span {
            width: unset;
          }

          &.reject {
            &::before {
              content: "";
              background-color: $title;
              border-radius: 100%;
              height: 20px;
              width: 20px;
              position: absolute;
              top: 0;
              left: -29px;
              z-index: 3;
              background-image: url("../../images/svg/time.svg");
              background-position: center;
              background-size: 12px 12px;
              background-repeat: no-repeat;
              border: 1px solid $gray-dark-color;
            }

            >span,
            small {
              color: $title;
            }
          }
        }
      }
    }

    .invoice-action {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      width: 100%;
      border-width: 1px 0 1px 0;
      border-style: solid;
      border-color: $gray-dark-color;
      padding: 16px 13px;
      margin-block: 40px 0;

      @media (max-width: 767.98px) {
        padding: 19px 26px;
      }

      li {
        @include flex_common;
        gap: 8px;
        cursor: pointer;
        color: $title;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 700;
        line-height: 17.64px;

        @media (max-width:575px) {
          font-size: 13px !important;
          line-height: 16.38px !important;
        }

        &:hover {
          color: $secondary-color-4;
        }

        img {
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  &.invoice-detail-modal {
    width: calc(100% - 270px);
    padding: 0;
    margin-left: 270px;
    box-shadow: none;

    @media (max-width: 1440.98px) {
      width: calc(100% - 116px);
      margin-left: 116px;
    }

    @media (max-width: 767.98px) {
      width: 100%;
      margin: 0;
      height: calc(100vh - 72px);
    }

    .right-box-shadow {
      left: 0;
      z-index: 1;
      transform: unset;
      border: 0;
    }

    .invoice-content {
      padding-block: 0;
      padding-inline: 85px 117px;
      display: grid;
      place-items: center;
      height: 100%;

      textarea {
        &.form-control {
          min-height: 47px;
        }
      }
    }

    .footer-buttons {
      .btn {
        @media (max-width: 575.98px) {
          padding: 13px;
          font-size: 14px;
          font-weight: 700;
          line-height: 17.64px;
        }
      }
    }

    .status-content {
      padding: 40px 70px;
      background-color: #FAF7FA;
      display: flex;
      width: calc(100% - 774px);
      align-items: center;

      @media (max-width:1440.98px) and (min-width: 991.98px) {
        width: calc(100% - calc(594px + (774 - 594) * ((100vw - 991px) / (1441 - 991))));
        padding: 40px calc(24px + (70 - 24) * ((100vw - 991px) / (1441 - 991))) 40px calc(0px + (70 - 0) * ((100vw - 991px) / (1441 - 991)));
      }

      @media (max-width: 767.98px) {
        width: 100%;
      }

      .invoice-detail {
        ul {
          &:after {
            background-color: $success-medium-color;
            border-radius: 100%;
            height: 10px;
            width: 10px;
            position: absolute;
            top: 5px;
            left: 6px;
            z-index: 2;
          }

          &:before {
            top: 5px;
          }
        }

        ul:not(:has(li:first-child.active)) {
          &:after {
            content: "";
          }
        }
      }

      h2 {
        margin-top: 0 !important;
      }

      .invoice-left-part {
        margin: 0;
      }
    }

    .btn-close {
      border: 1px solid $gray-dark-color;
      border-radius: 100%;
      width: 44px;
      height: 44px;
      cursor: pointer;
      position: absolute;
      right: 104px;
      top: 80px;
      z-index: 5;
      padding: 0;

      @media (max-width:1399px) {
        right: 60px;
      }

      @media (max-width:1024px) {
        right: 45px;
      }

      &:hover,
      &:focus,
      &:active {
        box-shadow: none;
        outline: none;
      }
    }

    h2 {
      font-size: 26px;
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 69px !important;

      @media (max-width:767.98px) {
        padding-inline: 30px;
        font-size: 20px;
        display: block;
        margin-bottom: 60px !important;
        line-height: 25.2px;
      }

      @media (max-width:575px) {
        padding-inline: 30px;
        font-size: 20px;
        font-weight: 700;
        line-height: 25.2px;

      }

      img {
        width: 45px;
        height: 45px;
      }
    }

    .modal-note {
      margin-block: 48px 16px;

      h6 {
        font-size: 14px;
        font-weight: 700;
        line-height: 17.64px;
        margin-bottom: 14px;
        color: $black;
      }

      .text {
        font-size: 14px;
        font-weight: 700;
        line-height: 19.6px;
        color: $title;
        margin-bottom: 14px;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 19.6px;
        margin: 0;
        color: $dark-color;
      }
    }

    .footer-buttons {
      @include flex_common;
      gap: 12px;
      flex-wrap: nowrap;
      position: absolute;
      bottom: 40px;
      width: calc(100% - 64px);
      left: 64px;
      padding-right: 117px;

      .btn {
        width: 100%;
        min-width: unset;
        white-space: nowrap;

        &.btn-gray {
          color: $dark-gray !important;
        }

        img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 17px;
          width: 32px;
          height: auto;
          padding: 4px;

          @media (max-width: 575.98px) {
            display: none;
          }
        }
      }

      &:has(.pay-btn) {
        .btn {
          img {
            @media (max-width:1440.98px) and (min-width: 991.98px) {
              left: calc(5px + (17 - 5) * ((100vw - 991px) / (1441 - 991)));
              width: calc(29px + (32 - 29) * ((100vw - 991px) / (1441 - 991)));
            }

            @media (max-width: 991.98px) and (min-width: 575.98px) {
              left: calc(5px + (17 - 5) * ((100vw - 575px) / (992 - 575)));
              width: calc(29px + (32 - 29) * ((100vw - 575px) / (992 - 575)));
            }

            @media (max-width: 575.98px) {
              display: none;
            }
          }
        }
      }
    }
  }

  &.theme-offcanvas-2 {
    max-width: 774px;
    margin-left: 270px;
    width: calc(100% - 270px);
    border: none;
    box-shadow: -4px 0px 40px 0px rgba(67, 11, 11, 0.2);
    display: block;

    &:has(.setting-main) {
      .theme-offcanvas-box {
        justify-content: center;
      }

      .offcanvas-body {
        height: calc(100vh - 38px - 43px) !important;
      }
    }

    @media (max-width: 1440.98px) {
      margin-left: 116px;
      width: calc(100% - 116px);
    }

    @media (max-width: 767.98px) {
      margin: 0;
      width: 100%;
    }

    &.max-offcanvas {
      max-width: 1170px;
      width: calc(100% - 270px);
    }

    .business-table {
      padding: 0;
    }

    .client-offcanvas-box {
      border: 1px solid $gray-dark-color;
      border-radius: 12px;
      padding: 24px 28px 20px;
      position: relative;
      margin-bottom: 40px;

      @media (max-width: 767.98px) {
        margin-right: 0;
        padding-inline: 18px;
      }

      .details-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 11px;
        line-height: 17.64px;

        @media (max-width: 767.98px) {
          font-size: 13px;
          font-weight: 600;
          line-height: 16.38px;
          margin-bottom: 13px;
        }
      }

      .client-edit-icon {
        @include juuli-size(38px, 38px);
        background-color: $gray-medium-color;
        @include juuli-flex(flex, center, center);
        border-radius: 50%;
        padding: 7px;
        cursor: pointer;
        position: absolute;
        top: 24px;
        right: 24px;

        img {
          height: 16px;
        }
      }

      .title {
        margin-bottom: 16px;
        line-height: 20.16px;
        font-weight: 700;
      }

      .freelancer-details-box {
        h5 {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 600;
          line-height: 17.64px;
        }

        p {
          margin: 0;
          font-size: 14px;
          font-weight: 500;
          line-height: 19.6px;
          margin-top: 6px;

          &:empty {
            display: none;
          }
        }
      }

      .common-f-flex {
        margin-bottom: 27px;
        gap: 19px;

        .initial-letter {
          width: 50px;
          height: 50px;
          border: 1px solid $gray-medium-color;
        }

        p {
          margin-block: 6px 0;
          font-size: 12px;
          font-weight: 500;
          line-height: 16.8px;
          color: $gray;
        }

        .juuli-d-flex {
          display: flex;
          gap: 8px;
          align-items: center;

          ~p {
            color: var(--gray);
            font-size: 12px;
            font-weight: 500;
          }
        }

        >div {
          span {
            font-size: 18px;
            font-weight: 600;
            line-height: 22.68px;
          }
        }
      }

      .invoice-finance {
        margin-top: 40px;

        .invoice-data {
          gap: 24px;
          padding: 0;
          border: none;

          li {
            display: grid;
            grid-template-columns: 1fr 2fr;

            p {
              font-size: 14px;
              font-weight: 500;
              line-height: 19.6px;
            }
          }
        }
      }

      .initial-letter {
        span {
          font-size: 16px !important;
          line-height: 20.16px !important;
        }
      }

      .edit-btn {
        width: 38px;
        height: 38px;
        justify-content: center;
        padding: 0;
        background: $gray-medium-color;
        line-height: 1;
        font-size: unset;
        gap: 0;
        border-radius: 100%;
        position: absolute;
        top: 24px;
        right: 32px;
      }
    }

    &.upload-document {
      .theme-offcanvas-box {
        @media (max-width: 1440.98px) {
          padding: 108px 0 calc(32px + (40 - 32) * ((100vw - 575px) / (1441 - 575))) calc(30px + (60 - 30) * ((100vw - 575px) / (1441 - 575)));
        }

        @media (max-width: 575px) {
          padding: calc(23px + (25 - 23) * ((100vw - 320px) / (576 - 320))) 0 calc(20px + (35 - 20) * ((100vw - 320px) / (576 - 320))) calc(20px + (35 - 20) * ((100vw - 320px) / (576 - 320)));
        }
      }
    }

    .filter-dropdown-box {
      &:has(p.placeholder-text) {
        .select-btn {
          padding: 4px;
          background-image: none;
        }
      }

      .placeholder-text {
        background-color: $white;
        width: 100%;
        text-align: left;
        padding: 7px calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 100px;
        display: flex;
        align-items: center;
        position: relative;
        gap: calc(9px + (28 - 9) * ((100vw - 320px) / (1920 - 320)));

        &::before {
          content: url(../../images/svg/icon_arrow_down.svg);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 16px;
        }

        .name {
          font-size: 14px;
          color: $dark-gray;
          font-weight: 600;
          display: inline-flex;
          align-items: center;
          gap: 8px;
        }

        .mail {
          font-size: 14px;
          font-weight: 500;
          color: $gray;
        }
      }
    }

    .theme-offcanvas-box {
      padding: 80px 0 87px 60px;
      display: flex;
      align-items: center;
      height: 100%;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 1440.98px) {
        padding: calc(34px + (80 - 34) * ((100vw - 575px) / (1441 - 575))) 0 87px calc(30px + (60 - 30) * ((100vw - 575px) / (1441 - 575)));
      }

      @media (max-width: 575.98px) {
        padding: 20px 0 79px 20px;
        display: block;
      }
    }

    .offcanvas-header {
      padding: 0;
      width: 100%;
      margin-bottom: 38px;
      margin-right: 104px;

      @media (max-width: 1440.98px) {
        margin-right: calc(30px + (104 - 30) * ((100vw - 575px) / (1441 - 575)));
      }

      @media (max-width: 575.98px) {
        flex-direction: row-reverse;
        justify-content: flex-end;
        gap: 12px;
        margin-bottom: 28px;
        margin-right: 20px;
      }

      .offcanvas-title {
        font-size: 32px;
        font-weight: 700;
        color: $secondary-color-4;

        @media (max-width: 1440.98px) {
          font-size: calc(24px + (32 - 24) * ((100vw - 575px) / (1441 - 575)));
        }

        @media (max-width: 575.98px) {
          font-size: 20px;
          font-weight: 700;
          line-height: 25.2px;
          width: auto;
        }
      }

      .btn-close {
        display: none;

        @media (max-width: 575.98px) {
          display: block;
          position: relative;
          inset: unset;
          background-color: $gray-medium-color;
          border: none;
          background-size: 18px 18px;
          width: 32px;
          height: 32px;
          background-image: url(../../images/svg/red-right-arrow.svg);
          transform: scale(-1);
          flex-shrink: 0;
        }
      }
    }

    .invoice-action {
      @include flex_common;
      padding-block: 16px;
      border-block: 1px solid $gray-dark-color;
      margin-bottom: 40px;

      @media (max-width: 767.98px) {
        margin-bottom: 20px;
      }

      li {
        @include flex_common;
        gap: 4px;
        width: 50%;
        font-size: 14px;
        font-weight: 700;
        line-height: 17.64px;

        img {
          width: 32px;
          height: 32px;
          padding: 7px;
        }
      }
    }

    .btn-close {
      width: 44px;
      height: 44px;
      border: 1px solid $gray-dark-color;
      border-radius: 100%;
      padding: 0;
      margin: 0;
      position: absolute;
      top: 80px;
      z-index: 0;
      background-color: $white;
      right: 104px;

      @media (max-width: 1440px) {
        right: calc(30px + (104 - 30) * ((100vw - 575px) / (1441 - 575)));
        width: calc(32px + (44 - 32) * ((100vw - 575px) / (1441 - 575)));
        height: calc(32px + (44 - 32) * ((100vw - 575px) / (1441 - 575)));
        top: calc(32px + (80 - 32) * ((100vw - 575px) / (1441 - 575)));
      }

      @media (max-width: 575.98px) {
        display: none;
      }
    }

    .offcanvas-height {
      max-height: calc(100vh - 80px - 46px - 27px - 38px - 44px - 40px);
      height: 100%;
      overflow: auto;
      padding-right: 104px;

      @media (max-width: 1440.98px) {
        padding-right: calc(30px + (104 - 30) * ((100vw - 575px) / (1441 - 575)));
      }

      @media (max-width:767.98px) {
        @media (max-height:850px) {
          height: calc(100vh - 10px - 25px - 36px - 38px - 44px - 40px - 23px - 80px);
        }
      }

      @media (max-width:575.98px) {
        height: calc(100vh - 10px - 25px - 36px - 38px - 44px - 40px - 23px);
        display: block;
        padding-right: 0;
      }
    }

    .offcanvas-body {
      padding: 0 104px 0 0;
      position: unset;
      display: flex;
      max-height: calc(100vh - 272px);
      height: auto;
      overflow: hidden auto;

      @media (max-width: 1440.98px) {
        padding-right: calc(30px + (104 - 30) * ((100vw - 575px) / (1441 - 575)));
        max-height: calc(100vh - calc(194px + (272 - 194) * ((100vw - 575px) / (1441 - 575))));
      }

      @media (max-width: 575.98px) {
        padding-right: 20px;
        max-height: 100%;
        height: calc(100vh - 167px);

        @media (max-height: 800px) {
          max-height: calc(100vh - 167px - 87px);
        }
      }

      >form {
        width: 100%;
      }
    }

    .setting-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 20.16px;
      margin-bottom: 24px;
      color: #252133;
    }

    .offcanvas-footer {
      position: absolute;
      bottom: 40px;
      left: 0;
      padding-inline: 64px 0;
      width: calc(100% - 104px);
      display: flex;
      align-items: center;
      gap: 26px;

      @media (max-width: 1440.98px) {
        gap: calc(10px + (26 - 10) * ((100vw - 575px) / (1441 - 575)));
        padding-inline: calc(30px + (64 - 30) * ((100vw - 575px) / (1441 - 575))) 0;
        bottom: calc(30px + (40 - 30) * ((100vw - 575px) / (1441 - 575)));
        width: calc(100% - calc(30px + (104 - 30) * ((100vw - 575px) / (1441 - 575))));
      }

      @media (max-width: 575.98px) {
        width: 100%;
        gap: 7px;
        bottom: 0;
        padding: 14px 20px 27px;
        background-color: $white;
      }

      .btn {
        width: 100%;
        font-weight: 700;
        display: block;
        font-size: 14px;
        padding: 13px;
        line-height: 17.64px;

        &:has(img.media) {
          display: flex;
        }
      }
    }

    .invoice-data {
      border: 1px solid $gray-dark-color;
      border-radius: 12px;
      padding: 20px;

      &.project-data {
        padding: calc(20px + (33 - 20) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 40px;
      }

      @media (max-width:575.98px) {
        padding: 25px;
        gap: 26px;

        li {
          flex-direction: column;
          align-items: unset;
          gap: 9px;

          span {
            font-size: 13px;
            font-weight: 600;
            line-height: 16.9px;
            min-width: unset;
          }
        }
      }

      p {
        word-break: break-word;
        gap: 4px;

        @media (max-width:575.98px) {
          flex-direction: column;
          align-items: unset;
          margin-top: 0;
          line-height: 19.6px;
          gap: 2px;
        }
      }
    }

    .footer {
      position: absolute;
      bottom: 40px;
      left: 64px;
      display: flex;
      align-items: center;
      width: calc(100% - 64px - 104px);
      gap: 26px;

      @media (max-width: 1440.98px) {
        gap: calc(14px + (26 - 14) * ((100vw - 575px) / (1441 - 575)));
        left: calc(30px + (64 - 30) * ((100vw - 575px) / (1441 - 575)));
        width: calc(100% - calc(30px + (64 - 30) * ((100vw - 575px) / (1441 - 575))) - calc(30px + (104 - 30) * ((100vw - 575px) / (1441 - 575))));
      }

      @media (max-width: 575.98px) {
        left: calc(15px + 15 * (100vw - 320px) / 256);
      }

      button {
        white-space: nowrap;
        width: 100%;
      }
    }

    .box-flex {
      display: flex;
      align-items: center;
      justify-content: end;

      .form-check {
        .form-check-input {
          width: 17px;

          &:before {
            left: 4px;
          }
        }

        label {
          font-weight: 700;
          color: $dark-gray;
          font-size: 14px;
        }
      }

      .right-box {
        button {
          font-weight: 700;
          color: $dark-gray !important;
          background-color: $gray-medium-color;
          border-color: transparent;
        }
      }
    }
  }

  &.new-freelancer-offcanvas {
    max-width: 775px;
    width: 100% !important;

    .filter-dropdown-box {
      max-width: unset;
      min-width: unset;

      .select-btn {
        font-size: 14px;

        &:has(.create-btn) {
          padding: 10px 24px;
          width: 100%;
          background-position: center right 130px;

          @media (max-width: 575.98px) {
            padding-right: 45px;
            background-position: center right 60px;
          }
        }

        .create-btn {
          height: auto;
          padding: 7px 16px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 4px;
          background-color: $gray-light-color;
          border-color: transparent;
        }
      }
    }

    .offcanvas-body {
      padding: 0;

      @media (max-width:575.98px) {
        display: block;
        height: calc(100vh - 38px - 40px - 122px);
        overflow: auto;

        @media (max-height: 750px) {
          height: calc(100vh - 38px - 40px - 122px - 70px);

          form {
            .footer {
              bottom: 70px;
            }
          }
        }
      }

      .offcanvas-height {
        padding: 0 104px 0 0;
        height: calc(100vh - 145px - 45px - 38px - 43px - 20px);
        overflow: auto;

        @media (max-width: 1440.98px) {
          padding-right: calc(30px + 74 * (100vw - 320px) / 866);
        }

        @media (max-width: 767.98px) {
          padding-right: 20px;
          height: calc(100vh - 125px - 45px - 38px - 43px - 35px);
        }
      }
    }
  }

  &.edit-project-offcanvas {
    .theme-offcanvas-box {
      height: 100%;
      display: grid;
      place-items: center;
      padding-bottom: 84px;

      @media (max-width:575px) {
        align-items: unset;
      }

      >div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .offcanvas-body {
        padding: 0;
        height: unset;
        overflow: unset;
        display: block;

        .offcanvas-height {
          height: auto;
          padding-right: 104px;

          @media (max-width: 1440.98px) {
            padding-right: calc(30px + (104 - 30) * ((100vw - 575px) / (1441 - 575)));
          }

          @media (max-width:575px) {
            height: calc(100vh - 168px);
            overflow: auto;
            display: block;
            padding-right: calc(20px + 15 * (100vw - 320px) / 256);
          }
        }
      }

      .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0 104px 40px 64px;
        margin: 0;

        @media (max-width: 1440.98px) {
          padding-right: calc(30px + (104 - 30) * ((100vw - 575px) / (1441 - 575)));
        }

        @media (max-width:575px) {
          padding: 12px 24px 20px;
        }
      }
    }
  }

  &.setting-canvas {
    .offcanvas-header {
      border-bottom: none;
    }

    .offcanvas-body {
      .setting-main {
        h6 {
          margin-bottom: 24px;
          font-size: 16px;
          font-weight: 700;
          line-height: 20.16px;
        }
      }

      .setting-footer-main {
        width: calc(100% - 228px);
        bottom: 40px;

        @media (max-width: 575px) and (max-height: 750px) {
          bottom: 36px !important;
        }
      }
    }
  }

  &.client-offcanvas-detail {
    @media (max-width: 767.98px) {
      height: calc(100vh - 80px);

      ~.offcanvas-backdrop {
        opacity: 0 !important;
      }
    }

    @media (max-width: 575.98px) {
      height: calc(100vh - 72px);
    }

    .theme-offcanvas-box {
      display: block;
      height: 100%;
    }

    .offcanvas-header {
      h5 {
        margin-bottom: 0;
      }
    }

    .offcanvas-body {
      padding: 0 !important;
    }

    .client-custom-height {
      height: calc(100% - calc(32px + 48*(100vw - 575px) / 1345) - 45px - calc(30px + 74*(100vw - 575px) / 1345));
      overflow: scroll;
      padding: 0 104px 0 0;

      .table {
        padding: 0;
      }
    }

    h5 {
      margin-bottom: 16px;

      &.title {
        @media (max-width: 757.98px) {
          font-size: 13px;
          font-weight: 600;
          line-height: 16.9px;
          margin-left: 18px;
        }
      }
    }

    .client-custom-height {
      height: unset;
      overflow: unset;
      padding: 0;
    }

    .offcanvas-height {
      padding: 0 104px 0 0;
      height: calc(100vh - 97px - 45px - 38px - 43px - 20px);
      overflow: auto;

      @media (max-width: 1440.98px) {
        padding-right: calc(30px + 74 * (100vw - 320px) / 866);
      }

      @media (max-width: 767.98px) {
        padding-right: 20px;
        height: calc(100vh - 97px - 45px - 38px - 43px - 35px);
      }
    }

    .offcanvas-footer {
      padding-right: 104px;
      margin-top: 13px;
    }

    .theme-offcanvas-box {
      padding-bottom: 86px;
      display: grid;
      place-items: unset;
      align-items: center;

      @media (max-width: 767.98px) {
        padding: 52px 0 76px 20px;
        display: block;
        box-shadow: 0px -8px 20px 0px rgba(103, 0, 0, 0.122);
        border-top: 1px solid $gray-dark-color;
        border-radius: 24px 24px 0 0;

        &::before {
          position: absolute;
          content: "";
          width: 34px;
          height: 4px;
          border-radius: 100px;
          top: 8px;
          left: 50%;
          transform: translateX(-50%);
          background-color: $gray-dark-color;
        }

        .offcanvas-loader {
          border-radius: 24px 24px 0 0;
        }
      }
    }

    .offcanvas-header {
      @media (max-width: 767.98px) {
        margin-bottom: 24px;
        margin-right: 0;
        padding-right: 20px;

        ~.btn-close {
          display: none;
        }

        .offcanvas-title {
          font-size: 20px;
          font-weight: 700;
          line-height: 25.2px;
          width: 100%;
          margin-left: 10px;
        }
      }
    }

    .client-offcanvas-box {
      border: 1px solid $gray-dark-color;
      border-radius: 12px;
      padding: 24px 28px 20px;
      position: relative;
      margin-bottom: 40px;

      @media (max-width: 767.98px) {
        margin-right: 0;
        padding-inline: 18px;
      }

      .details-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 11px;
        line-height: 17.64px;

        @media (max-width: 767.98px) {
          font-size: 13px;
          font-weight: 600;
          line-height: 16.38px;
          margin-bottom: 13px;
        }
      }

      .client-edit-icon {
        @include juuli-size(38px, 38px);
        background-color: $gray-medium-color;
        @include juuli-flex(flex, center, center);
        border-radius: 50%;
        padding: 7px;
        cursor: pointer;
        position: absolute;
        top: 24px;
        right: 24px;

        img {
          height: 16px;
        }
      }

      .title {
        margin-bottom: 16px;
        line-height: 20.16px;
        font-weight: 700;
      }

      .freelancer-details-box {
        h5 {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 600;
          line-height: 17.64px;
        }

        p {
          margin: 0;
          font-size: 14px;
          font-weight: 500;
          line-height: 19.6px;
          margin-top: 6px;

          &:empty {
            display: none;
          }
        }
      }

      .common-f-flex {
        margin-bottom: 27px;
        gap: 19px;

        .initial-letter {
          width: 50px;
          height: 50px;
          border: 1px solid $gray-medium-color;
        }

        p {
          margin-block: 6px 0;
          font-size: 12px;
          font-weight: 500;
          line-height: 16.8px;
          color: $gray;
        }

        .juuli-d-flex {
          display: flex;
          gap: 8px;
          align-items: center;

          ~p {
            color: var(--gray);
            font-size: 12px;
            font-weight: 500;
          }
        }

        >div {
          span {
            font-size: 18px;
            font-weight: 600;
            line-height: 22.68px;
          }
        }
      }

      .invoice-finance {
        margin-top: 40px;

        .invoice-data {
          gap: 22px;

          li {
            display: grid;
            grid-template-columns: 1fr 2fr;

            p {
              font-size: 14px;
              font-weight: 500;
              line-height: 19.6px;
            }
          }
        }
      }

      .edit-btn {
        width: 38px;
        height: 38px;
        justify-content: center;
        padding: 0;
        background: $gray-medium-color;
        line-height: 1;
        font-size: unset;
        gap: 0;
        border-radius: 100%;
        position: absolute;
        top: 24px;
        right: 32px;
      }
    }

    .finance-table {
      padding-right: 104px;
      overflow: auto;
      max-height: calc(100vh - 80px - 86px - 46px - 15px - 212px - 40px - 23px - 16px - 50px);

      @media (max-width: 767.98px) {
        padding-right: 0;
        max-height: calc(100vh - 80px - 86px - 46px - 15px - 212px - 40px - 23px - 16px);
      }
    }

    .offcanvas-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px 104px 20px 64px;
      margin: 0;

      @media (max-width: 767.98px) {
        padding: 12px 24px 20px;
      }
    }

    .finance-table {
      .table {
        padding-top: 0;

        thead {
          &:has(th:empty) {
            display: none;
          }
        }

        tbody {
          tr {
            td {
              &:empty {
                padding: 0;
              }
            }
          }
        }
      }
    }

    .table-footer {
      margin-top: 11px;
    }
  }

  &.edit-freelancer-detail-offcanvas {
    .theme-offcanvas-box {
      display: block;
      height: 100%;

      @media (max-width: 575.98px) {
        >.w-100 {
          height: 100%;
          overflow: auto;
        }
      }
    }

    .offcanvas-header {
      h5 {
        margin-block: 0;
      }
    }

    .offcanvas-body {
      padding: 0;
      padding-right: 0;

      @media (max-width: 575.98px) {
        padding-right: 0;
        display: block;
        height: auto;
        overflow: auto;
      }
    }

    .client-custom-height {
      height: calc(100% - calc(32px + 48*(100vw - 575px) / 1345) - 45px - calc(30px + 74*(100vw - 575px) / 1345));
      overflow: scroll;
      padding: 0 104px 0 0;
    }

    h5 {
      margin-bottom: 8px;
      margin-top: 40px;
    }

    .email-box {
      label {
        margin-bottom: 0;
        display: block;
      }

      p {
        margin-block: 8px 0;
        font-weight: 500;
        font-size: 14px;
        color: #433B55;
        line-height: 19.6px;
      }
    }

    .client-custom-height {
      height: unset;
      overflow: unset;
      padding: 0;
    }

    .offcanvas-height {
      padding: 0 104px 0 0;
      height: calc(100vh - 148px - 45px - 38px - 43px - 20px);
      overflow: auto;

      @media (max-width: 1440.98px) {
        padding-right: calc(30px + 74 * (100vw - 320px) / 866);
      }

      @media (max-width: 575.98px) {
        padding-right: 24px;
        height: calc(100vh - 168px);
      }
    }

    .business-table {
      padding: 0;

      tbody {
        tr {
          @media (max-width: 575.98px) {
            padding-block: 14px;
          }

          td {
            @media (max-width: 575.98px) {
              .responsive-span {
                &.responsive-span {
                  &:empty {
                    display: none;
                  }
                }
              }

              .responsive-box {
                h6 {
                  margin-bottom: 8px;
                }
              }
            }
          }
        }
      }
    }

    .offcanvas-footer {
      padding-right: 104px;
      margin-top: 13px;
    }

    .theme-offcanvas-box {
      padding-bottom: 86px;
      display: grid;
      place-items: unset;
      align-items: center;

      @media (max-width: 767.98px) {
        padding: calc(23px + 2*(100vw - 320px) / 256) 0 calc(20px + 15*(100vw - 320px) / 256) calc(20px + 15*(100vw - 320px) / 256);
      }

      @media (max-width: 575.98px) {
        padding: 24px 0 24px 24px;
      }
    }

    .offcanvas-header {
      @media (max-width: 767.98px) {
        margin-bottom: 24px;
        margin-right: 0;

        .offcanvas-title {
          font-size: 20px;
          font-weight: 700;
          line-height: 25.2px;
          width: 100%;
        }
      }
    }

    .client-offcanvas-box {
      margin-right: 104px;
      border: 1px solid $gray-dark-color;
      border-radius: 12px;
      padding: 24px 28px 20px;
      position: relative;
      margin-bottom: 40px;

      @media (max-width: 767.98px) {
        margin-right: 0;
        padding-inline: 18px;
      }

      .details-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 11px;
        line-height: 17.64px;

        @media (max-width: 767.98px) {
          font-size: 13px;
          font-weight: 600;
          line-height: 16.38px;
          margin-bottom: 13px;
        }
      }

      .client-edit-icon {
        @include juuli-size(38px, 38px);
        background-color: $gray-medium-color;
        @include juuli-flex(flex, center, center);
        border-radius: 50%;
        padding: 7px;
        cursor: pointer;
        position: absolute;
        top: 24px;
        right: 24px;

        img {
          height: 16px;
        }
      }

      .title {
        margin-bottom: 16px;
        line-height: 20.16px;
        font-weight: 700;
      }

      .freelancer-details-box {
        h5 {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 600;
          line-height: 17.64px;
        }

        p {
          margin: 0;
          font-size: 14px;
          font-weight: 500;
          line-height: 19.6px;
          margin-top: 6px;

          &:empty {
            display: none;
          }
        }
      }

      .common-f-flex {
        margin-bottom: 27px;
        gap: 19px;

        .initial-letter {
          width: 50px;
          height: 50px;
          border: 1px solid $gray-medium-color;
        }

        p {
          margin-block: 6px 0;
          font-size: 12px;
          font-weight: 500;
          line-height: 16.8px;
          color: $gray;
        }

        .juuli-d-flex {
          display: flex;
          gap: 8px;
          align-items: center;

          ~p {
            color: var(--gray);
            font-size: 12px;
            font-weight: 500;
          }
        }

        >div {
          span {
            font-size: 18px;
            font-weight: 600;
            line-height: 22.68px;
          }
        }
      }

      .invoice-finance {
        margin-top: 40px;

        .invoice-data {
          gap: 22px;

          li {
            display: grid;
            grid-template-columns: 1fr 2fr;

            p {
              font-size: 14px;
              font-weight: 500;
              line-height: 19.6px;
            }
          }
        }
      }

      .edit-btn {
        width: 38px;
        height: 38px;
        justify-content: center;
        padding: 0;
        background: $gray-medium-color;
        line-height: 1;
        font-size: unset;
        gap: 0;
        border-radius: 100%;
        position: absolute;
        top: 24px;
        right: 32px;
      }
    }

    .finance-table {
      padding-right: 104px;
      overflow: auto;
      max-height: calc(100vh - 80px - 86px - 46px - 15px - 212px - 40px - 23px - 16px - 50px);

      @media (max-width: 767.98px) {
        padding-right: 0;
        max-height: calc(100vh - 80px - 86px - 46px - 15px - 212px - 40px - 23px - 16px);
      }
    }

    .offcanvas-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px 104px 20px 64px;
      margin: 0;

      @media (max-width: 767.98px) {
        padding: 12px 24px 20px;
      }
    }

    .finance-table {
      .table {
        padding-top: 0;

        thead {
          &:has(th:empty) {
            display: none;
          }
        }

        tbody {
          tr {
            td {
              &:empty {
                padding: 0;
              }
            }
          }
        }
      }
    }

    .table-footer {
      margin-top: 11px;
    }
  }

  &.freelancer-detail-offcanvas {
    @media (max-width: 757.98px) {
      height: calc(100vh - 80px);

      ~.offcanvas-backdrop {
        opacity: 0 !important;
      }
    }

    @media (max-width: 575.98px) {
      height: calc(100vh - 72px);
    }

    .theme-offcanvas-box {
      display: block;
      height: 100%;

      @media (max-width: 575.98px) {
        >.w-100 {
          height: 100%;
          overflow: auto;

          .offcanvas-body {
            align-items: unset;
            overflow: auto;
          }
        }
      }
    }

    .offcanvas-header {
      h5 {
        margin-bottom: 0;
      }
    }

    .offcanvas-body {
      padding: 0 !important;
    }

    .client-custom-height {
      height: calc(100vh - calc(32px + 48*(100vw - 575px) / 1345) - 155px - calc(30px + 74*(100vw - 575px) / 1345));
      overflow: auto;
      padding: 0 104px 0 0;

      @media (max-width: 767.98px) {
        height: calc(100vh - calc(32px + 48*(100vw - 575px) / 1345) - 205px - calc(30px + 74*(100vw - 575px) / 1345));
        padding-right: 20px;
      }

      @media (max-width: 575px) {
        height: unset;
      }
    }

    h5 {
      margin-bottom: 16px;

      &.title {
        @media (max-width: 757.98px) {
          font-size: 13px;
          font-weight: 600;
          line-height: 16.9px;
          margin-left: 18px;
          margin-bottom: 7px;
        }
      }
    }

    .offcanvas-height {
      padding: 0 104px 0 0;
      height: calc(100vh - 97px - 45px - 38px - 43px - 20px);
      overflow: auto;

      @media (max-width: 1440.98px) {
        padding-right: calc(30px + 74 * (100vw - 320px) / 866);
      }
    }

    .offcanvas-footer {
      padding-right: 104px;
      margin-top: 13px;
    }

    .theme-offcanvas-box {
      padding-bottom: 86px;
      display: grid;
      place-items: unset;
      align-items: center;

      @media (max-width: 767.98px) {
        padding: 52px 0px 76px 20px;
        display: block;
        box-shadow: 0px -8px 20px 0px #6700001F;
        border-top: 1px solid $gray-dark-color;
        border-radius: 24px;

        &::before {
          position: absolute;
          content: "";
          width: 34px;
          height: 4px;
          border-radius: 100px;
          top: 8px;
          left: 50%;
          transform: translateX(-50%);
          background-color: $gray-dark-color;
        }
      }
    }

    .offcanvas-header {
      @media (max-width: 767.98px) {
        margin-bottom: 24px;
        margin-right: 0;

        .btn-close {
          display: none;
        }

        .offcanvas-title {
          font-size: 20px;
          font-weight: 700;
          line-height: 25.2px;
          width: 100%;
          margin-left: 10px;
        }
      }
    }

    .client-offcanvas-box {
      border: 1px solid $gray-dark-color;
      border-radius: 12px;
      padding: 24px 28px 20px;
      position: relative;
      margin-bottom: 40px;

      @media (max-width: 767.98px) {
        margin-right: 0;
        padding: 24px 18px 20px;
        padding-inline: 18px;
      }

      .details-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 11px;
        line-height: 17.64px;

        @media (max-width: 767.98px) {
          font-size: 13px;
          font-weight: 600;
          line-height: 16.38px;
          margin-bottom: 13px;
        }
      }

      .client-edit-icon {
        @include juuli-size(38px, 38px);
        background-color: $gray-medium-color;
        @include juuli-flex(flex, center, center);
        border-radius: 50%;
        padding: 7px;
        cursor: pointer;
        position: absolute;
        top: 24px;
        right: 24px;

        @media (max-width: 767.98px) {
          top: 10px;
          right: 10px;
        }

        img {
          height: 16px;
        }
      }

      .title {
        margin-bottom: 16px;
        line-height: 20.16px;
        font-weight: 700;
      }

      .freelancer-details-box {
        h5 {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 600;
          line-height: 17.64px;
        }

        p {
          margin: 0;
          font-size: 14px;
          font-weight: 500;
          line-height: 19.6px;
          margin-top: 6px;

          &:empty {
            display: none;
          }
        }
      }

      .common-f-flex {
        margin-bottom: 27px;
        gap: 19px;

        @media (max-width: 767.98px) {
          gap: 11px;
        }

        .initial-letter {
          width: 50px;
          height: 50px;
          border: 1px solid $gray-medium-color;

          @media (max-width: 767.98px) {
            width: 40px;
            height: 40px;

            span {
              font-size: 16px !important;
              font-weight: 700 !important;
              line-height: 20.16px !important;
            }
          }
        }

        p {
          margin-block: 6px 0;
          font-size: 12px;
          font-weight: 500;
          line-height: 16.8px;
          color: $gray;

          @media (max-width: 767.98px) {
            margin-top: 4px;
          }
        }

        .juuli-d-flex {
          display: flex;
          gap: 8px;
          align-items: center;

          ~p {
            color: var(--gray);
            font-size: 12px;
            font-weight: 500;
          }
        }

        >div {
          span {
            font-size: 18px;
            font-weight: 600;
            line-height: 22.68px;

            @media (max-width: 767.98px) {
              font-size: 15px;
              font-weight: 600;
              line-height: 18.9px;
            }
          }
        }
      }

      .invoice-finance {
        margin-top: 40px;

        .invoice-data {
          gap: 22px;

          li {
            display: grid;
            grid-template-columns: 1fr 2fr;

            p {
              font-size: 14px;
              font-weight: 500;
              line-height: 19.6px;
            }
          }
        }
      }

      .edit-btn {
        width: 38px;
        height: 38px;
        justify-content: center;
        padding: 0;
        background: $gray-medium-color;
        line-height: 1;
        font-size: unset;
        gap: 0;
        border-radius: 100%;
        position: absolute;
        top: 24px;
        right: 32px;
      }

      ~div {
        .table-responsive {
          padding-right: unset;
        }

        .table-footer {
          margin-right: unset;
        }
      }
    }

    .finance-table {
      padding-right: 104px;
      overflow: auto;
      max-height: calc(100vh - 80px - 86px - 46px - 15px - 212px - 40px - 23px - 16px - 50px);

      @media (max-width: 767.98px) {
        padding-right: 0;
        max-height: calc(100vh - 80px - 86px - 46px - 15px - 212px - 40px - 23px - 16px);
      }
    }

    .offcanvas-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px 104px 20px 64px;
      margin: 0;

      @media (max-width: 767.98px) {
        padding: 12px 24px 20px;
      }

      @media (max-width: 575.98px) and (max-height: 750px) {
        bottom: 15px;
      }
    }

    .finance-table {
      .table {
        padding-top: 0;

        thead {
          &:has(th:empty) {
            display: none;
          }
        }

        tbody {
          tr {
            td {
              &:empty {
                padding: 0;
              }
            }
          }
        }
      }
    }

    .table-footer {
      margin-top: 11px;
      margin-right: 104px;

      @media (max-width: 1440.98px) {
        margin-right: calc(30px + 74*(100vw - 575px) / 866);
      }

      @media (max-width: 767.98px) {
        margin-right: 0;
        margin-bottom: 1px;
      }
    }

    .table-responsive {
      padding-right: 104px;

      @media (max-width: 1440.98px) {
        padding-right: calc(30px + 74*(100vw - 575px) / 866);
      }

      @media (max-width: 767.98px) {
        padding-right: 0;
      }

      .table {
        padding: 0;
      }
    }
  }

  &.add-client {
    padding-left: 63px;
    padding-right: 104px;

    form {
      display: grid;
      place-items: center;
      height: calc(100% - 77px);

      .footer {
        position: absolute;
        bottom: 34px;
        width: calc(100% - 63px - 105px);
      }
    }

    .offcanvas-body {
      height: unset;

      .invoice-note {
        margin-block: -12px 30px;
      }
    }
  }

  &.profile-setting {
    padding: 60px 0 0 115px;

    &:has(.tab-content .document-profile-tab.active, .tab-content .bank-account-tab.active) {
      padding-bottom: 20px;

      .offcanvas-body {
        max-height: 100%;
      }
    }

    .offcanvas-header {
      margin-bottom: 0;
      border: none;
      position: relative;
      margin-right: 155px;

      .offcanvas-title {
        font-size: 32px;
        line-height: 40.32px;

        .title {
          font-size: 32px;
          line-height: 40px;
          margin-top: -8px;
          margin-bottom: 34px;
        }
      }

      .btn-close {
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;
        width: 44px;
        height: 44px;
        padding: 0;
        background-size: 16px 16px;
      }
    }

    .profile-box {
      margin-top: 34px;
      gap: 23px;
      margin-right: 155px;

      @media (max-width: 1440px) {
        margin-block: calc(20px + (34 - 20) * ((100vw - 767px) / (1441 - 767))) calc(15px + (20 - 15) * ((100vw - 767px) / (1441 - 767)));
      }

      .profile-image-box {
        .profile-image {
          border-radius: 100%;
          border: 4px solid $white;
          box-shadow: $box-shadow-2;
        }

        .upload-image {
          height: 24px;
          width: 24px;

          img {
            height: 13px;
            width: 13px;
          }
        }
      }

      .profile-detail {
        width: calc(100% - 20px - calc(60px + 40 * (100vw - 320px) / 1600));
      }

      p {
        margin-top: 6px;
      }
    }

    .tab-border-tab {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        bottom: 3px;
        left: 0;
        width: 100%;
        height: 1px;
        border-top: 1px solid $gray-dark-color;
      }
    }

    .profile-tab {
      position: relative;
      border: none;
      padding: 0 155px 19px 0;
      gap: 69px;
      scrollbar-width: none;

      @media (max-width: 1440.98px) {
        padding-right: calc(36px + 119 * (100vw - 320px) / 1121);
        gap: calc(16px + (68 - 16) * ((100vw - 767px) / (1441 - 767)));
      }

      .nav-item {
        .nav-link {
          padding: 0;

          &.active {
            span {
              &::before {
                bottom: -18px;
                background-color: $primary-color-1;
              }
            }
          }
        }
      }
    }

    .offcanvas-body {
      padding-inline: 0 155px;
      padding-bottom: 90px;

      .bank-account-tab {
        .setting-main {
          .transaction-detail {
            .transaction-list {
              overflow: unset;

              @media (max-width: 575.98px) {
                margin-bottom: 0;
              }
            }
          }

          .bank-btn {
            @media (max-width: 575.98px) {
              margin-top: 32px !important;
            }
          }
        }

        .edit-item-data {
          .bank-info-box {
            >.row {
              @media (max-width: 991.98px) {
                --bs-gutter-y: 1rem;
                --bs-gutter-x: 1rem;

                >.col-md-6 {
                  width: 100%;
                }
              }

              @media (max-width: 575.98px) {
                --bs-gutter-y: 1.5rem;
                --bs-gutter-x: 1.5rem;

                >.col-md-6 {
                  width: 50%;
                }
              }

              @media (max-width: 400.98px) {
                --bs-gutter-y: 1rem;
                --bs-gutter-x: 1rem;

                >.col-md-6 {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .setting-main {
        >p {
          font-size: 14px;
          font-weight: 500;

          @media (max-width: 767px) {
            line-height: 19.6px;
            margin-bottom: 45px;
          }
        }

        .filter-dropdown-box {
          min-width: unset;
          max-width: none;
        }

        label {
          margin-bottom: 12px;
          color: $dark-gray;
          line-height: 18.2px;
        }

        .bank-form-check {
          label {
            margin-bottom: 0;
          }
        }

        .top-title {
          font-size: calc(14px + 2*(100vw - 320px) / 1600);
          margin-top: calc(32px + (45 - 32) * ((100vw - 320px) / (1441 - 320)));
          margin-bottom: 28px;
        }

        .email-change {
          p {
            a {
              cursor: pointer;
            }
          }

          span {
            cursor: pointer;
          }

          h6 {
            margin-top: calc(40px + (60 - 40) * ((100vw - 320px) / (1441 - 320)));
            margin-bottom: calc(24px + (28 - 24) * ((100vw - 320px) / (1441 - 320)));
          }
        }

        .form-control {
          position: relative;
          border: none;

          &:has(.form-check-input:disabled) {
            pointer-events: none;
            filter: none;
            opacity: 0.5;
          }

          .form-check {
            .form-radio-input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 100px;
              border: none;
              background: none;

              &::before {
                content: none;
              }

              &:checked {
                ~label {
                  .circle {
                    &::before {
                      transform: translate(-50%, -50%) scale(1);
                    }
                  }
                }
              }
            }

            label {
              margin-bottom: 0;
              font-weight: 500;
              width: 100%;
              border: 1px solid $gray-dark-color;
              border-radius: 100px;
              padding: 10px 12px;

              .circle {
                width: 16px;
                height: 16px;
                box-shadow: unset;
                border-radius: 100%;
                border: 1px solid $gray-dark-color;
                background-color: $white;
                filter: unset;
                float: unset;
                margin: 0;
                position: relative;
                transition: all 0.15s ease-in-out;

                &::before {
                  z-index: 0;
                  width: 12px;
                  height: 12px;
                  content: "";
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%) scale(0);
                  background-color: #ff4a29;
                  border-radius: 100%;
                  transition: all 0.15s ease-in-out;
                }
              }

              .text {
                width: calc(100% - 4px - 16px);
              }
            }
          }

          &.form-control-white {
            padding: 0;

            &.mb-3 {
              margin-bottom: 0 !important;
            }

            .form-radio-input {
              &:checked {
                ~label {
                  color: $secondary-color-4;
                }
              }
            }
          }
        }
      }
    }
  }

  &.finance-modal {
    .offcanvas-header {
      gap: 10px;
    }

    h5 {
      margin-bottom: 20px;
      margin-top: 16px;
    }

    .invoice-finance {
      border-radius: 12px;
      border: 1px solid $gray-dark-color;
      padding: 30px;
    }

    .footer-buttons {
      .btn {
        justify-content: center;
      }
    }

    .application-received {
      text-align: center;

      h2 {
        margin-block: 20px;
      }
    }
  }

  .upload-close-btn {
    box-shadow: unset;
    opacity: 1;
    background-image: url("../../images/svg/close.svg");
    background-size: 12px 12px;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid $gray-dark-color;
    border-radius: 100%;
    padding: 0;
    margin: 0;
    z-index: 0;
    position: absolute;
    top: 50%;
    right: 5px;
    width: 32px;
    height: 32px;
    background-color: transparent;
    transform: translate(0, -50%);

    &:focus {
      box-shadow: unset;
    }

    &:hover {
      background-image: url("../../images/svg/red-close.svg");
    }

    &:focus {
      background-image: url("../../images/svg/red-close.svg") $primary-color-1;
    }
  }

  &.upload-document {
    .offcanvas-header {
      @media (max-width:575.98px) {
        margin-bottom: 48px;
        justify-content: start;

        h5 {
          font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320))) !important;
        }
      }
    }

    .offcanvas-body {
      padding-block: 0;
      padding-left: 0;
    }

    .footer {
      bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));

      .btn {
        gap: 30px;
        padding: 13px;
        position: relative;
        justify-content: center;

        img {
          width: 32px;
          height: 32px;
          padding: 6px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }

    .flex-grow-1 {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .doc-title {
        font-size: calc(20px + (26 - 20) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 700;
        line-height: 30px;
        margin-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
      }
    }

    .invoice-data {
      border: 1px solid $gray-dark-color;
      border-radius: 12px;
      padding: 20px;

      &.project-data {
        padding: calc(20px + (33 - 20) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 40px;
      }

      @media (max-width:575.98px) {
        padding: 25px;
        gap: 26px;

        li {
          flex-direction: column;
          align-items: unset;
          gap: 9px;

          span {
            font-size: 13px;
            font-weight: 600;
            line-height: 16.9px;
            min-width: unset;
          }
        }
      }

      p {
        word-break: break-word;
        gap: 4px;

        @media (max-width:575.98px) {
          flex-direction: column;
          align-items: unset;
          margin-top: 0;
          line-height: 19.6px;
          gap: 2px;
        }
      }
    }

    .offcanvas-body {
      width: 100%;
      display: block;
      max-height: calc(100vh - calc(185px + (400 - 270) * ((100vw - 575px) / (1440 - 575))));
      overflow: auto;

      @media (max-width: 1399px) {
        max-height: calc(100vh - calc(220px + (400 - 270) * ((100vw - 575px) / (1440 - 575))));
      }

      @media (max-width: 992px) {
        max-height: calc(100vh - calc(240px + (400 - 270) * ((100vw - 575px) / (1440 - 575))));
      }

      @media (max-width: 767px) {
        max-height: calc(100vh - calc(310px + (400 - 270) * ((100vw - 575px) / (1440 - 575))));
      }

      @media (max-width: 575px) {
        max-height: calc(100vh - calc(235px + (400 - 270) * ((100vw - 575px) / (1440 - 575))));
      }
    }

    .document-list {
      li {
        padding: 9.3px 15px;
        border-radius: 100px;
        position: relative;
        padding-right: 45px;

        .file-type-img {
          width: 24px;
          height: 24px;
          cursor: pointer;
          padding: 3px;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 19.6px;
          width: calc(100% - 0.5rem - 24px - 32px);
        }

        .list-action {
          position: absolute;
          top: 50%;
          right: 4px;
          transform: translateY(-50%);
        }
      }
    }
  }

  label {
    line-height: 18.2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    .required-data {
      font-size: 14px;
      font-weight: 600;
      line-height: 18.2px;
      color: $primary-color-1;
    }
  }

  &.invoice-send-modal {
    .modal-note {
      margin-block: 60px 35px;
      padding-bottom: 22px;
      border-bottom: 1px solid $gray-dark-color;

      p {
        font-size: 16px;
        line-height: 24px;
      }
    }

    h2 {
      margin-top: 0;
    }

    .invoice-data {
      margin-top: 0;
    }

    .offcanvas-body {
      padding: 0;

      .invoice-content {
        .invoice-right-part {
          position: unset;
        }
      }

      .invoice-data {
        li {
          span {
            font-size: 14px;
            font-weight: 600;
            line-height: 17.64px;
          }
        }
      }
    }
  }

  &.full-offcanvas-theme {
    width: calc(100% - 270px);
    margin-left: 270px;
    max-width: unset;
    overflow: auto;

    @media (max-width: 1440.98px) {
      margin-left: 116px;
      width: calc(100% - 116px);
    }

    @media (max-width: 767.98px) {
      margin: 0;
      width: 100%;
      height: 100vh;
      border-radius: 0;
      border: none;
    }

    .theme-offcanvas-box {
      padding: 89px 0 40px 212px;
      display: block;

      @media (max-width: 1440.98px) {
        padding: calc(43px + (89 - 43) * ((100vw - 575px) / (1441 - 575))) 0 calc(24px + (40 - 24) * ((100vw - 575px) / (1441 - 575))) calc(50px + (212 - 50) * ((100vw - 575px) / (1441 - 575)));
      }

      @media (max-width: 767.98px) {
        padding: 0;
      }
    }

    .btn-close {
      @media (max-width: 575.98px) {
        display: block;
        width: 44px;
        height: 44px;
        background-size: 16px;
        top: 24px;
        right: 20px;
      }
    }

    .offcanvas-height {
      overflow: auto;
      max-height: calc(100vh - 89px - 42px - 148px);

      @media (max-width: 1440.98px) {
        max-height: calc(100vh - calc(24px + (91 - 24) * ((100vw - 767px) / (1441 - 767))) - 40px - 148px);
      }

      @media (max-width: 767.98px) {
        height: calc(100vh - 66px - 16px - 70px);
        max-height: unset;
        padding: 25px calc(14px + (30 - 14) * ((100vw - 320px) / (768 - 320))) 0;

        @media (max-height: 750px) {
          height: calc(100vh - 66px - 16px - 70px - 75px);
        }
      }
    }

    .offcanvas-body {
      padding-right: 0;
      max-height: 100%;

      @media (max-width: 575.98px) {
        height: 100%;
      }

      &:has(.confirm-invoice-approval) {
        .offcanvas-height {
          max-height: calc(100vh - 139px - 40px - 148px);

          @media (max-width: 1440.98px) {
            max-height: calc(100vh - calc(91px + (139 - 91) * ((100vw - 767px) / (1441 - 767))) - 40px - 148px);
          }

          @media (max-width: 767.98px) {
            max-height: calc(100vh - 19px - 40px - 148px);
          }

          @media (max-width: 575.98px) {
            max-height: calc(100vh - 53px - 40px - 148px);
          }
        }
      }

      &:has(.confirm-invoice-approval .confirm-check-box) {
        .offcanvas-height {
          max-height: calc(100vh - 146px - 40px - 148px);

          @media (max-width: 1440.98px) {
            max-height: calc(100vh - calc(94px + (146 - 94) * ((100vw - 767px) / (1441 - 767))) - 40px - 148px);
          }

          @media (max-width: 767.98px) {
            max-height: calc(100vh - 82px - 148px);
          }

          @media (max-width: 575.98px) {
            max-height: calc(100vh - 50px - 148px);
          }
        }
      }

      &:has(.confirm-invoice-approval .confirm-check-box textarea) {
        .offcanvas-height {
          max-height: calc(100vh - 256px - 40px - 148px);

          @media (max-width: 1440.98px) {
            max-height: calc(100vh - calc(189px + (256 - 189) * ((100vw - 767px) / (1441 - 767))) - 40px - 148px);
          }

          @media (max-width: 767.98px) {
            max-height: calc(100vh - 189px - 148px);
          }

          @media (max-width: 575.98px) {
            max-height: calc(100vh - 154px - 148px);
          }
        }
      }

      .close-btn {
        width: 100%;
        display: block;

        @media (max-width: 767.98px) {
          margin-inline: 24px;
          width: calc(100% - 48px);
        }
      }

      .pdf-box {
        height: calc(100vh - 280px);
        overflow: auto;
        padding-right: 212px;

        &:has(~.confirm-invoice-approval) {
          height: calc(100vh - 315px);
        }

        &:has(~.confirm-invoice-approval textarea) {
          height: calc(100vh - 425px);
        }

        #adobe-dc-view {
          @media (min-width: 1200px) {
            max-width: 800px;
            margin: auto;
          }
        }

        @media (max-width: 1440.98px) {
          padding-right: calc(50px + (212 - 50) * ((100vw - 575px) / (1441 - 575)));
          height: calc(100vh - (268px + 38 * (100vw - 575px) / 866));
        }

        @media (max-width: 767.98px) {
          padding-right: 0;
          height: calc(100vh - 150px);

          @media (max-height: 800px) {
            height: calc(100vh - 202px);
          }

          &:has(~.confirm-invoice-approval) {
            @media (max-height: 800px) {
              height: calc(100vh - 245px);
            }

            @media (max-height: 650px) {
              height: calc(100vh - 290px);
            }
          }

          &:has(~.invoice-action) {
            height: calc(100vh - 260px);
          }
        }
      }

      .invoice-action {
        @include flex_common;
        padding-block: 16px;
        border-block: 1px solid $gray-dark-color;
        margin-bottom: 40px;

        @media (max-width: 575.98px) {
          position: absolute;
          bottom: 140px;
          left: 0;
          right: 0;
        }

        li {
          @include flex_common;
          gap: 4px;
          width: 50%;
          font-size: 14px;
          font-weight: 700;
          line-height: 17.64px;

          img {
            width: 32px;
            height: 32px;
            padding: 7px;
          }
        }
      }

      .invoice-action,
      .confirm-invoice-approval {
        margin-right: 212px;

        @media (max-width: 1440.98px) {
          margin-right: calc(50px + (212 - 50) * ((100vw - 575px) / (1441 - 575)));
        }

        @media (max-width: 767.98px) {
          margin-bottom: 20px;
          margin-right: 0;
        }

      }
    }

    .offcanvas-footer {
      border-top: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      flex-wrap: nowrap;
      padding-inline: 100px;

      button {
        padding-block: 13px;
        margin: 0;
        width: 100%;
        justify-content: center;
      }
    }

    .footer-buttons {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      padding-right: 212px;

      @media (max-width: 1440.98px) {
        padding-right: calc(50px + (212 - 50) * ((100vw - 575px) / (1441 - 575)));
      }

      @media (max-width: 767.98px) {
        padding-inline: 15px;
        gap: 8px;
      }

      @media (max-width: 575px) {
        position: absolute;
        bottom: 90px;
      }

      .btn {
        min-width: unset;

        @media (max-width: 767.98px) {
          width: 100%;
          margin: 0;
          padding: 13px;
          font-size: 14px;
          font-weight: 700;
          line-height: 17.64px;
        }
      }
    }

    .confirm-invoice-approval {
      padding-top: 25px;
      padding-inline: 16px 20px;
      border-top: 1px solid $gray-dark-color;

      h2 {
        font-size: 20px;
        font-weight: 700;
        line-height: 25.2px;
      }

      .confirm-check-box {
        margin-block: 18px 49px;

        @media (max-width: 575.98px) {
          margin-block: 12px 20px;
        }

        textarea {
          margin-top: 14px;
        }
      }

      .form-check {
        margin-block: 16px 52px;
        align-items: flex-start;

        @media (max-width: 767.98px) {
          margin-bottom: 22px;
        }

        input {
          margin-top: 3px;
        }

        label {
          line-height: 19.6px;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .address-indicate-note {
        .indicate {
          margin: 0;
        }
      }

      ~.footer-buttons {
        @media (min-width: 576px) {
          padding-bottom: 20px;
        }

        .btn {
          max-width: 240px;
          width: 100%;
          display: block;
        }
      }
    }
  }
}

.offcanvas-backdrop {
  background-color: rgba(249, 246, 249, 0.7);
  z-index: 7;
  filter: blur(4px);
}

#gtx-trans {
  display: none;
}

.offcanvas-profile-setting {
  .profile-box {
    .profile-image-box {
      .initial-letter {
        width: 100px;
        height: 100px;
        border-width: 4px;

        @media (max-width: 932px) {
          height: 70px;
          width: 70px;
        }

        span {
          font-size: 30px !important;
        }
      }
    }
  }

  .document-detail {
    .document-list {
      margin-bottom: 1px;
    }
  }

  .profile-setting-footer-main {
    position: absolute !important;
    bottom: 28px;
    left: 0;
    margin: 0;
    padding: 0;
    padding-inline: 115px 156px;
    width: 100%;

    @media (max-width: 1440.98px) {
      padding-inline: calc(36px + (115 - 36) * ((100vw - 767px) / (1441 - 767))) calc(36px + (156 - 36) * ((100vw - 767px) / (1441 - 767)));
    }

    @media (max-width: 767.98px) {
      padding-inline: calc(16px + (36 - 16) * ((100vw - 320px) / (767 - 320)));
    }

    @media (max-width: 575.98px) {
      bottom: 0;
      padding: 12px 20px 16px;
    }

    .profile-setting-footer {
      margin: 0;
      justify-content: unset;

      @media (max-width: 575.98px) {
        margin: 0;
      }

      .btn {
        display: block;
        width: 100%;
        padding: 13px;
        font-size: 14px;
        font-weight: 700;
        line-height: 17.64px;
      }
    }
  }
}

.offcanvas-backdrop {
  opacity: 0.8 !important;

  @media (max-width: 575.98px) {
    opacity: 0 !important;
  }
}

.form-mb-73 {
  @media (max-width: 767.98px) and (max-height: 780px) {
    margin-bottom: 73px;
  }
}

/**=====================
  Offcanvas CSS ends
==========================**/